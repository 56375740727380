import { ApolloError } from '@apollo/client';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BillingModelType,
  ContractType,
  EngagementType,
  PackageType,
  useGetAllEngagementTypesQuery,
  useGetAllPackagesQuery,
  useGetBillingModelsQuery,
} from '../../../types';
import { computeStartDateDefault } from '../../../utils/contract-utils';

interface Data {
  defaultStartDateCalenderDate: Dayjs;
  engagementTypes: EngagementType[];
  availablePackages: PackageType[];
  billingModels: BillingModelType[];
  defaultInitialPackage: Partial<PackageType>;
  preSelectedBillingModel: string | undefined;
}

interface UseGetDefaultReturn {
  data: Data | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
}

export function useGetDefaultPackageFormValues(
  currentContracts: ContractType[],
): UseGetDefaultReturn {
  const location = useLocation();

  const defaultStartDateCalenderDate = useMemo(
    () => computeStartDateDefault(currentContracts),
    [currentContracts],
  );
  const defaultInitialPackage = useMemo(
    () => ({ id: 0, name: '', packageOfferings: [] }),
    [],
  );

  const {
    data: engagementTypes,
    loading: engagementTypesLoading,
    error: engagementTypesError,
  } = useGetAllEngagementTypesQuery();
  const {
    data: availablePackages,
    loading: packagesLoading,
    error: packagesError,
  } = useGetAllPackagesQuery();
  const {
    data: billingModels,
    loading: billingModelsLoading,
    error: billingModelsError,
  } = useGetBillingModelsQuery();

  const combinedError =
    engagementTypesError || packagesError || billingModelsError;

  const isLoading =
    engagementTypesLoading || packagesLoading || billingModelsLoading;

  const processedData = useMemo(() => {
    if (
      isLoading ||
      combinedError ||
      !engagementTypes?.getAllEngagementTypes ||
      !availablePackages?.getAllPackages ||
      !billingModels?.getBillingModels
    ) {
      return undefined;
    }

    return {
      defaultStartDateCalenderDate,
      engagementTypes: engagementTypes.getAllEngagementTypes,
      availablePackages: availablePackages.getAllPackages,
      billingModels: billingModels.getBillingModels,
      defaultInitialPackage,
      preSelectedBillingModel: location.state?.billingModel,
    };
  }, [
    engagementTypes?.getAllEngagementTypes,
    availablePackages?.getAllPackages,
    billingModels?.getBillingModels,
    isLoading,
    combinedError,
    defaultStartDateCalenderDate,
    defaultInitialPackage,
    location.state?.billingModel,
  ]);

  return {
    data: processedData,
    isLoading,
    error: combinedError,
  };
}
