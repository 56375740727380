import {
  HHAlert,
  HHPagination,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { EngagementsPaginationType } from '../../types';
import LoadingComponent from '../utils/loading';
import EngagementList from './engagement-list';

interface EngagementsDataDisplayProps {
  loading: boolean;
  error: boolean;
  page: number;
  engagementsData?: EngagementsPaginationType;
}

export interface EngagementsDataProps extends EngagementsDataDisplayProps {
  setPage: (value: number) => void;
  total: number;
  pages: number;
}

const EngagementsDataDisplay = ({
  loading,
  error,
  page,
  engagementsData,
}: EngagementsDataDisplayProps): JSX.Element => {
  if (loading) {
    return <LoadingComponent center />;
  }

  if (error) {
    return (
      <HHAlert
        hhVariant="outlined"
        severity="error"
        children={
          <HHTypography hhVariant="body">
            There was an error loading Engagements
          </HHTypography>
        }
      ></HHAlert>
    );
  }

  if (!engagementsData) {
    return (
      <HHStack spacing={2} overflow="hidden">
        <HHTypography hhVariant="body">Engagements not found</HHTypography>
      </HHStack>
    );
  }

  return <EngagementList key={page} engagementsData={engagementsData} />;
};

const EngagementsData = ({
  error,
  loading,
  page,
  total,
  pages,
  engagementsData,
  setPage,
}: EngagementsDataProps): JSX.Element => (
  <HHStack spacing={2} direction="column" padding={1}>
    <EngagementsDataDisplay
      page={page ?? 1}
      engagementsData={engagementsData}
      loading={loading}
      error={!!error}
    />
    {total > 0 && (
      <HHStack direction="row" justifyContent="end">
        <HHPagination
          page={page}
          count={pages ?? 1}
          onChange={(_, value): void => setPage(value)}
        />
      </HHStack>
    )}
  </HHStack>
);

export default EngagementsData;
