import {
  HHBox,
  HHSnackbarAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Add } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hinge-connect/hooks/use-snackbar';
import { useDeleteRule } from '../../../hinge-connect/rules/hooks/use-delete-rule';
import { tabRoutes } from '../../constants/member-data-services-constants.constant';
import { targetedEnrollmentRoutes } from '../constants/targeted-enrollment.constants';
import {
  TargetedEnrollmentContextProvider,
  useTargetedEnrollmentContext,
} from '../contexts/targeted-enrollment-context';
import { TargetedEnrollmentGrid } from './targeted-enrollment-grid';

export const TargetedEnrollmentListViewContainer = (): JSX.Element => {
  const { listRulesData, listRulesLoading, refetchListRules, listRulesError } =
    useTargetedEnrollmentContext();

  const {
    deleteRule,
    data: deleteRuleData,
    loading: deleteRuleLoading,
    error: deleteRuleError,
    errorMessages: deleteRuleErrorMessages,
  } = useDeleteRule(refetchListRules);

  const {
    snackbar: deleteRuleSnackbar,
    handleClose: handleDeleteRuleSnackbarClose,
  } = useSnackbar({
    data: deleteRuleData,
    error: deleteRuleError,
    errorMessages: deleteRuleErrorMessages,
    successMessage: 'Rule deleted successfully',
  });

  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;

  useEffect(() => {
    if (successMessage) {
      refetchListRules();
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [successMessage, refetchListRules, navigate, location.pathname]);

  const {
    snackbar: updateRuleSnackbar,
    handleClose: handleUpdateRuleSnackbarClose,
  } = useSnackbar({
    data: successMessage ? { message: successMessage } : null,
    error: undefined,
    errorMessages: '',
    successMessage,
  });

  return (
    <>
      <HHSnackbarAlert
        message={deleteRuleSnackbar.message}
        severity={deleteRuleSnackbar.severity}
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="standard"
        open={deleteRuleSnackbar.open}
        onClose={handleDeleteRuleSnackbarClose}
        autoHideTime={3000}
      />
      <HHSnackbarAlert
        message={updateRuleSnackbar.message}
        severity={updateRuleSnackbar.severity}
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="standard"
        open={updateRuleSnackbar.open}
        onClose={handleUpdateRuleSnackbarClose}
        autoHideTime={3000}
      />
      <HHTypography hhVariant="h2">Tagging Rules</HHTypography>
      <br />
      <HHStack direction="row" justifyContent="space-between">
        <TargetedEnrollmentGrid
          listRulesData={listRulesData}
          listRulesLoading={listRulesLoading}
          refetchListRules={refetchListRules}
          deleteRule={deleteRule}
          deleteRuleLoading={deleteRuleLoading}
          listRulesError={listRulesError}
          copyRuleLoading={false}
        />
        <Fab
          color="primary"
          size="large"
          aria-label="add"
          href={`/${tabRoutes.baseRoute}/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.new}`}
          sx={{
            position: 'absolute',
            bottom: 32,
            right: 32,
          }}
        >
          <Add />
        </Fab>
      </HHStack>
    </>
  );
};

export const TargetedEnrollmentListView = (): JSX.Element => (
  <TargetedEnrollmentContextProvider>
    <HHBox paddingY="1rem">
      <TargetedEnrollmentListViewContainer />
    </HHBox>
  </TargetedEnrollmentContextProvider>
);
