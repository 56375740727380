import {
  HHButton,
  HHIconButton,
  HHPaper,
  HHStack,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { GetSplitTreatmentOnOrOff } from '../../../../../components/splits';
import {
  contractPartnershipIdFlag,
  migrateBillingModelsEnabled,
} from '../../../constants/strings/split';
import {
  ActiveStatus,
  getResetPackageFormValues,
  parsePackageFormValues,
} from '../../../utils/contract-utils';
import LoadingComponent from '../../utils/loading';
import { CurrencySelect } from '../form/components/currency-select';
import { ContractDatePickers } from '../form/components/date-pickers';
import { PartnershipSelect } from '../form/components/partnership-select';
import FormPanelDetails from './components/form-right-panel';
import {
  PackageFormConfirmation,
  PackageFormConfirmationProps,
} from './components/package-form-confirmation';
import { UserAnnualCap } from './components/user-annual-cap';
import { getConfirmationTitle } from './components/utils';
import {
  ContractPackageFormProps,
  DEFAULT_FORM_VALUES,
  DEFAULT_PACKAGE_FORM_VALUES,
  RawContractPackageFormValues,
} from './custom-package-types';
import { PackageForm } from './package-form';
import { useGetDefaultPackageFormValues } from './use-get-default-package-values';
import { PackageValidationSchema } from './validation-schema';
export const fieldArrayName = 'selectedPackages';

export enum ContractPackageFormLabelText {
  START_DATE = 'Start date',
  END_DATE = 'End date',
  USER_ANNUAL_CAP = 'User Annual spending cap',
  PACKAGE = 'Product Package',
  BILLING_MODEL = 'Billing Model',
  PRICE = 'Price',
  VOID = 'Void Contract',
  INITIAL_FEE = 'Initial Fee',
  ACTIVITY_FEE = 'Activity Fee',
  CURRENCY = 'Currency code',
  BILLABLE_ACTIVITY = 'Billable Activities', //this matches to pre-existing label
}

export const ContractPackageForm = ({
  currentContracts,
  activeStatus = ActiveStatus.Unknown,
  editId,
  hideVoid,
  submitTitle,
  disableSubmit,
  partnershipId,
  onSubmitCallback,
  onCancel,
  wholeFormDisabled = false,
  startDateDisablePast = false,
}: ContractPackageFormProps): JSX.Element => {
  const memoizedCurrentContracts = useMemo(
    () => currentContracts,
    [currentContracts],
  );
  const {
    data: formData,
    isLoading,
    error,
  } = useGetDefaultPackageFormValues(memoizedCurrentContracts);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();
  const contractPartnershipIdFlagEnabled = GetSplitTreatmentOnOrOff(
    contractPartnershipIdFlag,
    adminId,
  );

  const migrateBillingModelsFlagEnabled = GetSplitTreatmentOnOrOff(
    migrateBillingModelsEnabled,
    adminId,
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_FORM_VALUES,
    context: {
      startDateDisablePast,
      editId,
      currentContracts: memoizedCurrentContracts,
      billingModels: formData?.billingModels ?? [],
      availablePackages: formData?.availablePackages,
    },
    resolver: yupResolver(PackageValidationSchema),
    criteriaMode: 'all',
  });

  const {
    formState,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    trigger,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  const { isDirty, errors, isValid, isSubmitting, isSubmitted } = formState;

  const [
    watchedVoid,
    watchedCurrency,
    watchedSelectedPackages,
    watchedStartDate,
  ] = useWatch({
    control,
    name: ['isVoid', 'currency', 'selectedPackages', 'startDate'],
  });

  const hasPackageSelected = watchedSelectedPackages?.some(
    selectedPackage =>
      selectedPackage.packageId != null && selectedPackage.packageId !== 0,
  );

  const watchedBillingModelIds = useMemo(
    () =>
      watchedSelectedPackages?.map(
        selectedPackage => selectedPackage.billingModelId,
      ) ?? [],
    [watchedSelectedPackages],
  );
  const previousBillingModelIds = useRef(watchedBillingModelIds);

  useEffect(() => {
    if (
      watchedBillingModelIds !== previousBillingModelIds.current &&
      watchedStartDate
    ) {
      trigger('startDate');
    }
    previousBillingModelIds.current = watchedBillingModelIds;
  }, [watchedBillingModelIds, watchedStartDate, trigger]);

  const onAction: PackageFormConfirmationProps['onAction'] = action => {
    setIsModalOpen(false);
    if (action === 'submit') {
      handleSubmit((data: RawContractPackageFormValues): void => {
        onSubmitCallback(
          parsePackageFormValues(data, formData?.billingModels ?? []),
        );
      })();
    }
    if (action === 'void') {
      handleSubmit((data: RawContractPackageFormValues): void => {
        onSubmitCallback(
          parsePackageFormValues(
            { ...data, isVoid: true },
            formData?.billingModels ?? [],
          ),
        );
      })();
    }
  };

  useEffect(() => {
    if (formData) {
      const contractToEdit = memoizedCurrentContracts.find(
        c => c.id === editId,
      );
      reset(
        getResetPackageFormValues(
          formData.billingModels,
          formData.preSelectedBillingModel,
          contractToEdit,
          partnershipId,
        ),
      );
    }
  }, [memoizedCurrentContracts, editId, formData, partnershipId, reset]);

  if (isLoading) {
    return <LoadingComponent center />;
  }

  if (error) {
    return (
      <HHTypography hhVariant="default">
        There was an error loading the form
      </HHTypography>
    );
  }

  return (
    <FormProvider {...methods}>
      <PackageFormConfirmation
        formValues={parsePackageFormValues(
          getValues(),
          formData?.billingModels ?? [],
        )}
        availablePackages={formData?.availablePackages ?? []}
        billingModels={formData?.billingModels ?? []}
        onAction={onAction}
        open={isModalOpen}
        isVoid={watchedVoid}
        title={getConfirmationTitle(watchedVoid, editId)}
        editId={editId}
        contractToEdit={memoizedCurrentContracts.find(c => c.id === editId)}
        activeStatus={activeStatus}
        confirmText={watchedVoid ? 'Void' : 'Confirm'}
      />
      <form>
        <HHStack direction="row" spacing={4} padding={4}>
          <HHStack
            direction="column"
            spacing={4}
            flex={hasPackageSelected ? 2 : 1}
            sx={{ transition: 'flex 0.3s ease' }}
            data-testid={'contract-package-form-test-id'}
          >
            {/* Form fields go here */}
            {contractPartnershipIdFlagEnabled && (
              <PartnershipSelect wholeFormDisabled={wholeFormDisabled} />
            )}
            <ContractDatePickers
              startDateDisablePast={startDateDisablePast}
              startlabel={ContractPackageFormLabelText.START_DATE}
              endLabel={ContractPackageFormLabelText.END_DATE}
              defaultStartDateCalenderDate={
                formData?.defaultStartDateCalenderDate ?? dayjs()
              }
              wholeFormDisabled={wholeFormDisabled}
              currentContracts={memoizedCurrentContracts}
              contractEditId={editId}
              submissionCount={0}
              activeStatus={activeStatus}
            />
            <HHStack direction="row" spacing={2}>
              <CurrencySelect wholeFormDisabled={wholeFormDisabled} />
              <UserAnnualCap wholeFormDisabled={wholeFormDisabled} />
            </HHStack>
            {/* package form */}
            {fields.map((field, index) => (
              <PackageForm
                index={index}
                availablePackages={
                  formData?.availablePackages.filter(
                    p => p.name !== 'in_person_visit',
                  ) ?? []
                }
                billingModels={formData?.billingModels ?? []}
                engagementTypes={formData?.engagementTypes ?? []}
                wholeFormDisabled={wholeFormDisabled}
                value={field}
                key={field.id}
                remove={remove}
                existingContract={memoizedCurrentContracts?.find(
                  c => c.id === editId,
                )}
              />
            ))}
            <HHPaper>
              <HHStack direction="row" alignItems="center" padding={4}>
                <HHIconButton
                  hhVariant="medium"
                  onClick={(): void => append(DEFAULT_PACKAGE_FORM_VALUES)}
                  disabled={
                    wholeFormDisabled || !migrateBillingModelsFlagEnabled
                  }
                >
                  <AddIcon color="inherit" />
                </HHIconButton>
                <HHTypography hhVariant="default">Another Package</HHTypography>
              </HHStack>
            </HHPaper>
            <HHStack
              direction="row"
              spacing={2}
              justifyContent={hideVoid ? 'flex-end' : 'space-between'}
            >
              {hideVoid ? null : (
                <HHButton
                  name="isVoid"
                  hhVariant="outlined"
                  type="button"
                  color="error"
                  onClick={(): void => {
                    setValue('isVoid', true);
                    setIsModalOpen(true);
                  }}
                >
                  {ContractPackageFormLabelText.VOID}
                </HHButton>
              )}
              <HHStack direction="row" spacing={2}>
                {errors.selectedPackages && (
                  <HHStack alignItems="center" justifyContent="center">
                    <HHTypography hhVariant="default" color="error">
                      {errors.selectedPackages.message}
                    </HHTypography>
                  </HHStack>
                )}
                <HHButton
                  hhVariant="variant-bypass"
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={onCancel}
                >
                  Cancel
                </HHButton>
                <HHButton
                  hhVariant="variant-bypass"
                  variant="contained"
                  size="medium"
                  color="primary"
                  disabled={
                    disableSubmit ||
                    !isDirty ||
                    (!!errors && !isValid) ||
                    isSubmitting ||
                    isSubmitted
                  }
                  onClick={(): void => {
                    setIsModalOpen(true);
                  }}
                >
                  {submitTitle}
                </HHButton>
              </HHStack>
            </HHStack>
          </HHStack>
          <HHStack
            direction="column"
            spacing={4}
            flex={hasPackageSelected ? 1 : 0}
            sx={{ transition: 'flex 0.3s ease' }}
          >
            {watchedSelectedPackages?.map((selectedPackage, index) => {
              if (
                selectedPackage.packageId == null ||
                selectedPackage.packageId === 0
              ) {
                return null;
              }
              return (
                <FormPanelDetails
                  key={index}
                  selectedPackage={selectedPackage}
                  availablePackages={formData?.availablePackages ?? []}
                  engagementTypes={formData?.engagementTypes ?? []}
                  currency={watchedCurrency.value}
                  billingModels={formData?.billingModels ?? []}
                />
              );
            })}
          </HHStack>
        </HHStack>
      </form>
    </FormProvider>
  );
};
