import { HHStack } from '@hinge-health/react-component-library';
import { FeeForServiceEvent } from '../../types';
import BillsByFeeForServiceId from '../bills/bills-fee-for-service';
import EngagementsByFeeForServiceId from '../engagement/fee-for-service-engagements';

export interface FeeForServiceDataProps {
  data: FeeForServiceEvent;
}

const FeeForServiceData = ({ data }: FeeForServiceDataProps): JSX.Element => (
  <HHStack
    spacing={6}
    direction="column"
    padding={3}
    bgcolor={(theme): string => theme.palette.background.default}
    sx={{ borderRadius: 2, marginTop: 1 }}
  >
    <BillsByFeeForServiceId ffsData={data} />
    <EngagementsByFeeForServiceId feeForServiceEventId={data.id} />
  </HHStack>
);

export default FeeForServiceData;
