import { ApolloError } from '@apollo/client';
import {
  HHButton,
  HHIconButton,
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { BaseSyntheticEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BillingTypes } from '../../../../types';
import {
  capitalizeFirstLetterAndSpaceSnakeString,
  convertStringArrayToNumberArray,
  formatLineBreakOrCsvInput,
  selectSorterFunction,
} from '../../../../utils/bills-utils';
import {
  AutocompleteSelectOption,
  RawAdvancedFiltersValues,
} from '../bill-holds-types';
import { HoldsFiltersPropsWithClientsAndInsurersAndPartnerships } from '../filters-toolbar';
import { defaultBillHoldsGridFilterModel } from '../grid';
import { AdvancedFiltersValidationSchema } from './validation-schema';

export interface AdvancedFiltersProps
  extends HoldsFiltersPropsWithClientsAndInsurersAndPartnerships {
  holdTypes: string[];
  holdTypesError?: ApolloError;
  filterToggle: () => void;
}

export enum AdvancedFilterLabelText {
  CLAIM_ID = 'Claim ID',
  HOLD_REASON = 'Hold Reason',
  SUBSCRIPTION_ID = 'Subscription ID',
  DATE_OF_SERVICE = 'Date of service',
  USER_ID = 'User ID',
  CLIENT = 'Client',
  INSURER = 'Insurer',
  PARTNERSHIP = 'Partnership',
  BILL_TYPE = 'Billing type',
  PAYMENT_TYPE = 'Payment type',
}

const holdReasonsSelectOptions = (
  holdVals: string[],
): AutocompleteSelectOption[] =>
  holdVals
    .map(val => ({
      value: val,
      label: capitalizeFirstLetterAndSpaceSnakeString(val),
    }))
    .sort((a, b) => selectSorterFunction(a.label, b.label));

const billTypesSelectOptions = Object.entries(BillingTypes)
  .map(([key, value]) => ({
    value: key,
    label: capitalizeFirstLetterAndSpaceSnakeString(value.toLowerCase()),
  }))
  .sort((a, b) => selectSorterFunction(a.label, b.label));

export const AdvancedFilters = ({
  setFilterParams,
  filterParams,
  clients,
  clientsError,
  insurers,
  insurersError,
  partnerships,
  partnershipsError,
  holdTypes,
  holdTypesError,
  filterToggle,
}: AdvancedFiltersProps): JSX.Element => {
  const [rowHeight, setRowHeight] = useState(1);

  const {
    holdReasons,
    startDate,
    endDate,
    claimIds,
    userIds,
    subscriptionIds,
    clientIds,
    insurerIds,
    partnershipIds,
    billingTypes: filteredBillingTypes,
  } = filterParams;

  const defaultValues: RawAdvancedFiltersValues = {
    claimIds,
    holdReasons: holdReasonsSelectOptions(holdReasons),
    userIds: userIds.map(id => id.toString()) ?? [],
    subscriptionIds: subscriptionIds.map(id => id.toString()) ?? [],
    clients: clients.filter(client => clientIds.includes(client.id)),
    insurers: insurers.filter(insurer => insurerIds.includes(insurer.id)),
    partnerships: partnerships.filter(partnership =>
      partnershipIds.includes(partnership.id),
    ),
    billingTypes: billTypesSelectOptions.filter(select =>
      filteredBillingTypes.includes(select.value),
    ),
    dateRange: [
      dayjs(startDate).isValid() ? dayjs(startDate).toDate() : null,
      dayjs(endDate).isValid() ? dayjs(endDate).toDate() : null,
    ],
  };

  const { handleSubmit, control, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    criteriaMode: 'all',
    resolver: yupResolver(AdvancedFiltersValidationSchema),
  });

  const { errors, isValid, isDirty } = formState;

  const parseFormValues = (data: RawAdvancedFiltersValues): void => {
    const {
      holdReasons,
      dateRange,
      userIds,
      subscriptionIds,
      clients,
      insurers,
      billingTypes,
      partnerships,
      ...rest
    } = data;

    const formattedData = {
      ...rest,
      userIds: convertStringArrayToNumberArray(userIds),
      subscriptionIds: convertStringArrayToNumberArray(subscriptionIds),
      holdReasons: holdReasons.map(reason => reason.value),
      startDate: dayjs(dateRange[0]).isValid()
        ? dayjs(dateRange[0]).format('YYYY-MM-DD')
        : null,
      endDate: dayjs(dateRange[1]).isValid()
        ? dayjs(dateRange[1]).format('YYYY-MM-DD')
        : null,
      clientIds: clients.map(client => client.id),
      insurerIds: insurers.map(insurer => insurer.id),
      partnershipIds: partnerships.map(partnership => partnership.id),
      billingTypes: billingTypes.map(type => type.value),
    };

    setFilterParams(formattedData);
    filterToggle();
  };

  const onFormSubmit = (e: BaseSyntheticEvent<object>): void => {
    handleSubmit(parseFormValues)(e);
  };

  const handleCancel = (): void => {
    reset(defaultValues);
    setFilterParams(defaultBillHoldsGridFilterModel);
    filterToggle();
  };

  const sortedInsurers = [...insurers].sort((a, b) =>
    selectSorterFunction(a.identifier, b.identifier),
  );
  const sortedClients = [...clients].sort((a, b) =>
    selectSorterFunction(a.identifier, b.identifier),
  );
  const sortedPartnerships = [...partnerships].sort((a, b) =>
    selectSorterFunction(a.name, b.name),
  );

  return (
    <form onSubmit={onFormSubmit}>
      <HHStack direction="row" spacing={2}>
        <HHStack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-end"
          sx={{ gap: 4 }}
        >
          <Controller //claim ids
            control={control}
            name="claimIds"
            render={({ field: { onChange, value, ref } }): JSX.Element => (
              <HHTextField
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                layoutStyles={{ minWidth: 230, maxWidth: 230 }}
                label={AdvancedFilterLabelText.CLAIM_ID}
                hhVariant="variant-bypass"
                variant="standard"
                multiline
                value={value}
                ref={ref}
                rows={rowHeight}
                onChange={(e): void => {
                  if (e.target.value.length > 15) {
                    setRowHeight(5);
                  } else {
                    setRowHeight(1);
                  }
                  onChange(
                    e.target.value.length > 0
                      ? formatLineBreakOrCsvInput(e.target.value)
                      : [],
                  );
                }}
                type="string"
                InputLabelProps={{ sx: { color: 'gray' } }}
                error={Object.keys(errors?.claimIds ?? {}).length !== 0}
                helperText={errors.claimIds?.message}
              />
            )}
          />
          <Controller //hold reasons
            control={control}
            name="holdReasons"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                multiple
                id="tags-standard"
                value={value}
                options={
                  !holdTypesError ? holdReasonsSelectOptions(holdTypes) : []
                }
                size="small"
                isOptionEqualToValue={(option, value): boolean =>
                  option.value === value.value
                }
                sx={{ minWidth: 230, maxWidth: 230 }}
                limitTags={1}
                onChange={(_, newValue): void => onChange(newValue)}
                getOptionLabel={(option): string => option.label}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="standard"
                    label={AdvancedFilterLabelText.HOLD_REASON}
                    error={
                      !!holdTypesError ||
                      Object.keys(errors?.holdReasons ?? {}).length !== 0
                    }
                    helperText={
                      holdTypesError ||
                      Object.keys(errors?.holdReasons ?? {}).length !== 0
                        ? (errors.holdReasons?.message ??
                          'hold reasons unavailable')
                        : ''
                    }
                  />
                )}
              />
            )}
          />
          <Controller //date range
            control={control}
            name="dateRange"
            render={({ field: { onChange, value } }): JSX.Element => (
              <DateRangePicker
                value={value as [Date | null, Date | null]}
                onChange={(newValue): void => onChange(newValue)}
                renderInput={(startProps, endProps): JSX.Element => {
                  // Bug with this version of x-date-pickers-pro it was overwriting the start label
                  const { label: _, ...restStart } = startProps;
                  return (
                    <HHStack
                      direction="row"
                      spacing={2}
                      alignItems="flex-end"
                      minWidth={150}
                      maxWidth={230}
                    >
                      <HHTextField
                        hhVariant="variant-bypass"
                        variant="standard"
                        type="string"
                        label={AdvancedFilterLabelText.DATE_OF_SERVICE}
                        InputLabelProps={{ sx: { color: 'gray' } }}
                        error={
                          Object.keys(errors?.dateRange ?? {}).length !== 0
                        }
                        helperText={errors.dateRange?.message}
                        {...restStart}
                      />
                      <HHTypography hhVariant="body1"> to </HHTypography>
                      <HHTextField
                        hhVariant="variant-bypass"
                        variant="standard"
                        type="string"
                        InputLabelProps={{ sx: { color: 'gray' } }}
                        error={
                          Object.keys(errors?.dateRange ?? {}).length !== 0
                        }
                        helperText={errors.dateRange?.message}
                        {...endProps}
                      />
                    </HHStack>
                  );
                }}
              />
            )}
          />
          <Controller //subscription ids
            control={control}
            name="subscriptionIds"
            render={({ field: { onChange, value } }): JSX.Element => (
              <HHTextField
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                layoutStyles={{ minWidth: 230, maxWidth: 230 }}
                hhVariant="variant-bypass"
                variant="standard"
                label={AdvancedFilterLabelText.SUBSCRIPTION_ID}
                multiline
                rows={rowHeight}
                value={value}
                onChange={(e): void => {
                  if (e.target.value.length > 15) {
                    setRowHeight(5);
                  } else {
                    setRowHeight(1);
                  }
                  onChange(
                    e.target.value.length > 0
                      ? formatLineBreakOrCsvInput(e.target.value)
                      : [],
                  );
                }}
                type="string"
                InputLabelProps={{ sx: { color: 'gray' } }}
                error={Object.keys(errors?.subscriptionIds ?? {}).length !== 0}
                helperText={errors.subscriptionIds?.message}
              />
            )}
          />

          {/* <Controller //payment type
            control={control}
            name="paymentType"
            render={({ field: { onChange, value } }): JSX.Element => ( */}
          <Autocomplete
            multiple
            id="tags-standard"
            disabled
            options={[]}
            // getOptionLabel={(option): string => option.name}
            size="small"
            sx={{ minWidth: 230, maxWidth: 230 }}
            limitTags={1}
            // onChange={(_, newValue): void => onChange(newValue)}
            // value={value}
            renderInput={(params): JSX.Element => (
              <HHTextField
                {...params}
                hhVariant="variant-bypass"
                variant="standard"
                label={AdvancedFilterLabelText.PAYMENT_TYPE}
              />
            )}
          />
          <Controller //user ids
            control={control}
            name="userIds"
            render={({ field: { onChange, value } }): JSX.Element => (
              <HHTextField
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                layoutStyles={{ minWidth: 230, maxWidth: 230 }}
                hhVariant="variant-bypass"
                variant="standard"
                label={AdvancedFilterLabelText.USER_ID}
                multiline
                rows={rowHeight}
                value={value}
                onChange={(e): void => {
                  if (e.target.value.length > 15) {
                    setRowHeight(5);
                  } else {
                    setRowHeight(1);
                  }
                  onChange(
                    e.target.value.length > 0
                      ? formatLineBreakOrCsvInput(e.target.value)
                      : [],
                  );
                }}
                type="string"
                InputLabelProps={{ sx: { color: 'gray' } }}
                error={Object.keys(errors?.userIds ?? {}).length !== 0}
                helperText={errors.userIds?.message}
              />
            )}
          />
          <Controller //clients
            control={control}
            name="clients"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                multiple
                id="tags-standard"
                options={!clientsError ? sortedClients : []}
                disabled={!!clientsError}
                getOptionLabel={(option): string => option.name}
                size="small"
                sx={{ minWidth: 230, maxWidth: 230 }}
                limitTags={1}
                onChange={(_, newValue): void => onChange(newValue)}
                value={value}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="standard"
                    label={AdvancedFilterLabelText.CLIENT}
                    error={!!clientsError}
                    helperText={clientsError ? 'clients unavailable' : ''}
                  />
                )}
              />
            )}
          />
          <Controller //insurers
            control={control}
            name="insurers"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                multiple
                id="tags-standard"
                options={!insurersError ? sortedInsurers : []}
                disabled={!!insurersError}
                getOptionLabel={(option): string => option.name}
                size="small"
                sx={{ minWidth: 230, maxWidth: 230 }}
                limitTags={1}
                onChange={(_, newValue): void => onChange(newValue)}
                value={value}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="standard"
                    label={AdvancedFilterLabelText.INSURER}
                    error={!!insurersError}
                    helperText={insurersError ? 'insurers unavailable' : ''}
                  />
                )}
              />
            )}
          />
          <Controller //partnerships
            control={control}
            name="partnerships"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                multiple
                id="tags-standard"
                options={!partnershipsError ? sortedPartnerships : []}
                disabled={!!partnershipsError}
                getOptionLabel={(option): string => option.name}
                isOptionEqualToValue={(option, value): boolean =>
                  option.id === value.id
                }
                size="small"
                limitTags={1}
                sx={{ minWidth: 230, maxWidth: 230 }}
                onChange={(_, newValue): void => onChange(newValue)}
                value={value}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="standard"
                    label={AdvancedFilterLabelText.PARTNERSHIP}
                    error={!!partnershipsError}
                    helperText={
                      partnershipsError ? 'partnerships unavailable' : ''
                    }
                  />
                )}
              />
            )}
          />
          <Controller //billing types
            control={control}
            name="billingTypes"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                multiple
                id="tags-standard"
                options={billTypesSelectOptions}
                size="small"
                sx={{ minWidth: 230, maxWidth: 230 }}
                limitTags={1}
                onChange={(_, newValue): void => onChange(newValue)}
                value={value}
                getOptionLabel={(option): string => option.label}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="standard"
                    label={AdvancedFilterLabelText.BILL_TYPE}
                  />
                )}
              />
            )}
          />
        </HHStack>
        <HHStack direction="row" spacing={4} alignSelf="flex-end">
          <HHButton size="small" hhVariant="text" onClick={handleCancel}>
            Cancel
          </HHButton>
          <HHButton
            size="small"
            type="submit"
            hhVariant="contained"
            onClick={onFormSubmit}
            disabled={!isValid || !isDirty}
          >
            Apply
          </HHButton>
          <HHIconButton
            hhVariant="variant-bypass"
            size="medium"
            color="default"
            aria-label="close-advanced-filters"
            onClick={filterToggle}
          >
            <Close />
          </HHIconButton>
        </HHStack>
      </HHStack>
    </form>
  );
};
