import { EngagementType } from '../../../../types';
import { ValidBillingModel } from '../../package/custom-package-types';

export interface SubTypeRules {
  defaultSelected: boolean;
  disabled: boolean;
  value: number;
}

export interface EngagementTypeWithRules
  extends BillingModelRule,
    EngagementType {}

export interface BillingModelRule {
  defaultSelected: boolean;
  disabled: boolean;
  subTypeOptions: Record<RuleType, SubTypeRules[]> | null;
}

export enum RuleType {
  enso_session = 'dailyCap',
}

export const getDefaultPackagePrice = (billingModel: string): number => {
  switch (billingModel) {
    case ValidBillingModel.FEE_FOR_SERVICE:
      return 50;
    case ValidBillingModel.ENGAGEMENT:
      return 1750;
    default:
      return 995;
  }
};

const defaultSubTypeOptions = (
  defaultSelected: boolean,
): Record<RuleType, SubTypeRules[]> => ({
  [RuleType.enso_session]: [
    {
      defaultSelected: false,
      disabled: false,
      value: 1,
    },
    {
      defaultSelected,
      disabled: false,
      value: 3,
    },
  ],
});

const defaultConfig = (
  defaultSelected: boolean,
  disabled: boolean,
  subTypeOptions: Record<RuleType, SubTypeRules[]> | null,
): BillingModelRule => ({
  defaultSelected,
  disabled,
  subTypeOptions,
});

export const getEngagementTypesConfig = (): Record<
  string,
  Record<string, BillingModelRule>
> => ({
  enso_session: {
    engagement: defaultConfig(false, false, defaultSubTypeOptions(true)),
    legacy: defaultConfig(false, false, defaultSubTypeOptions(true)),
    standard: defaultConfig(false, false, defaultSubTypeOptions(true)),
    individual_milestone: defaultConfig(
      false,
      false,
      defaultSubTypeOptions(true),
    ),
    cohort_milestone: defaultConfig(false, false, defaultSubTypeOptions(true)),
  },
  et_session: {
    engagement: defaultConfig(true, false, null),
    legacy: defaultConfig(true, false, null),
    standard: defaultConfig(true, false, null),
    individual_milestone: defaultConfig(true, false, null),
    cohort_milestone: defaultConfig(true, false, null),
  },
  article_read: {
    engagement: defaultConfig(false, true, null),
    legacy: defaultConfig(false, false, null),
    standard: defaultConfig(false, false, null),
    cohort_milestone: defaultConfig(false, false, null),
    individual_milestone: defaultConfig(false, false, null),
  },
  video_visit: {
    engagement: defaultConfig(false, false, null),
    legacy: defaultConfig(false, false, null),
    standard: defaultConfig(false, false, null),
    individual_milestone: defaultConfig(false, false, null),
    cohort_milestone: defaultConfig(false, false, null),
    fee_for_service: defaultConfig(true, false, null),
  },
  in_person_visit: {
    fee_for_service: defaultConfig(false, false, null),
  },
  care_team_communication: {
    engagement: defaultConfig(false, false, null),
    legacy: defaultConfig(false, true, null),
    standard: defaultConfig(false, true, null),
    individual_milestone: defaultConfig(false, true, null),
    cohort_milestone: defaultConfig(false, true, null),
  },
});

export const mapEngagementTypesToRules = (
  billingModel: string,
  engagementTypes: EngagementType[],
): EngagementTypeWithRules[] => {
  const engagementTypesConfig = getEngagementTypesConfig();
  return engagementTypes
    .filter(ent => engagementTypesConfig[ent.name]?.[billingModel])
    .map(ent => ({
      ...ent,
      ...engagementTypesConfig[ent.name][billingModel],
    }));
};
