import { ListItem, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { BatchClientInsurerType } from '../../types';
import { cleanTypeNameGraphql } from '../../utils/graphql-utils';
import { ClientInsurerBatchCard } from './batch-client-insurer-card';

export interface ClientInsurerBatchListProps {
  clientInsurers: BatchClientInsurerType[] | [];
  clientInsurerUrlIdsCount: number;
  onRemove: (id: number) => void;
}

export const CLIENT_INSURER_BATCH_LIST_CONTAINER =
  'client-insurer-batch-list-container';

export const ClientInsurerBatchList = ({
  clientInsurers,
  clientInsurerUrlIdsCount,
  onRemove,
}: ClientInsurerBatchListProps): JSX.Element => {
  const urlDifferenceWithClientInsurers =
    clientInsurerUrlIdsCount - clientInsurers.length;

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexGrow: 1,
        backgroundColor: '#EDEFF0',
        overflowY: 'scroll',
        padding: '1em',
      }}
      data-testid={CLIENT_INSURER_BATCH_LIST_CONTAINER}
    >
      <List>
        <ListItem alignItems="flex-start">
          <ListItemText primary="Selected client insurers" />
        </ListItem>
        {cleanTypeNameGraphql(clientInsurers).map(ci => (
          <ClientInsurerBatchCard
            client={ci.client}
            insurer={ci.insurer}
            id={ci.id}
            onRemove={onRemove}
            key={ci.id}
          />
        ))}
        {urlDifferenceWithClientInsurers >= 1 ? (
          <ListItem>
            <ListItemText
              primary={`Incorrect ID count:  ${urlDifferenceWithClientInsurers}`}
            />
          </ListItem>
        ) : null}
      </List>
    </Paper>
  );
};
