import {
  HHCircularProgress,
  HHTextField,
} from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CONTRACT_FORM_TEMPLATE_SELECT_ID,
  NO_DATA_TEMPLATE,
} from '../../../../constants/strings/contract/form-constants';

import { useEffect } from 'react';
import { ContractTemplateType } from '../../../../types';
import { filterTemplatesByPartnerships } from '../../../../utils/contract-utils';

export interface ContractTemplateSelectProps {
  partnershipIds: number[] | undefined;
  allTemplates: ContractTemplateType[];
  partnershipIdFlag: boolean;
  wholeFormDisabled: boolean | undefined;
}

export const ContractTemplateSelect = ({
  partnershipIds,
  allTemplates,
  partnershipIdFlag,
  wholeFormDisabled,
}: ContractTemplateSelectProps): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [watchedTemplate, watchedPartnershipId] = watch([
    'currentTemplate',
    'partnershipId',
  ]);
  // updating contract when template selected
  setValue('contract', watchedTemplate.contract);

  useEffect(() => {
    if (partnershipIdFlag) {
      const selectedTemplate = allTemplates.find(
        t => t.id === watchedTemplate.contractTemplateId,
      );
      if (
        selectedTemplate?.partnershipId &&
        selectedTemplate.partnershipId !== watchedPartnershipId
      ) {
        setValue('currentTemplate', NO_DATA_TEMPLATE, { shouldValidate: true });
      }
    }
  }, [
    allTemplates,
    partnershipIdFlag,
    setValue,
    watchedPartnershipId,
    watchedTemplate.contractTemplateId,
  ]);

  const filteredTemplates = filterTemplatesByPartnerships(
    partnershipIdFlag ? [watchedPartnershipId] : partnershipIds,
    allTemplates,
  );

  return filteredTemplates.length ? (
    <Controller
      control={control}
      name="currentTemplate"
      render={({ field: { onChange, value, ref } }): JSX.Element => (
        <Autocomplete
          ref={ref}
          key="currentTemplate"
          data-testid={CONTRACT_FORM_TEMPLATE_SELECT_ID}
          disabled={wholeFormDisabled}
          options={filteredTemplates}
          getOptionLabel={(option): string => option.name}
          isOptionEqualToValue={(option, value): boolean =>
            option.contractTemplateId === value.contractTemplateId
          }
          disableClearable
          onChange={(_, newValue): void => onChange(newValue)}
          value={value}
          renderInput={(params): JSX.Element => (
            <HHTextField
              {...params}
              label="Contract template"
              hhVariant="variant-bypass"
              variant="outlined"
              error={Object.keys(errors?.currentTemplate ?? {}).length !== 0}
              name="currentTemplate"
              helperText={errors.currentTemplate?.message}
              sx={{ '& .MuiInputLabel-root': { color: 'gray' } }}
            />
          )}
        />
      )}
    />
  ) : (
    <HHCircularProgress />
  );
};
