import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as yup from 'yup';
import { NO_DATA_TEMPLATE } from '../../../constants/strings/contract/form-constants';
import {
  BillingModelType,
  PackageOfferingsType,
  PackageType,
} from '../../../types';
import {
  extractMilestones,
  isDateGap,
  isDateRangeOverlappingContracts,
} from '../../../utils/contract-utils';
import { ContractTypeExtension } from '../form/custom-types';
import { yupContract } from '../form/validation-schema';

dayjs.extend(isSameOrBefore);

export const InnerPackageValidationSchema = yup.object().shape({
  id: yup.number().nullable().defined(),
  billingModelId: yup.number().min(1).required('Billing model is required'),
  packageId: yup
    .number()
    .min(1, 'Package is required')
    .required('Package is required')
    .when(
      ['$availablePackages', '$billingModels', 'billingModelId'],
      ([availablePackages, billingModels, billingModelId], schema) =>
        schema.test(
          'acuteOnlyPackage',
          'Acute only package can only be selected with standard billing model',
          value => {
            if (!availablePackages || !billingModels) return true;
            const selectedPackage = availablePackages.find(
              (p: PackageType) => p.id === value,
            );
            if (
              // when all program indications are acute only, only standard billing model is allowed
              selectedPackage?.packageOfferings.every(
                (po: PackageOfferingsType) =>
                  po?.programIndication?.includes('acute'),
              ) &&
              !billingModels.find(
                (bm: BillingModelType) =>
                  bm.name === 'standard' && bm.id === billingModelId,
              )
            ) {
              return false;
            }
            return true;
          },
        ),
    ),
  price: yup
    .number()
    .min(0, 'Price must be greater than or equal to 0')
    .typeError('Price invalid')
    .required('Price is required')
    .test('2 decimals', 'Price invalid', number =>
      Number.isInteger(number * 10 ** 2),
    ),
  initialFee: yup
    .number()
    .min(0, 'Initial fee must be greater than or equal to 0')
    .typeError('Initial fee invalid')
    .required('Initial fee is required')
    .test('2 decimals', 'Initial fee invalid', number =>
      Number.isInteger(number * 10 ** 2),
    ),
  activityFee: yup
    .number()
    .min(0, 'Activity fee must be greater than or equal to 0')
    .typeError('Activity fee invalid')
    .required('Activity fee is required')
    .test('2 decimals', 'Activity fee invalid', number =>
      Number.isInteger(number * 10 ** 2),
    ),
  selectedBillableActivities: yup
    .object()
    .defined()
    .test(
      'has at least 1',
      'at least 1 billable activity must be selected',
      value => Object.keys(value).length > 0,
    ),
  currentTemplate: yup
    .object()
    .optional()
    .shape({
      name: yup.string(),
      contract: yupContract,
      contractTemplateId: yup.number(),
    })
    .typeError('Invalid Template')
    .when(
      ['$billingModels', 'billingModelId', 'price'],
      ([billingModels, billingModelId, price], schema) => {
        const milestoneBillingModelIds = billingModels
          .filter((bm: BillingModelType) => bm.name.includes('milestone'))
          .map((bm: BillingModelType) => bm.id);
        if (milestoneBillingModelIds.includes(billingModelId)) {
          return schema.test({
            name: 'milestonePriceCheck',
            skipAbsent: false,
            test(value, ctx) {
              if (
                value?.contract?.length === 0 ||
                value?.name === NO_DATA_TEMPLATE.name
              )
                return true;

              const milestonePriceTotal = extractMilestones(
                value?.contract as ContractTypeExtension[],
              ).reduce((total, { payment }) => total + payment, 0);

              if (
                milestonePriceTotal !== price &&
                value?.name?.toUpperCase() !== 'STANDARD'
              ) {
                return ctx.createError({
                  message: 'Milestone prices do not match package price',
                });
              }
              return true;
            },
          });
        }
        return schema;
      },
    ),
});

export const PackageValidationSchema = yup.object({
  startDate: yup
    .date()
    .typeError('Invalid Date')
    .required('Start date is required')
    .nullable()
    .test({
      name: 'startDateInvalid',
      skipAbsent: false,
      test(value) {
        return dayjs(value).isValid();
      },
    })
    .test({
      name: 'startDatePastDisabled',
      skipAbsent: true,
      test(value, ctx) {
        const { startDateDisablePast } = ctx?.options?.context ?? {};
        if (
          startDateDisablePast &&
          dayjs(value).isBefore(dayjs(), 'day') === true
        ) {
          return ctx.createError({
            message: 'Start date is disabled before today',
          });
        } else return true;
      },
    })
    .test({
      name: 'startDateOverlaps',
      skipAbsent: true,
      test(value, ctx) {
        const { currentContracts, editId } = ctx?.options?.context ?? {};
        const { endDate } = ctx.parent;
        if (currentContracts && currentContracts.length >= 1 && value) {
          const startDateOverlapCheck = isDateRangeOverlappingContracts(
            value,
            endDate,
            currentContracts,
            editId,
          );
          if (startDateOverlapCheck.startDateOverlap) {
            return ctx.createError({
              path: 'startDate',
              message: 'Start date overlaps current contracts',
            });
          }
        }
        return true;
      },
    }),
  endDate: yup
    .date()
    .typeError('Invalid Date')
    .defined()
    .test({
      name: 'endDateInvalid',
      skipAbsent: false,
      test(value) {
        return value === null || dayjs(value).isValid();
      },
    })
    .test({
      name: 'endDateOverlap',
      skipAbsent: false,
      test(value, ctx) {
        const { currentContracts, editId } = ctx?.options?.context ?? {};
        const { startDate } = ctx.parent;
        if (currentContracts && currentContracts.length >= 1) {
          const endDateOverlapCheck = isDateRangeOverlappingContracts(
            startDate,
            value,
            currentContracts,
            editId,
          );
          if (endDateOverlapCheck.endDateOverlap) {
            return ctx.createError({
              message: 'End date overlaps current contracts',
            });
          }
        }
        return true;
      },
    })
    .test({
      name: 'endDateBeforeStart',
      skipAbsent: true,
      test(value, ctx) {
        const { startDate } = ctx.parent;
        if (dayjs(value).isBefore(startDate, 'day')) {
          return ctx.createError({
            message: 'End date is before start date',
          });
        }
        return true;
      },
    })
    .test({
      name: 'endDateGapCheck',
      skipAbsent: false,
      test(value, ctx) {
        const { currentContracts, editId } = ctx?.options?.context ?? {};
        if (currentContracts && currentContracts.length >= 1) {
          const endDateGapCheck = isDateGap(
            dayjs(value).format(),
            editId ?? null,
            currentContracts,
            'end',
          );
          if (endDateGapCheck.isGap) {
            return ctx.createError({
              message: `There is a gap with selected end date and next contract of ${endDateGapCheck.gapLength} days`,
            });
          }
        }
        return true;
      },
    })
    .nullable(),
  userAnnualCap: yup
    .number()
    .min(0, 'User annual cap must be greater than or equal to 0')
    .typeError('Invalid price')
    .required('User annual cap is required')
    .test('2 decimals', 'User annual cap invalid', number =>
      Number.isInteger(number * 10 ** 2),
    ),
  currency: yup
    .object()
    .shape({
      label: yup.string().default('USD $'),
      value: yup.string().default('USD'),
    })
    .defined(),
  isVoid: yup.boolean().defined(),
  partnershipId: yup.number().nullable().defined(),
  selectedPackages: yup
    .array()
    .min(1)
    .required()
    .of(InnerPackageValidationSchema)
    .test({
      name: 'uniquePackages',
      skipAbsent: false,
      test(value, ctx) {
        if (value.length <= 1) {
          return true;
        }
        const { availablePackages } = ctx?.options?.context ?? {};

        const seenPackageIds = new Set();

        for (const v of value) {
          const packageId = v.packageId;
          const packageName = (availablePackages as PackageType[]).find(
            p => p.id === packageId,
          )?.name;
          if (packageId && seenPackageIds.has(packageId)) {
            return ctx.createError({
              path: 'selectedPackages',
              message: `${packageName} package can only be selected once`,
            });
          }
          if (packageId) {
            seenPackageIds.add(packageId);
          }
        }

        return true;
      },
    })
    .test({
      name: 'uniqueProgramIndications',
      skipAbsent: false,
      test(value, ctx) {
        const { availablePackages } = ctx?.options?.context ?? {};
        if (value.length <= 1) {
          return true;
        }

        const seenProgramIndications = new Map();

        for (const v of value) {
          const currentPackage = (availablePackages as PackageType[]).find(
            p => p.id === v.packageId,
          );
          const currentPackageProgramIndications =
            currentPackage?.packageOfferings.map(po => po.programIndication) ??
            [];

          for (const pi of currentPackageProgramIndications) {
            if (seenProgramIndications.has(pi)) {
              const conflictingPackage = seenProgramIndications.get(pi);
              return ctx.createError({
                path: 'selectedPackages',
                message: `${currentPackage?.name} package and ${conflictingPackage.name} package have duplicate program indications`,
              });
            }
            seenProgramIndications.set(pi, currentPackage);
          }
        }

        return true;
      },
    })
    .test({
      name: 'userAnnualCapEqualToMaxPackagePrice',
      skipAbsent: false,
      test(value, ctx) {
        const { userAnnualCap } = ctx.parent;
        const maxPrice = Math.max(...value.map(pkg => pkg.price));
        if (userAnnualCap !== maxPrice) {
          return ctx.createError({
            path: 'userAnnualCap',
            message: `User annual cap must be equal to the highest package price (${maxPrice})`,
          });
        }
        return true;
      },
    })
    .test({
      name: 'engagement not allow before 8/1/2024',
      skipAbsent: false,
      test(value, ctx) {
        const { startDate } = ctx.parent;
        const { billingModels } = ctx?.options?.context ?? {};
        const engagementBillingModelId = billingModels.find(
          (bm: BillingModelType) => bm.name === 'engagement',
        )?.id;
        if (
          value.some(pkg => pkg.billingModelId === engagementBillingModelId) &&
          dayjs(startDate).isBefore('2024-08-01', 'day')
        ) {
          return ctx.createError({
            path: 'startDate',
            message:
              'Engagement billing model cannot be selected before 8/1/2024',
          });
        }
        return true;
      },
    })
    .test({
      name: 'AllowOnlyOneEngagementOrMilestonePackage',
      skipAbsent: false,
      test(value, ctx) {
        const { billingModels } = ctx?.options?.context ?? {};
        const engagementOrMilestoneIds = billingModels
          .filter(
            (bm: BillingModelType) =>
              bm.name === 'engagement' || bm.name.includes('milestone'),
          )
          .map((bm: BillingModelType) => bm.id);

        if (
          value.filter(cp =>
            engagementOrMilestoneIds.includes(cp.billingModelId),
          ).length > 1
        ) {
          return ctx.createError({
            path: 'selectedPackages',
            message: 'Only one engagement or milestone package is allowed',
          });
        }
        return true;
      },
    }),
});
