import {
  HHButton,
  HHStack,
  HHTooltip,
  HHTypography,
} from '@hinge-health/react-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { PackageType } from '../../../types';
import { getEditDefaultValues, IDiscount } from '../../../utils/discount-utils';
import { DiscountDatePickers } from './components/date-pickers';
import { DiscountInput } from './components/discount-input';
import { DiscountTypeSelect } from './components/discount-type-select';
import { PackageSelect } from './components/package-select';
import { ProgramSelect } from './components/program-select';
import { SuppressEmptyChargesCheckbox } from './components/suppress-empty-charges';
import DiscountInfo from './discount-info';
import {
  DiscountFormValues,
  DISCOUNT_CANCEL_BUTTON_TEST_ID,
  DISCOUNT_SUBMIT_BUTTON_TEST_ID,
  ParsedDiscountValues,
} from './form-constants';
import { DiscountValidationSchema } from './validation-schema';

export interface DiscountFormProps {
  clientId: number;
  formTitle: string;
  onSubmitCallback: (values: ParsedDiscountValues) => void;
  onCancelCallback: () => void;
  submitTitle?: string | null;
  discountToEdit: IDiscount | null;
  currentDiscounts?: IDiscount[];
  availablePackages: PackageType[];
}

export const DiscountForm = ({
  clientId,
  formTitle,
  onSubmitCallback,
  onCancelCallback,
  submitTitle,
  discountToEdit,
  currentDiscounts,
  availablePackages,
}: DiscountFormProps): JSX.Element => {
  const defaultValues = {
    clientId,
    discountType: '',
    startDate: null,
    endDate: null,
    program: '',
    packageId: '',
    discount: '',
    suppressEmptyCharges: true,
  };

  const methods = useForm({
    mode: 'onChange',
    values: discountToEdit
      ? getEditDefaultValues(discountToEdit)
      : defaultValues,
    context: { currentDiscounts, discountToEdit },
    // Ignoring because we want null initial start/end date, but don't want it nullable in validation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(DiscountValidationSchema),
    criteriaMode: 'all',
  });

  const { handleSubmit, formState, reset } = methods;
  const { isValid, errors, isSubmitting, isDirty, isSubmitted } = formState;

  const parseFormValues = (data: DiscountFormValues): ParsedDiscountValues => ({
    clientId: data.clientId,
    discount:
      typeof data.discount === 'string'
        ? parseInt(data.discount)
        : data.discount,
    discountType: data.discountType,
    endDate: data.endDate ? dayjs(data.endDate).format('YYYY-MM-DD') : null,
    programId: data.program ? parseInt(data.program) : null,
    packageId: data.packageId ? parseInt(data.packageId) : null,
    startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
    suppressEmptyCharges: data.suppressEmptyCharges,
  });

  const onFormSubmit = (data: DiscountFormValues): void => {
    onSubmitCallback(parseFormValues(data));
  };

  return (
    <FormProvider {...methods}>
      <form>
        <HHStack spacing={6}>
          <HHStack direction="row" spacing={2}>
            <HHTypography hhVariant="section-title">{formTitle}</HHTypography>
            <HHTooltip
              hhVariant="variant-bypass"
              placement="top"
              title={<DiscountInfo />}
            >
              <InfoOutlined fontSize="small" />
            </HHTooltip>
          </HHStack>
          <HHStack direction="column" spacing={6}>
            <HHStack spacing={6}>
              <DiscountTypeSelect discountToEdit={discountToEdit} />
              <HHStack direction="row" spacing={4}>
                <ProgramSelect discountToEdit={discountToEdit} />
                <HHTypography
                  hhVariant="muted"
                  layoutStyles={{ alignSelf: 'center' }}
                >
                  or
                </HHTypography>
                <PackageSelect
                  discountToEdit={discountToEdit}
                  availablePackages={availablePackages}
                />
              </HHStack>
            </HHStack>
            <DiscountDatePickers discountToEdit={discountToEdit} />
            <HHStack direction="row" spacing={4}>
              <DiscountInput discountToEdit={discountToEdit} />
              <SuppressEmptyChargesCheckbox />
            </HHStack>
          </HHStack>
          <HHStack direction="row" spacing={6} alignSelf="end">
            <HHButton
              data-testid={DISCOUNT_CANCEL_BUTTON_TEST_ID}
              hhVariant="variant-bypass"
              variant="outlined"
              size="medium"
              color="primary"
              onClick={(): void => {
                reset(defaultValues);
                onCancelCallback();
              }}
            >
              Cancel
            </HHButton>
            <HHButton
              data-testid={DISCOUNT_SUBMIT_BUTTON_TEST_ID}
              hhVariant="variant-bypass"
              variant="contained"
              size="medium"
              color="primary"
              disabled={
                !isDirty || (errors && !isValid) || isSubmitting || isSubmitted
              }
              // adding ignore here because we want initial null value, but don't want to allow null start date
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onClick={handleSubmit(onFormSubmit)}
            >
              {submitTitle}
            </HHButton>
          </HHStack>
        </HHStack>
      </form>
    </FormProvider>
  );
};
