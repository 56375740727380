import {
  HHChip,
  HHCollapsibleTable,
  HHLink,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import { routes } from '../../constants/strings/routes';
import { useDynamicDrawerContext } from '../../context/dynamic-drawer.context';
import { FeeForServiceEventPaginated } from '../../types';
import { ActiveStatus, calculateChipColor } from '../../utils/contract-utils';
import LoadingComponent from '../utils/loading';
import MoreDetailsButton from '../utils/more-details-button';
import FeeForServiceData from './fee-for-service-data';
import FeeForServiceDetail from './fee-for-service-detail';

export interface FeeForServiceListProps {
  data?: FeeForServiceEventPaginated;
  loading: boolean;
}

const FeeForServiceList = ({
  data,
  loading,
}: FeeForServiceListProps): JSX.Element => {
  const [openRowId, setOpenRowId] = useState<number | null>(null);
  const { setDrawerContent } = useDynamicDrawerContext();

  const handleOpenRow =
    (id: number): React.MouseEventHandler<HTMLButtonElement> =>
    (): void => {
      if (openRowId !== id) {
        setOpenRowId(id);
      } else {
        setOpenRowId(null);
      }
    };

  if (loading) {
    return <LoadingComponent center />;
  }

  if (!data) {
    return (
      <HHTypography hhVariant="body">
        User Fee for Service not found
      </HHTypography>
    );
  }

  return (
    <HHCollapsibleTable
      headers={[
        {
          align: 'left',
          headerTitle: 'Id',
        },
        {
          align: 'left',
          headerTitle: 'Occurred Date',
        },
        {
          align: 'left',
          headerTitle: 'Client Insurer Id',
        },
        {
          align: 'left',
          headerTitle: 'Status',
        },
        {
          align: 'left',
          headerTitle: '',
        },
      ]}
      emptyRowContent={
        <HHStack direction="row" padding={4} justifyContent="center">
          <HHTypography hhVariant="muted">No fee for service</HHTypography>
        </HHStack>
      }
      rows={
        data.items
          ? data.items.map(ffs => ({
              open: openRowId === ffs.id,
              setOpen: handleOpenRow(ffs.id),
              rowId: ffs.id,
              collapsibleRowContent: <FeeForServiceData data={ffs} />,
              columnStyles: {
                textTransform: 'capitalize',
              },
              columnValues: [
                {
                  columnItem: ffs.id,
                },
                {
                  columnItem: dayjs(ffs.occurredAt).format('L'),
                },
                {
                  columnItem: (
                    <HHLink
                      href={`${routes.billing.main}/${ffs.clientsInsurerId}`}
                      target="_blank"
                    >
                      {ffs.clientsInsurerId}
                    </HHLink>
                  ),
                },
                {
                  columnItem: (
                    <HHChip
                      hhVariant="variant-bypass"
                      color={calculateChipColor(
                        ffs.void ? ActiveStatus.Void : ActiveStatus.Active,
                      )}
                      size="small"
                      label={ffs.void ? ActiveStatus.Void : ActiveStatus.Active}
                    />
                  ),
                },
                {
                  columnItem: (
                    <MoreDetailsButton
                      onClick={(): void =>
                        setDrawerContent(
                          <FeeForServiceDetail
                            feeForServiceEventData={ffs}
                            key={ffs.id}
                          />,
                          <HHTypography hhVariant="section-title">
                            Fee for service
                          </HHTypography>,
                        )
                      }
                    />
                  ),
                },
              ],
            }))
          : []
      }
    />
  );
};

export default FeeForServiceList;
