import {
  HHDivider,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Maybe } from 'yup';
import { ActiveContractLabels } from '../../constants/strings/billing-label-enums';
import { IN_PERSON_NAME_KEY } from '../../constants/strings/contract/form-constants';
import {
  ContractPackagesType,
  ContractType,
  PackageType,
  useGetAllPackagesQuery,
} from '../../types';
import { capitalizeFirstLetterAndSpaceCamelCaseString } from '../../utils/bills-utils';
import { formatCurrency } from '../../utils/currency-helpers';
import { computeEffectiveRange } from '../../utils/date-helper';
import { TextGrouping } from '../utils/text-grouping';
import { PackageBillableActivityDisplay } from './contract-view-only/billable-activity-display';
import { SelectedPackageIndications } from './package/components/form-right-panel';
import { ValidBillingModel } from './package/custom-package-types';

interface ProductPackageContractViewProps {
  contract: ContractType;
  productPackages: { key: string; contractPackage: ContractPackagesType }[];
}

interface ProductPackageDetailViewProps {
  productPackage: { key: string; contractPackage: ContractPackagesType };
  availablePackages: PackageType[];
  currency?: string | Maybe<string>;
}

const EngagementProductPackageView = ({
  productPackage,
  availablePackages,
  currency,
}: ProductPackageDetailViewProps): JSX.Element => (
  <HHStack>
    <HHTypography hhVariant="variant-bypass">
      {`${
        productPackage.key == IN_PERSON_NAME_KEY
          ? capitalizeFirstLetterAndSpaceCamelCaseString(productPackage.key)
          : productPackage.key
      } package`}
    </HHTypography>
    <HHStack direction="row" spacing={4}>
      <HHStack direction="column" spacing={4} flex={1}>
        <TextGrouping
          label="Price"
          text={formatCurrency(productPackage.contractPackage.price, currency)}
        />
        <HHStack direction="column" spacing={4}>
          <PackageBillableActivityDisplay
            packageBillableActivities={
              productPackage.contractPackage.billableActivityTypes ?? []
            }
          />
        </HHStack>
      </HHStack>
      <HHStack direction="column" spacing={4} flex={1}>
        {productPackage.contractPackage.billingModel.name ===
          ValidBillingModel.ENGAGEMENT && (
          <HHStack direction="column" spacing={4}>
            <TextGrouping
              label={ActiveContractLabels.ENGAGEMENT_INITIAL_FEE}
              text={formatCurrency(
                productPackage.contractPackage.rules?.activationFee ?? 0,
                currency,
              )}
            />
            <TextGrouping
              label={ActiveContractLabels.ENGAGEMENT_ACTIVITY_FEE}
              text={formatCurrency(
                productPackage.contractPackage.rules?.activityFee ?? 0,
                currency,
              )}
            />
          </HHStack>
        )}
        <SelectedPackageIndications
          selectedPackageId={productPackage.contractPackage.id}
          availablePackages={availablePackages ?? []}
        />
      </HHStack>
    </HHStack>
  </HHStack>
);

const ProductPackageViewMapper = ({
  productPackage,
  currency,
}: Omit<ProductPackageDetailViewProps, 'availablePackages'>): JSX.Element => {
  const { data: availablePackages } = useGetAllPackagesQuery();

  if (
    Object.values(ValidBillingModel).includes(
      productPackage.contractPackage.billingModel.name as ValidBillingModel,
    )
  ) {
    return (
      <EngagementProductPackageView
        productPackage={productPackage}
        availablePackages={availablePackages?.getAllPackages ?? []}
        currency={currency}
      />
    );
  } else {
    return (
      <HHTypography hhVariant="muted">
        {`Invalid billing model for package: ${productPackage.contractPackage.billingModel.name}`}
      </HHTypography>
    );
  }
};

const ProductPackageContractView = ({
  contract,
  productPackages,
}: ProductPackageContractViewProps): JSX.Element => (
  <HHStack
    direction="column"
    spacing={4}
    padding={4}
    sx={{ backgroundColor: 'background.default' }}
  >
    <HHStack direction="row" spacing={4}>
      <HHStack flex={1}>
        <TextGrouping
          label={ActiveContractLabels.EFFECT_DATE_LABEL}
          text={computeEffectiveRange(contract.startDate, contract.endDate)}
        />
        <TextGrouping
          label={ActiveContractLabels.CURRENCY}
          text={contract.currency ?? 'Currency not found'}
        />
      </HHStack>
      <HHStack flex={1}>
        <TextGrouping
          label={ActiveContractLabels.USER_ANNUAL_CAP}
          text={
            contract.userAnnualCap
              ? formatCurrency(contract.userAnnualCap, contract.currency)
              : 'No user annual spending cap found'
          }
        />
      </HHStack>
    </HHStack>
    {productPackages.map(pp => (
      <HHStack spacing={2} key={pp.key}>
        <HHDivider />
        <ProductPackageViewMapper
          key={pp.key}
          productPackage={pp}
          currency={contract.currency}
        />
      </HHStack>
    ))}
  </HHStack>
);

export default ProductPackageContractView;
