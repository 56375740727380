import { IN_PERSON_NAME_KEY } from '../../../constants/strings/contract/form-constants';
import { ContractTemplateType, ContractType } from '../../../types';
import { extractProductPackages } from '../../../utils/contract-utils';
import LoadingComponent from '../../utils/loading';
import LegacyContractDetailView from './legacy-contract-detail-view';
import ProductPackageContractView from './product-package-detail-view';

interface ContractDetailViewProps {
  templates: ContractTemplateType[];
  contract?: ContractType;
  showJson?: boolean;
  slimDisplay?: boolean;
}
const ContractDetailView = ({
  templates,
  contract,
  slimDisplay = false,
}: ContractDetailViewProps): JSX.Element => {
  if (!contract || templates.length === 0) {
    return <LoadingComponent />;
  }
  const contractPackages = extractProductPackages(contract.contractPackages);
  const inPersonVisitContractPackage = contractPackages.find(
    contractPackage => contractPackage.key === IN_PERSON_NAME_KEY,
  );
  const isPackageEnabledContract =
    contract.packageEnabled && contractPackages.length > 0;

  return isPackageEnabledContract ? (
    <ProductPackageContractView
      contract={contract}
      productPackages={contractPackages}
      templates={templates}
      slimDisplay={slimDisplay}
    />
  ) : (
    <LegacyContractDetailView
      contract={contract}
      templates={templates}
      inPersonVisitContractPackage={
        inPersonVisitContractPackage?.contractPackage
      }
      slimDisplay={slimDisplay}
    />
  );
};

export default ContractDetailView;
