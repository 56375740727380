import { ApolloError } from '@apollo/client';

/**
 * This function removes the __typename property from an object.
 * Should only be used when we have a display need of the raw object
 * otherwise the _typename property can flow through items
 */
export const cleanTypeNameGraphql = <T>(obj: T): T =>
  JSON.parse(JSON.stringify(obj), (key, value) =>
    key === '__typename' ? undefined : value,
  );

export interface GqlNetworkStatus {
  loading: boolean;
  error?: ApolloError;
}
