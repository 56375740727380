import { HHChip, HHStack } from '@hinge-health/react-component-library';
import { BillableActivityType, EngagementType } from '../../../types';
import { getBillableActivityDisplayString } from '../../../utils/package-utils';
import { ContractTypeExtension } from '../form/custom-types';
import { BillableActivitiesWithDetails } from '../package/custom-package-types';
import { MilestoneProps } from './milestones';

export interface ConfigurableDisplayProps
  extends Pick<
    MilestoneProps,
    | 'selectedBillableActivities'
    | 'engagementTypes'
    | 'contractBillableActivities'
  > {
  milestone: ContractTypeExtension;
  selectedBillableActivities?: BillableActivitiesWithDetails; //selected billable activities in form;
}
interface PackageBillableActivityDisplayProps {
  packageBillableActivities:
    | string[]
    | BillableActivityType[]
    | EngagementType[];
}

export const BillableActivityNameDisplay = ({
  billableActivityNames,
}: {
  billableActivityNames: string[];
}): JSX.Element => (
  <HHStack direction="row" flexWrap="wrap">
    {billableActivityNames
      .toSorted((a, b) => a.localeCompare(b))
      .map(ba => (
        <HHChip
          key={ba}
          hhVariant="variant-bypass"
          size="small"
          label={ba}
          sx={{ marginRight: 2, marginBottom: 2 }}
        />
      ))}
  </HHStack>
);

export const PackageBillableActivityDisplay = ({
  packageBillableActivities,
}: PackageBillableActivityDisplayProps): JSX.Element => {
  const billableActivityNames = packageBillableActivities.map(ba =>
    getBillableActivityDisplayString(ba),
  );

  return (
    <BillableActivityNameDisplay
      billableActivityNames={billableActivityNames}
    />
  );
};

export const ConfigurableActivityDisplay = ({
  milestone,
  selectedBillableActivities,
  engagementTypes,
  contractBillableActivities,
}: ConfigurableDisplayProps): JSX.Element => {
  let billableActivityNames = ['configurable'];
  if (
    milestone.billableActivityTypes &&
    (milestone.billableActivityTypes[0] === 'configurable' ||
      milestone.billableActivityTypes.length === 0) &&
    !selectedBillableActivities &&
    contractBillableActivities
  ) {
    billableActivityNames = contractBillableActivities?.map(ba =>
      getBillableActivityDisplayString(ba),
    );
  } else if (
    milestone.billableActivityTypes &&
    milestone.billableActivityTypes.length > 0 &&
    milestone.billableActivityTypes[0] !== 'configurable'
  ) {
    billableActivityNames = milestone.billableActivityTypes.map(ba =>
      getBillableActivityDisplayString(ba),
    );
  } else if (
    milestone.billableActivityTypes &&
    (milestone.billableActivityTypes[0] === 'configurable' ||
      milestone.billableActivityTypes.length === 0) &&
    selectedBillableActivities &&
    Object.keys(selectedBillableActivities).length > 0 &&
    engagementTypes
  ) {
    billableActivityNames = engagementTypes
      ?.filter(ba => ba.id in selectedBillableActivities)
      .map(ba => getBillableActivityDisplayString(ba));
  }
  return (
    <BillableActivityNameDisplay
      billableActivityNames={billableActivityNames}
    />
  );
};
