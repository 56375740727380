import {
  HHFormGroup,
  HHIconButton,
  HHStack,
} from '@hinge-health/react-component-library';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { BillableActivitiesProps } from './billable-activities';
import BillableActivityCheckBox from './billable-activity-checkbox';
import { EngagementTypeWithRules } from './engagement-types-rules';
import BillableActivitySubTypes from './sub-types';

export const CONTRACT_FORM_BILLABLE_ACTIVITIES_WITH_SUBTYPES_GROUP_ID =
  'contract-form-billable-activities-with-subtypes-group';

export interface BillableActivitiesWithSubTypesProps
  extends BillableActivitiesProps {
  engagementTypes: EngagementTypeWithRules[];
}

const BillableActivitiesWithSubTypes = ({
  wholeFormDisabled,
  engagementTypes,
  direction,
  isPackageForm = false,
  index = 0,
}: BillableActivitiesWithSubTypesProps): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState<number | string>('');

  const handleClick = (index: number): void => {
    if (selectedIndex === index) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <HHFormGroup
      data-testid={CONTRACT_FORM_BILLABLE_ACTIVITIES_WITH_SUBTYPES_GROUP_ID}
      sx={{
        alignItems: 'flex-start',
        flexDirection: direction ?? 'column',
      }}
    >
      <HHStack direction="column" alignItems="flex-start" flexGrow={1}>
        {engagementTypes
          ?.filter(ent => ent.subTypeOptions === null)
          .map(ent => (
            <BillableActivityCheckBox
              key={ent.id}
              engagementType={ent}
              isPackageForm={isPackageForm}
              wholeFormDisabled={wholeFormDisabled}
              index={index}
            />
          ))}
      </HHStack>
      {/* sub type billable activity in their own column display */}
      {engagementTypes
        ?.filter(ent => ent.subTypeOptions !== null)
        .map((ent, idx) => (
          <HHStack
            direction="column"
            key={`${ent.id}-parent`}
            alignItems="flex-start"
            flexGrow={1}
          >
            <HHStack direction="row" alignItems="center">
              {ent.subTypeOptions !== null ? (
                <HHIconButton
                  onClick={(): void => handleClick(idx)}
                  hhVariant={'variant-bypass'}
                >
                  {selectedIndex === idx ? <ExpandLess /> : <ExpandMore />}
                </HHIconButton>
              ) : null}
              <BillableActivityCheckBox
                engagementType={ent}
                isPackageForm={isPackageForm}
                wholeFormDisabled={wholeFormDisabled}
                index={index}
              />
            </HHStack>
            {ent.subTypeOptions !== null && selectedIndex !== idx ? (
              <BillableActivitySubTypes
                key={ent.id}
                engagementType={ent}
                wholeFormDisabled={wholeFormDisabled}
                index={index}
              />
            ) : null}
          </HHStack>
        ))}
    </HHFormGroup>
  );
};

export default BillableActivitiesWithSubTypes;
