import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as yup from 'yup';
import { NO_DATA_TEMPLATE } from '../../../constants/strings/contract/form-constants';
import {
  extractMilestones,
  isDateGap,
  isDateRangeOverlappingContracts,
} from '../../../utils/contract-utils';
import { ContractTypeExtension } from './custom-types';

dayjs.extend(isSameOrBefore);

const yupContract = yup.array().defined().of(yup.object());

export const ContractValidationSchema = yup.object({
  contract: yupContract,
  currentTemplate: yup
    .object()
    .shape({
      name: yup.string().required().min(1, 'Invalid Template'),
      contract: yupContract,
      contractTemplateId: yup.number().required().min(1, 'Invalid Template'),
    })
    .typeError('Invalid Template')
    .required()
    .test({
      name: 'milestonePriceCheck',
      skipAbsent: true,
      test(value, ctx) {
        if (
          value.contract?.length === 0 ||
          value.name === NO_DATA_TEMPLATE.name
        )
          return true;

        const milestonePriceTotal = extractMilestones(
          value.contract as ContractTypeExtension[],
        ).reduce((total, { payment }) => total + payment, 0);

        if (
          milestonePriceTotal !== ctx.parent.chronicCoreCharge &&
          value.name.toUpperCase() !== 'STANDARD'
        ) {
          return ctx.createError({
            message: 'Milestone prices do not match chronic price',
          });
        }
        return true;
      },
    }),
  currency: yup.object().shape({
    label: yup.string().default('USD $'),
    value: yup.string().default('USD'),
  }),
  chronicCoreCharge: yup
    .number()
    .min(0, 'Charge must be 0 or more')
    .typeError('Invalid charge')
    .required()
    .test(
      'chronic-maxDigitsAfterDecimal',
      'number field must have 2 digits after decimal or less',
      number => Number.isInteger(number * 10 ** 2),
    )
    .when('currentTemplate', ([currentTemplate], schema) => {
      if (currentTemplate) {
        return schema.test({
          name: 'chronicMilestoneCheck',
          skipAbsent: false,
          test(value, ctx) {
            if (currentTemplate.contract?.length === 0) return true;

            const milestonePriceTotal = extractMilestones(
              currentTemplate.contract as ContractTypeExtension[],
            ).reduce((total, { payment }) => total + payment, 0);

            if (
              milestonePriceTotal !== value &&
              currentTemplate.name.toUpperCase() !== 'STANDARD'
            ) {
              return ctx.createError({
                message: 'Core charge does not match Milestone prices',
              });
            } else return true;
          },
        });
      }
      return schema.required('Valid template required');
    }),
  startDate: yup
    .date()
    .typeError('Invalid Date')
    .required('Start date required')
    .nullable()
    .test({
      name: 'startDateInvalid',
      skipAbsent: false,
      test(value, ctx) {
        if (value == null) {
          return ctx.createError({
            message: 'Start date required',
          });
        }
        return dayjs(value).isValid();
      },
    })
    .test({
      name: 'startDatePastDisabled',
      skipAbsent: true,
      test(value, ctx) {
        const { startDateDisablePast } = ctx?.options?.context ?? {};
        if (
          startDateDisablePast &&
          dayjs(value).isBefore(dayjs(), 'day') === true
        ) {
          return ctx.createError({
            message: 'Start date is disabled before today',
          });
        } else return true;
      },
    })
    .test({
      name: 'startDateOverlaps',
      skipAbsent: true,
      test(value, ctx) {
        const { currentContracts, contractEditId } =
          ctx?.options?.context ?? {};
        const { endDate } = ctx.parent;
        if (currentContracts && currentContracts.length >= 1 && value) {
          const startDateOverlapCheck = isDateRangeOverlappingContracts(
            value,
            endDate,
            currentContracts,
            contractEditId,
          );
          if (startDateOverlapCheck.startDateOverlap) {
            return ctx.createError({
              path: 'startDate',
              message: 'Start date overlaps current contracts',
            });
          }
        }
        return true;
      },
    }),
  endDate: yup
    .date()
    .nullable()
    .typeError('Invalid Date')
    .defined()
    .test({
      name: 'endDateInvalid',
      skipAbsent: false,
      test(value) {
        return value === null || dayjs(value).isValid();
      },
    })
    .test({
      name: 'endDateOverlap',
      skipAbsent: false,
      test(value, ctx) {
        const { currentContracts, contractEditId } =
          ctx?.options?.context ?? {};
        const { startDate } = ctx.parent;
        if (currentContracts && currentContracts.length >= 1) {
          const endDateOverlapCheck = isDateRangeOverlappingContracts(
            startDate,
            value,
            currentContracts,
            contractEditId,
          );
          if (endDateOverlapCheck.endDateOverlap) {
            return ctx.createError({
              message: 'End date overlaps current contracts',
            });
          }
        }
        return true;
      },
    })
    .test({
      name: 'endDateBeforeStart',
      skipAbsent: true,
      test(value, ctx) {
        const { startDate } = ctx.parent;
        if (dayjs(value).isBefore(startDate, 'day')) {
          return ctx.createError({
            message: 'End date is before start date',
          });
        }
        return true;
      },
    })
    .test({
      name: 'endDateGapCheck',
      skipAbsent: false,
      test(value, ctx) {
        const { currentContracts, contractEditId } =
          ctx?.options?.context ?? {};
        if (currentContracts && currentContracts.length >= 1) {
          const endDateGapCheck = isDateGap(
            dayjs(value).format(),
            contractEditId ?? null,
            currentContracts,
            'end',
          );
          if (endDateGapCheck.isGap) {
            return ctx.createError({
              message: `There is a gap with selected end date and next contract of ${endDateGapCheck.gapLength} days`,
            });
          }
        }
        return true;
      },
    })
    .test({
      /* submissions present, but it's an edit and the new end date is after today */
      name: 'allowSubmitWhenSubmissionsPresent',
      skipAbsent: false,
      test(value, ctx) {
        const { submissionCount, contractEditId } = ctx?.options?.context ?? {};
        //adding 1 day to disallow setting end to today
        const dateIsBeforeToday =
          dayjs(value).isValid() &&
          dayjs(value).isBefore(dayjs().add(1, 'day'), 'day');

        if (submissionCount >= 1 && contractEditId && dateIsBeforeToday) {
          return ctx.createError({
            message: 'Submissions present on selected end date',
          });
        }
        return true;
      },
    }),
  enableInPersonVisit: yup.boolean().defined(),
  contractPackages: yup
    .array()
    .defined()
    .min(0)
    .of(
      yup.object().shape({
        price: yup
          .number()
          .min(0, 'Charge must be 0 or more')
          .typeError('Invalid charge')
          .required('Price required')
          .test(
            'maxDigitsAfterDecimal',
            'number field must have 2 digits after decimal or less',
            number => Number.isInteger(number * 10 ** 2),
          ),
        startDate: yup
          .date()
          .typeError('Invalid Date')
          .required('Start date required')
          .nullable()
          .test({
            name: 'startDateInvalid',
            skipAbsent: false,
            test(value, ctx) {
              if (value == null) {
                return ctx.createError({
                  message: 'Start date required',
                });
              }

              return dayjs(value).isValid();
            },
          })
          .test({
            name: 'startDatePastDisabled',
            skipAbsent: true,
            test(value, ctx) {
              const { startDateDisablePast } = ctx?.options?.context ?? {};
              if (
                startDateDisablePast &&
                dayjs(value).isBefore(dayjs(), 'day') === true
              ) {
                return ctx.createError({
                  message: 'Start date is disabled before today',
                });
              } else return true;
            },
          })
          .test({
            name: 'startDateBeforeContractStartDate',
            skipAbsent: true,
            test(value, ctx) {
              const { startDate } = ctx.from?.[1]?.value ?? {};
              if (dayjs(value).isBefore(startDate, 'day')) {
                return ctx.createError({
                  message: 'Effective date is before contract start date',
                });
              }
              return true;
            },
          })
          .test({
            name: 'effectiveDateAfterEndDate',
            skipAbsent: true,
            test(value, ctx) {
              const { endDate } = ctx.from?.[1]?.value ?? {};
              if (endDate && dayjs(value).isAfter(endDate, 'day')) {
                return ctx.createError({
                  message: 'Effective date is after contract end date',
                });
              }
              return true;
            },
          }),
        id: yup.number().nullable().defined().default(null),
        packageId: yup.number().nullable().defined(),
        billingModelId: yup.number().nullable().defined(),
        billableActivities: yup.object().defined(),
      }),
    ),
  isVoid: yup.boolean().defined(),
  selectedBillableActivities: yup
    .object()
    .defined()
    .test(
      'has at least 1',
      'at least 1 billable activity must be selected',
      value => Object.keys(value).length > 0,
    ),
  enableAcuteProgram: yup.boolean().defined(),
  acuteCoreCharge: yup
    .number()
    .min(0, 'Charge must be 0 or more')
    .typeError('Invalid charge')
    .required()
    .test(
      'acute-maxDigitsAfterDecimal',
      'number field must have 2 digits after decimal or less',
      number => Number.isInteger(number * 10 ** 2),
    ),
  partnershipId: yup.number().nullable().defined(),
});
