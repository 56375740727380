import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DiscountFormValues } from '../components/discounts/form/form-constants';
import { DiscountUsage, PackageType } from '../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from './bills-utils';
import { ActiveStatus } from './contract-utils';
import { GqlNetworkStatus } from './graphql-utils';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export enum DiscountType {
  billingCaps = 'billing_caps',
  innovationCredits = 'innovation_credits',
  volumeBasedDiscounts = 'volume_based_discounts',
  programAccess = 'program_access',
  freeBillableActivities = 'free_billable_activities',
}

export enum InvalidProgramDiscounts {
  'free_billable_activities',
}

export enum InvalidPackagesForDiscounts {
  'in_person_visit',
}

export interface DiscountTypeOption {
  value: keyof DiscountType;
  displayName: string;
}

export const DiscountTypeSelectOptions = (
  Object.keys(DiscountType) as (keyof typeof DiscountType)[]
)
  .map(key => ({
    value: DiscountType[key],
    displayName: DiscountType[key].replaceAll('_', ' '),
  }))
  .filter(x => !['volume_based_discounts'].includes(x.value));

export const getEditDiscountAmount = (discountToEdit: IDiscount): number => {
  switch (discountToEdit.discountType) {
    case DiscountType.billingCaps:
      return discountToEdit.discountDetails[1].discount;
    case DiscountType.programAccess:
      return discountToEdit.discountDetails[0].discount;
    case DiscountType.freeBillableActivities:
      return discountToEdit.discountDetails[0].discount;
    case DiscountType.innovationCredits:
      const subscriptionTier =
        discountToEdit.discountDetails[0].subscriptionTier;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return subscriptionTier[1] - 1;
    /* istanbul ignore next */
    default:
      return discountToEdit.discountDetails[1].discount;
  }
};

export const getEditDefaultValues = (
  discountToEdit: IDiscount,
): DiscountFormValues => {
  const discountAmount = getEditDiscountAmount(discountToEdit);
  return {
    clientId: discountToEdit.clientId ?? 0,
    discountType: discountToEdit.discountType,
    startDate: dayjs(discountToEdit.startDate).toDate(),
    endDate: dayjs(discountToEdit.endDate).isValid()
      ? dayjs(discountToEdit.endDate).toDate()
      : null,
    program: discountToEdit.programId?.toString() ?? '',
    packageId: discountToEdit.packageId?.toString() ?? '',
    discount: discountAmount,
    suppressEmptyCharges: discountToEdit.suppressEmptyCharges,
  };
};

export enum ProgramTypeDisplay {
  'Chronic & Acute',
  'Chronic',
  'Acute',
}

export const ProgramSelectionOptions = (
  Object.keys(ProgramTypeDisplay) as (keyof typeof ProgramTypeDisplay)[]
)
  .filter(v => !isNaN(Number(v)))
  .map((key, index) => ({
    value: key,
    displayName: ProgramTypeDisplay[index],
  }));

export const PackageTypeDisplay = (
  packageId: number,
  availablePackages: PackageType[],
): string => {
  if (packageId === 0) {
    return 'All Packages';
  }
  const selectedPackage = availablePackages.find(({ id }) => id === packageId);
  return capitalizeFirstLetterAndSpaceSnakeString(selectedPackage?.name ?? '');
};

export interface PackageDiscountDisplay {
  value: string;
  displayName: string;
}

export const PackageSelectionOptions = (
  availablePackages: PackageType[],
): PackageDiscountDisplay[] => [
  {
    value: '0',
    displayName: 'All Packages',
  },
  ...availablePackages.map(({ id, name }) => ({
    value: id.toString(),
    displayName: capitalizeFirstLetterAndSpaceSnakeString(name),
  })),
];

export enum ProgramType {
  'global',
  'chronic',
  'acute',
}

export interface IDiscountUsage
  extends Partial<DiscountUsage>,
    GqlNetworkStatus {}

export interface IDiscountDetail {
  discount: number;
  discountDetailId: number;
  milestoneDiscount: object;
  subscriptionTier: (number | null | undefined)[];
  tier: number;
  discountUsage: IDiscountUsage;
}
export interface IDiscount {
  clientId?: number | null;
  discountDefinitionId: number;
  discountType: string;
  endDate?: string | null;
  insurerId?: number | null;
  name?: string | null;
  partnershipId?: number | null;
  programId?: number | null;
  packageId?: number | null;
  startDate: string;
  discountDetails: IDiscountDetail[];
  suppressEmptyCharges: boolean;
  status: ActiveStatus;
}

export interface DiscountOverlapReturn {
  isOverlap: boolean;
  discountDefinitionId: number;
}

const hasSameProgramOrPackage = (
  discount: IDiscount,
  selectedProgramId: number | null,
  selectedPackageId: number | null,
): boolean => {
  if (
    discount.programId === 0 ||
    discount.packageId === 0 ||
    selectedProgramId === 0 ||
    selectedPackageId === 0
  ) {
    return true;
  }
  return (
    (discount.programId != null &&
      selectedProgramId != null &&
      discount.programId === selectedProgramId) ||
    (discount.packageId != null &&
      selectedPackageId != null &&
      discount.packageId === selectedPackageId)
  );
};

export interface DiscountOverlap {
  startDateOverlaps: boolean;
  endDateOverlaps: boolean;
}

export const checkDiscountOverlap = (
  startDate: Date,
  endDate: Date | null,
  selectedDiscountType: DiscountType,
  selectedProgramId: number | null,
  selectedPackageId: number | null,
  currentDiscounts: IDiscount[],
  editId?: number,
): DiscountOverlap => {
  let startDateOverlaps = false;
  let endDateOverlaps = false;

  if (editId) {
    currentDiscounts = currentDiscounts.filter(
      discount => discount.discountDefinitionId !== editId,
    );
  }

  currentDiscounts = currentDiscounts
    .filter(d => d.discountType === selectedDiscountType)
    .filter(d =>
      hasSameProgramOrPackage(d, selectedProgramId, selectedPackageId),
    )
    .sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)));

  const selectedStart = dayjs(startDate);
  const selectedEnd = endDate ? dayjs(endDate) : null;

  for (const discount of currentDiscounts) {
    const discountStart = dayjs(discount.startDate);
    const discountEnd = discount.endDate ? dayjs(discount.endDate) : null;

    if (selectedStart && selectedEnd) {
      if (
        discountStart.isBetween(selectedStart, selectedEnd, 'day', '[]') &&
        discountEnd?.isBetween(selectedStart, selectedEnd, 'day', '[]')
      ) {
        startDateOverlaps = true;
        endDateOverlaps = true;
        break;
      }
      startDateOverlaps =
        (discountEnd &&
          selectedStart.isBetween(discountStart, discountEnd, 'day', '[]')) ||
        (!discountEnd && selectedStart.isSameOrAfter(discountStart, 'day'));
      endDateOverlaps =
        (discountEnd &&
          selectedEnd.isBetween(discountStart, discountEnd, 'day', '[]')) ||
        (!discountEnd && selectedEnd.isSameOrAfter(discountStart, 'day'));
    } else {
      startDateOverlaps =
        (discountEnd &&
          selectedStart.isBetween(discountStart, discountEnd, 'day', '[]')) ||
        (!discountEnd && selectedStart.isSameOrAfter(discountStart, 'day'));
      endDateOverlaps =
        selectedStart.isSameOrBefore(discountStart, 'day') ||
        (!discountEnd && selectedStart.isSameOrAfter(discountStart, 'day'));
    }

    if (startDateOverlaps || endDateOverlaps) {
      break;
    }
  }

  return {
    startDateOverlaps,
    endDateOverlaps,
  };
};
