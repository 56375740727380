import { HHTypography } from '@hinge-health/react-component-library';
import InsertDriveFileRounded from '@mui/icons-material/InsertDriveFileRounded';
import Stack from '@mui/material/Stack';
import { ContractTemplateType, ContractType } from '../../types';
import ContractDetailView from './contract-detail-view';

interface ActiveContractDetailsProps {
  clientInsurerName: string;
  templates: ContractTemplateType[];
  contract?: ContractType;
}

const ActiveContractDetails = ({
  clientInsurerName,
  templates,
  contract,
}: ActiveContractDetailsProps): JSX.Element => {
  if (!contract) {
    return (
      <Stack
        direction="column"
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '200px',
          fontSize: '3.5rem',
          backgroundColor: '#f8f9fa',
          borderRadius: '5px',
        }}
      >
        <InsertDriveFileRounded color="disabled" fontSize="inherit" />
        <HHTypography hhVariant="body">
          {`No active contracts for ${clientInsurerName}`}
        </HHTypography>
      </Stack>
    );
  }

  return <ContractDetailView contract={contract} templates={templates} />;
};

export default ActiveContractDetails;
