import { ChipProps } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  EngagementTypeWithRules,
  mapEngagementTypesToRules,
  RuleType,
} from '../components/contract/form/components/engagement-types-rules';
import {
  ContractFormValues,
  ContractTemplateOption,
  ContractTypeExtension,
  RawFormValues,
} from '../components/contract/form/custom-types';
import {
  BillableActivitiesWithDetails,
  ContractInnerPackageFormValues,
  ContractPackageFormValues,
  DEFAULT_FORM_CURRENCY,
  DEFAULT_PACKAGE_FORM_VALUES,
  RawContractPackageFormValues,
  ValidBillingModel,
} from '../components/contract/package/custom-package-types';
import {
  IN_PERSON_NAME_KEY,
  NO_DATA_TEMPLATE,
} from '../constants/strings/contract/form-constants';
import {
  BillableActivityType,
  BillingModelType,
  ClientInsurerType,
  ContractPackagesType,
  ContractTemplateType,
  ContractType,
  DisabledProgramIndication,
  EngagementType,
  GetContractTemplatesQuery,
  LlmResponse,
  PartnershipType,
  ProgramIndicationType,
  Recommendation,
  SfdcFields,
} from '../types';
import { formatCurrency, getCurrencySymbol } from './currency-helpers';
import { cleanTypeNameGraphql } from './graphql-utils';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

export enum ActiveStatus {
  Active = 'Active',
  Past = 'Past',
  Future = 'Future',
  Unknown = 'Unknown',
  Void = 'Void',
}

export const calculateChipColor = (
  status?: ActiveStatus,
): ChipProps['color'] => {
  switch (status) {
    case ActiveStatus.Active:
      return 'primary';
    case ActiveStatus.Past:
      return 'default';
    case ActiveStatus.Future:
      return 'secondary';
    /* istanbul ignore next */
    default:
      return 'error';
  }
};

export const computeActiveStatus = (
  isVoid: boolean,
  startDate: string | Dayjs | null,
  endDate?: string | Dayjs | null,
): ActiveStatus => {
  if (isVoid) return ActiveStatus.Void;
  const today = dayjs();
  const start = dayjs(startDate);

  if (!endDate) {
    return start.isAfter(today, 'date')
      ? ActiveStatus.Future
      : ActiveStatus.Active;
  }

  const end = dayjs(endDate);

  if (today.isBetween(start, end, 'date', '[)')) {
    return ActiveStatus.Active;
  }

  if (today.isSameOrAfter(end, 'date')) {
    return ActiveStatus.Past;
  }

  if (today.isBefore(start, 'date')) {
    return ActiveStatus.Future;
  }
  return ActiveStatus.Unknown;
};

export const findActiveContract = (
  contracts: ContractType[],
): ContractType | undefined =>
  // undefined is intentional as that's what .find returns when no contract... could be null?
  contracts.find(
    contract =>
      computeActiveStatus(
        contract.void,
        contract.startDate,
        contract.endDate,
      ) === ActiveStatus.Active,
  );

export const dateSorterFunction = (
  aStartDate: string,
  bStartDate: string,
): number => {
  const aStart = dayjs(aStartDate);
  const bStart = dayjs(bStartDate);
  if (aStart.isBefore(bStart)) {
    return 1;
  }
  if (aStart.isAfter(bStart)) {
    return -1;
  }
  return 0;
};

export const sortContracts = (
  contracts: ContractType[],
  desc = false,
): ContractType[] => {
  if (desc) {
    return [...contracts].sort((a, b) =>
      dateSorterFunction(b.startDate, a.startDate),
    );
  }
  return [...contracts].sort((a, b) =>
    dateSorterFunction(a.startDate, b.startDate),
  );
};

export const getMilestoneName = (
  milestone: ContractTypeExtension,
): string | null => {
  if ('name' in milestone) {
    const name = milestone.name?.toUpperCase() ?? '';
    if (name === 'C') {
      return 'Standard';
    }
    if (name.startsWith('C')) {
      return `Milestone ${name.substring(1)}`;
    }
  }
  return null;
};

export interface DateOverlapCheckReturn {
  startDateOverlap: boolean;
  endDateOverlap: boolean;
}

export const isDateRangeOverlappingContracts = (
  startDate: Date,
  endDate: Date | null,
  currentContracts: ContractType[],
  contractEditId?: number,
): DateOverlapCheckReturn => {
  let startDateOverlap = false;
  let endDateOverlap = false;

  if (contractEditId) {
    currentContracts = currentContracts.filter(
      contract => contract.id !== contractEditId,
    );
  }
  currentContracts = currentContracts
    .filter(contract => contract.void !== true)
    .sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate)));

  const selectedStart = dayjs(startDate);
  const selectedEnd = endDate ? dayjs(endDate) : null;

  for (const contract of currentContracts) {
    const contractStart = dayjs(contract.startDate);
    const contractEnd = contract.endDate ? dayjs(contract.endDate) : null;

    if (selectedStart && selectedEnd) {
      if (
        contractStart.isBetween(selectedStart, selectedEnd, 'day', '[]') &&
        contractEnd?.isBetween(selectedStart, selectedEnd, 'day', '[]')
      ) {
        startDateOverlap = true;
        endDateOverlap = true;
        break;
      }
      startDateOverlap =
        (contractEnd &&
          selectedStart.isBetween(contractStart, contractEnd, 'day', '[]')) ||
        (!contractEnd && selectedStart.isSameOrAfter(contractStart, 'day'));
      endDateOverlap =
        (contractEnd &&
          selectedEnd.isBetween(contractStart, contractEnd, 'day', '[]')) ||
        (!contractEnd && selectedEnd.isSameOrAfter(contractStart, 'day'));
    } else {
      startDateOverlap =
        (contractEnd &&
          selectedStart.isBetween(contractStart, contractEnd, 'day', '[]')) ||
        (!contractEnd && selectedStart.isSameOrAfter(contractStart, 'day'));
      endDateOverlap =
        selectedStart.isSameOrBefore(contractStart, 'day') ||
        (!contractEnd && selectedStart.isSameOrAfter(contractStart, 'day'));
    }

    if (startDateOverlap || endDateOverlap) {
      break;
    }
  }

  return {
    startDateOverlap,
    endDateOverlap,
  };
};

const getNearestContract = (
  date: string,
  contracts: ContractType[],
  prevOrNext: 'prev' | 'next' = 'prev',
): ContractType | null => {
  const selectedDate = dayjs(date);

  return contracts
    .filter(contract => contract.void === false)
    .reduce((next: ContractType | null, contract): ContractType | null => {
      const start = dayjs(contract.startDate);
      if (prevOrNext === 'prev' && start.isBefore(selectedDate)) {
        if (
          !next ||
          selectedDate.diff(dayjs(next?.startDate)) >
            selectedDate.diff(dayjs(contract.startDate))
        ) {
          return contract;
        }
      } else if (prevOrNext === 'next' && start.isAfter(selectedDate)) {
        if (
          !next ||
          dayjs(next.startDate).diff(selectedDate) >
            dayjs(contract.startDate).diff(selectedDate)
        ) {
          return contract;
        }
      }
      return next;
    }, null);
};

export interface GapReturnObject {
  isGap: boolean;
  gapLength: number;
}

export const isDateGap = (
  date: string,
  contractEditId: number | null,
  contracts: ContractType[],
  startOrEnd: 'start' | 'end' = 'start',
): GapReturnObject => {
  let isGap = false;
  let gapLength = 0;

  if (contractEditId)
    contracts = contracts.filter(c => c.id !== contractEditId);

  const nearestContract = getNearestContract(
    date,
    contracts,
    startOrEnd === 'start' ? 'prev' : 'next',
  );

  if (startOrEnd === 'start' && nearestContract && nearestContract.endDate) {
    if (dayjs(date).isAfter(dayjs(nearestContract.endDate).add(1, 'day'))) {
      isGap = true;
      gapLength = dayjs(date).diff(
        dayjs(nearestContract.endDate).add(1, 'day'),
        'day',
      );
    }
  } else if (
    startOrEnd === 'end' &&
    nearestContract &&
    nearestContract.startDate
  ) {
    if (
      dayjs(date).isBefore(dayjs(nearestContract.startDate).subtract(1, 'day'))
    ) {
      isGap = true;
      gapLength = dayjs(nearestContract.startDate).diff(
        dayjs(date).add(1, 'day'),
        'day',
      );
    }
  }
  return {
    isGap,
    gapLength,
  };
};

export const checkContractsForGaps = (contracts: ContractType[]): boolean =>
  sortContracts(
    contracts.filter(c => c.void !== true),
    true,
  ).some((contract, index, sortedContracts) => {
    const nextContract = sortedContracts[index + 1];
    if (nextContract) {
      if (
        !dayjs(contract.endDate)
          .add(1, 'day')
          .isSame(dayjs(nextContract.startDate))
      ) {
        return true;
      }
    }
  });

export const getLastContract = (
  historicContracts: ContractType[],
): ContractType | null => {
  if (historicContracts.length > 0) {
    const sortedContracts = sortContracts(historicContracts);
    return sortedContracts[0];
  } else {
    return null;
  }
};

/**
 * Calculate default calender month for end date
 */
export const computeEndDateDefault = (
  startDate: Dayjs | null,
  currentContracts: ContractType[],
): Dayjs => {
  if (startDate) {
    return dayjs(startDate);
  } else if (getLastContract(currentContracts)?.endDate) {
    return dayjs(getLastContract(currentContracts)?.endDate).add(1, 'day');
  } else {
    return dayjs();
  }
};
/**
 * Calculate default calender month for start date
 */
export const computeStartDateDefault = (
  currentContracts: ContractType[],
): Dayjs => {
  const lastContractEndDate = dayjs(getLastContract(currentContracts)?.endDate);
  if (currentContracts.length > 0 && lastContractEndDate.isValid()) {
    return lastContractEndDate.add(1, 'day');
  } else {
    return dayjs().add(1, 'day');
  }
};
/**
 * Derived state for checking start date is before end
 */
export const startDateBeforeEndCheck = (
  startDate: Dayjs | null,
  endDate: Dayjs | null,
): boolean => {
  if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
    return dayjs(startDate).isBefore(dayjs(endDate));
  } else {
    return true;
  }
};

/**
 * Gets program name from identifier string
 * @param programIndicationIdentifier
 * @returns programName
 */
export const getProgramName = (programIndicationIdentifier: string): string => {
  const [programName] = programIndicationIdentifier.split('_');
  return programName;
};

/**
 *
 * @param option
 * @param disabledProgramIndication
 * @returns list of programIndication that aren't disabled
 */
export const isIndicationDisabledCheck = (
  option: DisabledProgramIndication,
  disabledProgramIndication: DisabledProgramIndication[],
): boolean =>
  disabledProgramIndication.some(
    disabled =>
      option.programIndicationIdentifier ===
      disabled.programIndicationIdentifier,
  );

/**
 *Takes in program id,
 *and list of program indications and filters by program id
 * @param programId
 * @param programIndications
 * @returns filtered list of disabled indications with the matching program id
 */
export const filterProgramIndicationsById = (
  programId: number,
  programIndications: ProgramIndicationType[],
): DisabledProgramIndication[] =>
  programIndications
    .filter(pi => pi.programId == programId)
    .map(p => ({ id: p.id, programIndicationIdentifier: p.identifier }))
    .sort((a, b) =>
      a.programIndicationIdentifier.localeCompare(
        b.programIndicationIdentifier,
      ),
    );

/**
 *Takes in program name,
 *and list of program indications and filters by program name
 * @param programName
 * @param programIndications
 * @returns filtered list of disabled indications with the matching program name
 */
export const filterProgramIndicationsByName = (
  programName: string,
  programIndications: DisabledProgramIndication[],
): DisabledProgramIndication[] =>
  programIndications.filter(
    pi => getProgramName(pi.programIndicationIdentifier) == programName,
  );

export interface CurrentInPersonVisitData {
  inPersonVisitContractPackageId: number | null;
  enableInPersonVisit: boolean;
  inPersonVisitCharge: number;
  inPersonVisitEffectiveDate: Date | null;
}
/**
 * Pull out in person visit package details
 */
export const currentInPersonVisitData = (
  currentContractPackages: ContractPackagesType[] | undefined,
): CurrentInPersonVisitData => {
  const currentInPersonVisitPackage = extractProductPackages(
    currentContractPackages ?? [],
  ).filter(cp => cp.key === IN_PERSON_NAME_KEY)[0]?.contractPackage;
  if (currentInPersonVisitPackage) {
    return {
      inPersonVisitContractPackageId: currentInPersonVisitPackage.id,
      enableInPersonVisit: true,
      inPersonVisitCharge: currentInPersonVisitPackage.price,
      inPersonVisitEffectiveDate: dayjs(
        currentInPersonVisitPackage.startDate,
      ).toDate(),
    };
  }
  return {
    inPersonVisitContractPackageId: null,
    enableInPersonVisit: false,
    inPersonVisitCharge: 150.0,
    inPersonVisitEffectiveDate: null,
  };
};

export const extractMilestones = (
  data: ContractTypeExtension[],
): { key: string; payment: number; name: string; value: string }[] =>
  data.reduce<{ key: string; payment: number; name: string; value: string }[]>(
    (coll, milestone) => {
      const name = getMilestoneName(milestone);
      if (name && 'payment' in milestone) {
        coll.push({
          key: milestone.name ?? '',
          name,
          value: formatCurrency(milestone?.payment ?? 0),
          payment: milestone?.payment ?? 0,
        });
      }
      return coll;
    },
    [],
  );

export const extractProductPackages = (
  data: ContractPackagesType[],
): { key: string; contractPackage: ContractPackagesType }[] =>
  data.reduce<{ key: string; contractPackage: ContractPackagesType }[]>(
    (coll, contractPackage) => {
      const name = contractPackage.package?.name || null;
      if (name) {
        coll.push({
          key: name,
          contractPackage,
        });
      }
      return coll;
    },
    [],
  );

export interface GenerateContractSubscriptionsVariablesInput {
  clientsInsurerId: number;
  contractId?: number | null;
  startDate?: string;
  endDate?: string | null;
  contractPackageIds?: number[] | [];
}

export const generateContractSubscriptionsVariables = (
  inputObject: GenerateContractSubscriptionsVariablesInput,
): Record<string, number | null | string | number[] | []> => {
  let variables = { ...inputObject };
  // if it's an edit we only check client insurer and contract id
  if (inputObject.contractId !== null) {
    const { clientsInsurerId, contractId, contractPackageIds } = variables;
    variables = { clientsInsurerId, contractId, contractPackageIds };
  }
  if (inputObject.contractId === null) {
    const { contractId: _, contractPackageIds: __, ...rest } = variables;
    variables = { ...rest };
    if (inputObject.endDate === null) {
      const today = dayjs().format('YYYY-MM-DD');
      variables = { ...variables, endDate: today };
    }
  }
  return variables;
};

enum contractType {
  'Milestone',
  'Standard',
  'Other',
}

export const determineContractType = (
  templateName: string,
): string | undefined =>
  Object.keys(contractType).find(key =>
    key.length > 1 ? (RegExp(`${key}`).exec(templateName)?.pop() ?? '') : '',
  );

/**
 * Derived check for match of current template against list of templates
 */
export const templateValid = (
  templates: ContractTemplateOption[],
  template: ContractTemplateOption,
): boolean =>
  templates
    .map(temp => temp.contractTemplateId)
    .includes(template.contractTemplateId);

export const templateEditable = (template: { name: string }): boolean =>
  template.name.toLowerCase().includes('other');

export const parseFormValues = (data: RawFormValues): ContractFormValues => ({
  acutePrice: data.enableAcuteProgram ? data.acuteCoreCharge : 0,
  billableActivityTypes: data.selectedBillableActivities,
  chronicPrice: data.chronicCoreCharge,
  contract: data.contract,
  contractTemplateId: data.currentTemplate.contractTemplateId,
  endDate: dayjs(data.endDate).isValid()
    ? dayjs(data.endDate).format('YYYY-MM-DD')
    : null,
  startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
  currency: data.currency.value,
  void: data.isVoid,
  partnershipId: data.partnershipId,
  contractPackages: data.contractPackages.map(c => {
    const cp = {
      billingModelId: c.billingModelId ?? null,
      packageId: c.packageId ?? null,
      price: c.price,
      startDate: dayjs(c.startDate).format('YYYY-MM-DD'),
      billableActivityTypes: c.billableActivities,
      billingEnabled: true,
      void: false,
    };
    return c.id ? { ...cp, id: c.id } : cp;
  }),
});

const mapEngagementTypeRuleToBillableActivitiesWithDetails = (
  engagementTypesWithRules: EngagementTypeWithRules[],
): BillableActivitiesWithDetails =>
  engagementTypesWithRules.reduce((acc: BillableActivitiesWithDetails, ent) => {
    if (ent.defaultSelected) {
      const defaultSubType = ent.subTypeOptions?.[
        RuleType[ent.name as keyof typeof RuleType]
      ]?.find(subType => subType.defaultSelected);

      acc[ent.id] = defaultSubType
        ? {
            [RuleType[ent.name as keyof typeof RuleType]]: defaultSubType.value,
          }
        : null;
    }
    return acc;
  }, {});

const mapContractBillableActivitiesToBillableActivitiesWithDetails = (
  billableActivities: BillableActivityType[],
): BillableActivitiesWithDetails =>
  billableActivities.reduce((acc: BillableActivitiesWithDetails, activity) => {
    acc[activity.engagementTypeId] = activity.details ?? null;
    return acc;
  }, {});

export const getBillableActivitiesFromContractPackageOrDefault = (
  billingModelName: string,
  engagementTypes: EngagementTypeWithRules[],
  index: number,
  existingContract?: ContractType,
): BillableActivitiesWithDetails => {
  if (
    existingContract?.packageEnabled &&
    existingContract?.contractPackages?.[index]
  ) {
    const contractPackage = existingContract.contractPackages[index];
    if (billingModelName === contractPackage.billingModel.name) {
      return mapContractBillableActivitiesToBillableActivitiesWithDetails(
        contractPackage.billableActivityTypes,
      );
    }
  } else if (existingContract) {
    return mapContractBillableActivitiesToBillableActivitiesWithDetails(
      existingContract.billableActivityTypes,
    );
  }
  return mapEngagementTypeRuleToBillableActivitiesWithDetails(engagementTypes);
};

export const parsePackageFormValues = (
  data: RawContractPackageFormValues,
  billingModels: BillingModelType[],
): ContractPackageFormValues => ({
  startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
  endDate: dayjs(data.endDate).isValid()
    ? dayjs(data.endDate).format('YYYY-MM-DD')
    : null,
  userAnnualCap: data.userAnnualCap,
  currency: data.currency?.value ?? 'USD',
  void: data.isVoid ?? false,
  partnershipId: data.partnershipId,
  contractPackages: data.selectedPackages?.map(
    (selectedPackage): ContractInnerPackageFormValues => {
      const basePackage: ContractInnerPackageFormValues = {
        billingModelId: selectedPackage.billingModelId ?? 0,
        packageId: selectedPackage.packageId ?? 0,
        price: selectedPackage.price,
        billableActivityTypes: selectedPackage.selectedBillableActivities,
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
      };
      if (
        billingModels.find(model => model.name === ValidBillingModel.ENGAGEMENT)
          ?.id === basePackage.billingModelId
      ) {
        return {
          ...basePackage,
          rules: {
            activationFee: selectedPackage.initialFee,
            activityFee: selectedPackage.activityFee,
          },
        };
      }

      return basePackage;
    },
  ),
});

export const getClientsInsurerPartnership = (
  data: ClientInsurerType | null,
): PartnershipType | undefined =>
  data?.partnerships?.filter(p => p.partnershipType === 'billing').pop();

export const filterTemplatesByPartnerships = (
  partnershipIds: number[] | undefined,
  allTemplates: ContractTemplateType[],
): ContractTemplateOption[] =>
  allTemplates
    .filter(
      template =>
        !template.partnershipId ||
        (partnershipIds && partnershipIds.includes(template.partnershipId)),
    )
    .map(temp => ({
      name: temp.name,
      contractTemplateId: temp.id,
      contract: temp.contract,
    }))
    .toSorted((a, b) => a.name.localeCompare(b.name)) ?? [NO_DATA_TEMPLATE];

export const getContractToEditTemplate = (
  injectedValues: ContractType,
  templates: GetContractTemplatesQuery['getContractTemplates'],
): ContractTemplateOption => ({
  name:
    templates?.find(
      template => template.id === injectedValues?.contractTemplateId,
    )?.name ?? 'No matching template id',
  contractTemplateId: injectedValues.contractTemplateId ?? -1,
  contract: injectedValues?.contract ?? [{}],
});

export const mapInjectedBillableActivites = (
  injected: string[] | [],
  engagementTypes: EngagementType[],
): number[] | [] =>
  injected
    .map(inj =>
      engagementTypes.filter(activity => activity?.name === inj).map(x => x.id),
    )
    .flat();

export enum RecommendationSource {
  IRONCLAD = 'IRONCLAD',
  SFDC = 'SFDC',
  LLM = 'LLM',
}

export enum RecommendationMessage {
  NOT_FOUND = 'IronClad: contract not found',
  NOT_COMPLETED = 'IronClad: contract not completed',
  NOT_CLOSED_WON = 'SFDC: opportunity not closed won',
  NOT_SUPPORTED = 'SFDC: Contract type not supported',
  RECOMMENDATION_SUPPORTED = 'Recommendation supported',
  SERVER_ERROR = 'Failed to create Recommendation: Request failed with status code 500',
}

export enum RecommendationStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  IN_REVIEW = 'in_review',
}

// if source LLM, contract = llm response
// if source SFDC, contract = SFDC fields
export const pickRecommendedData = (
  recommendationData: Recommendation | null,
): Maybe<SfdcFields> | Maybe<LlmResponse> | undefined => {
  switch (recommendationData?.source) {
    case RecommendationSource.SFDC:
      return recommendationData?.sfdcFields;
    case RecommendationSource.LLM:
      return recommendationData?.llmResponse;
    default:
      return;
  }
};

export const getResetPackageFormValues = (
  billingModels: BillingModelType[],
  preSelectedBillingModel?: string,
  contractToEdit?: ContractType,
  partnershipId?: number,
): RawContractPackageFormValues => ({
  startDate: contractToEdit?.startDate
    ? dayjs(contractToEdit?.startDate).toDate()
    : null,
  endDate: contractToEdit?.endDate
    ? dayjs(contractToEdit?.endDate).toDate()
    : null,
  userAnnualCap: contractToEdit?.userAnnualCap ?? 1750.0,
  currency: contractToEdit?.currency
    ? {
        label: `${contractToEdit.currency} ${getCurrencySymbol(
          contractToEdit.currency,
        )}`,
        value: contractToEdit.currency,
      }
    : DEFAULT_FORM_CURRENCY,
  isVoid: contractToEdit?.void ?? false,
  partnershipId: contractToEdit
    ? (contractToEdit.partnershipId ?? null)
    : (partnershipId ?? null),
  selectedPackages: contractToEdit?.contractPackages?.some(Boolean)
    ? contractToEdit.contractPackages.map(cp => ({
        billingModelId: cp.billingModelId,
        packageId: cp.package.id,
        price: cp.price,
        initialFee: cp.rules?.activationFee ?? 250,
        activityFee: cp.rules?.activityFee ?? 50,
        selectedBillableActivities: {},
      }))
    : [
        {
          ...DEFAULT_PACKAGE_FORM_VALUES,
          billingModelId:
            billingModels.find(bm => bm.name === preSelectedBillingModel)?.id ??
            0,
        },
      ],
});

const getInitialTemplateOption = (
  templates: ContractTemplateType[],
  contractTemplateId: number,
): ContractTemplateOption => {
  if (contractTemplateId === 0) {
    return NO_DATA_TEMPLATE;
  }
  const template = templates.find(
    template => template.id === contractTemplateId,
  );
  if (!template) {
    return NO_DATA_TEMPLATE;
  }
  return {
    name: template.name,
    contractTemplateId: template.id,
    contract: cleanTypeNameGraphql(template.contract),
  } as ContractTemplateOption;
};

export const getResetFormValues = (
  templates: ContractTemplateType[],
  engagementTypes: EngagementType[],
  contractToEdit?: ContractType,
  partnershipId?: number,
): RawFormValues => ({
  currentTemplate: getInitialTemplateOption(
    templates,
    contractToEdit?.contractTemplateId ?? 0,
  ),
  startDate: contractToEdit?.startDate
    ? dayjs(contractToEdit.startDate).toDate()
    : null,
  endDate: contractToEdit?.endDate
    ? dayjs(contractToEdit.endDate).toDate()
    : null,
  currency: contractToEdit?.currency
    ? {
        label: `${contractToEdit.currency} ${getCurrencySymbol(
          contractToEdit.currency,
        )}`,
        value: contractToEdit.currency,
      }
    : DEFAULT_FORM_CURRENCY,
  chronicCoreCharge: contractToEdit?.chronicPrice ?? 995.0,
  isVoid: contractToEdit?.void ?? false,
  selectedBillableActivities:
    contractToEdit?.billableActivityTypes?.reduce(
      (acc: BillableActivitiesWithDetails, activity) => {
        acc[activity.engagementTypeId] = activity?.details
          ? activity.details
          : null;
        return acc;
      },
      {},
    ) ??
    mapEngagementTypeRuleToBillableActivitiesWithDetails(
      mapEngagementTypesToRules('legacy', engagementTypes),
    ),
  contract: getInitialTemplateOption(
    templates,
    contractToEdit?.contractTemplateId ?? 0,
  ).contract,
  enableAcuteProgram: !!contractToEdit?.acutePrice,
  acuteCoreCharge: contractToEdit?.acutePrice ?? 250,
  enableInPersonVisit: contractToEdit?.contractPackages.some(Boolean) ?? false,
  partnershipId: contractToEdit
    ? (contractToEdit.partnershipId ?? null)
    : (partnershipId ?? null),
  contractPackages: contractToEdit?.contractPackages?.some(Boolean)
    ? contractToEdit.contractPackages.map(p => ({
        id: p.id,
        packageId: p.packageId,
        billingModelId: p.billingModelId,
        price: p.price,
        startDate: p?.startDate ? dayjs(p.startDate).toDate() : null,
        billableActivities: {},
      }))
    : [],
});
