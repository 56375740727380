import {
  HHStack,
  HHToggleButton,
  HHToggleButtonGroup,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import CheckIcon from '@mui/icons-material/Check';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';
import { GetSplitTreatmentOnOrOff } from '../../../../../../components/splits';
import { migrateBillingModelsEnabled } from '../../../../constants/strings/split';
import { BillingModelType } from '../../../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';
import { ValidBillingModel } from '../custom-package-types';
import { ContractPackageFormLabelText, fieldArrayName } from '../form';

interface BillingModelSelectProps {
  billingModels: BillingModelType[];
  index: number;
  wholeFormDisabled?: boolean;
}

const BillingModelSelect: React.FC<BillingModelSelectProps> = ({
  billingModels,
  index,
  wholeFormDisabled,
}): JSX.Element => {
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();

  const migrateBillingModelsSplitEnabled = GetSplitTreatmentOnOrOff(
    migrateBillingModelsEnabled,
    adminId,
  );

  const filteredBillingModels = migrateBillingModelsSplitEnabled
    ? billingModels.filter(model =>
        Object.values(ValidBillingModel).includes(
          model.name as ValidBillingModel,
        ),
      )
    : billingModels.filter(
        model => model.name === ValidBillingModel.ENGAGEMENT,
      );

  return (
    <HHStack direction="column" spacing={2}>
      <HHTypography hhVariant="caption" color="gray">
        {ContractPackageFormLabelText.BILLING_MODEL}
      </HHTypography>
      <Controller
        name={`${fieldArrayName}.${index}.billingModelId`}
        render={({ field: { onChange, value } }): JSX.Element => (
          <HHToggleButtonGroup size="medium">
            {filteredBillingModels.map(model => (
              <HHToggleButton
                hhVariant="variant-bypass"
                key={model.id}
                value={model.id}
                onClick={(): void => {
                  onChange(model.id);
                }}
                selected={value === model.id}
                sx={(theme): SystemStyleObject<Theme> => ({
                  backgroundColor: 'white',
                  width: '100%',
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.action.focus,
                  },
                })}
                disabled={wholeFormDisabled}
              >
                <HHStack direction="row" alignItems="center">
                  {capitalizeFirstLetterAndSpaceSnakeString(model.name)}
                  {value === model.id && <CheckIcon color="primary" />}
                </HHStack>
              </HHToggleButton>
            ))}
          </HHToggleButtonGroup>
        )}
      />
    </HHStack>
  );
};

export default BillingModelSelect;
