import {
  HHAlert,
  HHPaper,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';
import { isEngagementModelById } from '../../../../utils/contract-utils';
import { formatCurrency } from '../../../../utils/currency-helpers';
import { computeEffectiveRange } from '../../../../utils/date-helper';
import { TextGrouping } from '../../../utils/text-grouping';
import { PackageRules } from '../custom-package-types';
import { PackageFormConfirmationProps } from './package-form-confirmation';

export interface NewContractPackageDetailsProps
  extends Pick<
    PackageFormConfirmationProps,
    'formValues' | 'availablePackages' | 'billingModels'
  > {
  notificationString: string;
  hasNewContractPackages: boolean;
}

const NewContractPackageDetails = ({
  notificationString,
  formValues,
  availablePackages,
  billingModels,
  hasNewContractPackages,
}: NewContractPackageDetailsProps): JSX.Element => {
  const contractPackages = hasNewContractPackages
    ? formValues.contractPackages.filter(cp => !cp.id)
    : formValues.contractPackages;

  return (
    <HHStack gap={2}>
      {notificationString && (
        <HHAlert hhVariant="outlined" severity="info">
          <i>{notificationString}</i>
        </HHAlert>
      )}
      <HHStack display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={4}>
        <TextGrouping
          label="Start date"
          text={computeEffectiveRange(formValues.startDate, formValues.endDate)}
        />
        <TextGrouping
          label="Annual Cap"
          text={formatCurrency(formValues.userAnnualCap, formValues.currency)}
        />
        <TextGrouping label="Currency" text={formValues.currency} />
      </HHStack>
      {contractPackages.map(contractPackage => (
        <HHPaper
          key={contractPackage.packageId}
          sx={{ backgroundColor: 'background.default', padding: 2 }}
        >
          <HHTypography
            hhVariant="subtitle1"
            layoutStyles={{
              textTransform: 'capitalize',
            }}
          >
            {`${capitalizeFirstLetterAndSpaceSnakeString(
              availablePackages.find(
                ({ id }) => id === contractPackage.packageId,
              )?.name,
            )} Package`}
          </HHTypography>
          <HHStack display="grid" gridTemplateColumns="repeat(2,1fr)" gap={4}>
            <TextGrouping
              label="Billing Model"
              text={
                capitalizeFirstLetterAndSpaceSnakeString(
                  billingModels.find(
                    model => model.id === contractPackage.billingModelId,
                  )?.name,
                ) ?? '--'
              }
            />
            <TextGrouping
              label="Price"
              text={formatCurrency(contractPackage.price, formValues.currency)}
            />
            {isEngagementModelById(
              billingModels,
              contractPackage.billingModelId,
            ) && (
              <>
                <TextGrouping
                  label="Activation Fee"
                  text={formatCurrency(
                    (contractPackage.rules as PackageRules)?.activationFee ?? 0,
                    formValues.currency,
                  )}
                />
                <TextGrouping
                  label="Activity Fee"
                  text={formatCurrency(
                    (contractPackage.rules as PackageRules)?.activityFee ?? 0,
                    formValues.currency,
                  )}
                />
              </>
            )}
          </HHStack>
        </HHPaper>
      ))}
    </HHStack>
  );
};

export default NewContractPackageDetails;
