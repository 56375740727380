import { HHFormGroup } from '@hinge-health/react-component-library';
import {
  CONTRACT_FORM_BILLABLE_ACTIVITIES_GROUP_ID,
  IN_PERSON_NAME_KEY,
} from '../../../../constants/strings/contract/form-constants';
import { BillableActivitiesProps } from './billable-activities';
import BillableActivityCheckBox from './billable-activity-checkbox';
import { EngagementTypeWithRules } from './engagement-types-rules';

export interface LegacyBillableActivitiesProps extends BillableActivitiesProps {
  engagementTypes: EngagementTypeWithRules[];
}
/**
 * After go live of new billable activities, this component will be removed
 */
const LegacyBillableActivities = ({
  wholeFormDisabled,
  engagementTypes,
  direction,
  isPackageForm = false,
  index = 0,
}: LegacyBillableActivitiesProps): JSX.Element => (
  <HHFormGroup
    data-testid={CONTRACT_FORM_BILLABLE_ACTIVITIES_GROUP_ID}
    sx={{
      alignItems: 'flex-start',
      flexDirection: direction ?? 'column',
    }}
  >
    {engagementTypes
      ?.filter(ent => ![IN_PERSON_NAME_KEY].includes(ent.name))
      .map(ent => (
        <BillableActivityCheckBox
          key={ent.id}
          engagementType={ent}
          wholeFormDisabled={wholeFormDisabled}
          index={index}
          isPackageForm={isPackageForm}
        />
      ))}
  </HHFormGroup>
);

export default LegacyBillableActivities;
