import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { IN_PERSON_NAME_KEY } from '../../constants/strings/contract/form-constants';
import { ContractPackagesType } from '../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../utils/bills-utils';
import { formatCurrency } from '../../utils/currency-helpers';

export interface ContractGridDetails {
  contractPackages: { key: string; contractPackage: ContractPackagesType }[];
  templateName?: string | null;
  chronicPrice?: number | null;
  acutePrice?: number | null;
  inPersonVisitPrice?: number | null;
  currency: string;
  packageEnabled: boolean;
}

export interface LegacyContractGridDetails {
  templateName?: string | null;
  chronicPrice?: number | null;
  acutePrice?: number | null;
  inPersonVisitPrice?: number | null;
  currency: string;
}

export interface ProductPackageContractGridDetails {
  contractPackages: { key: string; contractPackage: ContractPackagesType }[];
  currency: string;
}

const DisplayLegacyContractDetails = ({
  inPersonVisitPrice,
  chronicPrice,
  acutePrice,
  currency,
}: LegacyContractGridDetails): JSX.Element => (
  <HHStack direction="column">
    {chronicPrice ? (
      <HHTypography hhVariant="caption">
        Chronic: {formatCurrency(chronicPrice, currency)}
      </HHTypography>
    ) : null}
    {acutePrice ? (
      <HHTypography hhVariant="caption">
        Acute: {formatCurrency(acutePrice, currency)}
      </HHTypography>
    ) : null}
    {inPersonVisitPrice ? (
      <HHTypography hhVariant="caption">
        In Person Visit: {formatCurrency(inPersonVisitPrice, currency)}
      </HHTypography>
    ) : null}
  </HHStack>
);

const DisplayProductPackageContractDetails = ({
  contractPackages,
  currency,
}: ProductPackageContractGridDetails): JSX.Element => (
  <HHStack direction="column" textTransform="capitalize">
    {contractPackages.map(({ key, contractPackage }) => (
      <HHTypography key={key} hhVariant="caption">
        {key === IN_PERSON_NAME_KEY
          ? capitalizeFirstLetterAndSpaceSnakeString(key)
          : key}
        : {formatCurrency(contractPackage.price, currency)}
      </HHTypography>
    ))}
  </HHStack>
);

const DisplayContractDetails = ({
  templateName,
  inPersonVisitPrice,
  chronicPrice,
  acutePrice,
  currency,
  contractPackages,
  packageEnabled = false,
}: ContractGridDetails): JSX.Element => {
  if (!packageEnabled) {
    return (
      <DisplayLegacyContractDetails
        templateName={templateName}
        inPersonVisitPrice={inPersonVisitPrice}
        chronicPrice={chronicPrice}
        acutePrice={acutePrice}
        currency={currency}
      />
    );
  } else {
    return (
      <DisplayProductPackageContractDetails
        contractPackages={contractPackages}
        currency={currency}
      />
    );
  }
};

export default DisplayContractDetails;
