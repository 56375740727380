import { HHStack, HHTextField } from '@hinge-health/react-component-library';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CONTRACT_FORM_DATE_PICKERS_ID,
  CONTRACT_FORM_END_DATE_PICKER_ID,
  CONTRACT_FORM_START_DATE_PICKER_ID,
} from '../../../../constants/strings/contract/form-constants';
import { ContractType } from '../../../../types';
import {
  ActiveStatus,
  computeEndDateDefault,
  isDateGap,
} from '../../../../utils/contract-utils';

export interface ContractDatePickersProps {
  startDateDisablePast: boolean;
  startlabel?: string;
  endLabel?: string;
  currentContracts: ContractType[] | [];
  contractEditId: number | undefined;
  defaultStartDateCalenderDate: dayjs.Dayjs;
  wholeFormDisabled: boolean;
  submissionCount: number;
  activeStatus: ActiveStatus | undefined;
}

export const ContractDatePickers = ({
  startDateDisablePast,
  defaultStartDateCalenderDate,
  currentContracts,
  contractEditId,
  // wholeFormDisabled,
  startlabel,
  endLabel,
  submissionCount,
  activeStatus,
}: ContractDatePickersProps): JSX.Element => {
  const {
    control,
    getValues,
    trigger,
    formState: { errors },
    watch,
    setError,
  } = useFormContext();
  const { startDate } = getValues();

  const [watchedStartDate, watchedEndDate] = watch(['startDate', 'endDate']);

  const startDateGapCheck = isDateGap(
    dayjs(watchedStartDate).format(),
    contractEditId ?? null,
    currentContracts,
  );

  const gapMessage = `There is a gap with selected start date and previous contract of ${startDateGapCheck.gapLength} days`;

  const defaultEndDateCalendarDate = computeEndDateDefault(
    startDate,
    currentContracts,
  );

  useEffect(() => {
    trigger('endDate');
  }, [watchedStartDate, trigger]);

  useEffect(() => {
    if (watchedEndDate) {
      trigger('startDate');
    }
  }, [watchedEndDate, trigger]);

  return (
    <HHStack
      direction="row"
      spacing={2}
      data-testid={CONTRACT_FORM_DATE_PICKERS_ID}
    >
      <Controller
        control={control}
        name="startDate"
        render={({ field: { onChange, value } }): JSX.Element => (
          <DatePicker
            label={startlabel ?? 'Contract start date'}
            value={value}
            key={'startDate'}
            disablePast={startDateDisablePast}
            defaultCalendarMonth={defaultStartDateCalenderDate}
            disabled={
              activeStatus === ActiveStatus.Active ||
              activeStatus === ActiveStatus.Past
            }
            onChange={(newValue): void => {
              onChange(dayjs(newValue).toDate()); //parsing to date for validation
            }}
            onError={(): void =>
              setError('startDate', { type: 'manual', message: 'Invalid Date' })
            }
            renderInput={(params): JSX.Element => (
              <HHTextField
                data-testid={CONTRACT_FORM_START_DATE_PICKER_ID}
                {...params}
                error={
                  params.error ||
                  Object.keys(errors?.startDate ?? {}).length !== 0
                }
                name="startDate"
                fullWidth
                hhVariant="variant-bypass"
                helperText={
                  startDateGapCheck.isGap
                    ? gapMessage
                    : errors.startDate?.message
                }
                InputLabelProps={{ sx: { color: 'gray' } }}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name="endDate"
        render={({ field: { onChange, value } }): JSX.Element => (
          <DatePicker
            label={endLabel ?? 'Contract end date'}
            value={value}
            key={'endDate'}
            disablePast={submissionCount >= 1 || startDateDisablePast}
            defaultCalendarMonth={defaultEndDateCalendarDate}
            shouldDisableDate={(selectedDate): boolean =>
              dayjs(selectedDate).isBefore(startDate)
            }
            disabled={activeStatus === ActiveStatus.Past}
            onChange={(newValue): void => {
              onChange(
                newValue ? dayjs(newValue).toDate() : null, //parsing to date for validation
              );
            }}
            onError={(): void =>
              setError('endDate', { type: 'manual', message: 'Invalid Date' })
            }
            renderInput={(params): JSX.Element => (
              <HHTextField
                data-testid={CONTRACT_FORM_END_DATE_PICKER_ID}
                {...params}
                error={
                  params.error ||
                  Object.keys(errors?.endDate ?? {}).length !== 0
                }
                name="endDate"
                fullWidth
                hhVariant="variant-bypass"
                helperText={errors.endDate?.message}
                InputLabelProps={{ sx: { color: 'gray' } }}
              />
            )}
          />
        )}
      />
    </HHStack>
  );
};
