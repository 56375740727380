import {
  HHBreadcrumbs,
  HHLink,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useSearchParams } from 'react-router-dom';
import BillsWithHoldsGrid from '../components/bills/bill-holds-grid/grid';
import { routes } from '../constants/strings/routes';
import { DynamicDrawerProvider } from '../context/dynamic-drawer.context';

export const BILLS_WITH_HOLDS_TEST_ID = 'bills-with-holds-container';

const BillsWithHolds = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const holdReason = searchParams.get('holdReason');

  return (
    <DynamicDrawerProvider>
      <HHStack flex={1} gap={4}>
        <HHBreadcrumbs
          sx={{ paddingLeft: 6, paddingTop: 6 }}
          aria-label="breadcrumb"
        >
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Operations Hub
          </HHLink>
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Billing
          </HHLink>
          <HHTypography color="text.primary" hhVariant="variant-bypass">
            Bill Holds
          </HHTypography>
        </HHBreadcrumbs>
        <HHStack sx={{ paddingLeft: 6 }}>
          <HHTypography hhVariant="section-title">
            Bills with active holds
          </HHTypography>
        </HHStack>
        <BillsWithHoldsGrid preSelectedHoldReason={holdReason} />
      </HHStack>
    </DynamicDrawerProvider>
  );
};
export default BillsWithHolds;
