import {
  HHAlert,
  HHBreadcrumbs,
  HHLink,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { ActiveContractLabels } from '../constants/strings/billing-label-enums';
import { routes } from '../constants/strings/routes';
import {
  ClientInsurerType,
  useGetContractsByClientInsurerQuery,
  useGetContractTemplatesQuery,
} from '../types';
import {
  checkContractsForGaps,
  findActiveContract,
  sortContracts,
} from '../utils/contract-utils';
import { cleanTypeNameGraphql } from '../utils/graphql-utils';
import ClientDiscountsList from './clients/client-discounts';
import ActiveContractDetails from './contract/billing-tool-active-contract-details';
import ContractGrid from './contract/billing-tool-contract-grid';
import { ContractSettingsButton } from './contract/contract-settings/contract-settings-button';
import { ContractLoadingError } from './contract/contract-view-components/contract-loading-error';
import ContractBillingModelLaunch from './main/contract-billing-model-launch';
import LoadingComponent from './utils/loading';

export const MAIN_SECTION_TEST_ID = 'billing-tool-main-section';

export interface BillingMainSectionProps {
  clientInsurerData: ClientInsurerType;
}

const BillingMainSection = ({
  clientInsurerData,
}: BillingMainSectionProps): JSX.Element => {
  const {
    data: contractData,
    loading: contractLoading,
    error: contractError,
  } = useGetContractsByClientInsurerQuery({
    variables: { id: clientInsurerData.id, includeVoided: true },
  });
  const { data, loading, error } = useGetContractTemplatesQuery();

  if (
    (!loading && (error || !data)) ||
    (!contractLoading && (contractError || !contractData))
  ) {
    return <ContractLoadingError />;
  }

  const contracts = sortContracts(contractData?.getContracts ?? []);
  const activeContract = findActiveContract(contracts);
  const contractTemplates = data?.getContractTemplates ?? [];

  const gapExists = checkContractsForGaps(cleanTypeNameGraphql(contracts));

  const partnershipIds =
    contracts
      .filter(c => !c.void)
      .map(c => c.partnershipId)
      .filter((id): id is number => id !== null && id !== undefined) ??
    undefined;

  return (
    <HHStack
      direction="column"
      data-testid={MAIN_SECTION_TEST_ID}
      flex={1}
      padding={6}
      sx={{
        overflowY: 'auto',
      }}
    >
      <HHBreadcrumbs sx={{ paddingBottom: 6 }} aria-label="breadcrumb">
        <HHLink color="inherit" href={routes.billing.home} underline="hover">
          Operations Hub
        </HHLink>
        <HHLink color="inherit" href={routes.billing.home} underline="hover">
          Billing
        </HHLink>
        <HHTypography color="text.primary" hhVariant="variant-bypass">
          Clients Insurer {clientInsurerData.id}
        </HHTypography>
      </HHBreadcrumbs>
      {contractLoading || loading ? (
        <LoadingComponent center />
      ) : (
        <HHStack direction="column" spacing={6}>
          {gapExists && (
            <HHAlert severity="warning" hhVariant="outlined">
              <HHTypography hhVariant="body">
                There is a gap between contracts
              </HHTypography>
            </HHAlert>
          )}
          <HHStack direction="column" spacing={2}>
            <HHStack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={6}
            >
              <HHTypography hhVariant="section-title">
                {ActiveContractLabels.ACTIVE_CONTRACT_LABEL}
              </HHTypography>
              <HHStack direction="row" spacing={2}>
                {activeContract && (
                  <ContractSettingsButton contract={activeContract} />
                )}
                <ContractBillingModelLaunch activeContract={activeContract} />
              </HHStack>
            </HHStack>
            <ActiveContractDetails
              contract={activeContract}
              clientInsurerName={clientInsurerData.client?.name}
              templates={contractTemplates}
            />
          </HHStack>
          <HHStack direction="column" spacing={12}>
            <ContractGrid data={contracts} templates={contractTemplates} />
            <ClientDiscountsList
              clientId={clientInsurerData.client.id}
              partnershipIds={partnershipIds}
              disableAddNew
            />
          </HHStack>
        </HHStack>
      )}
    </HHStack>
  );
};

export default BillingMainSection;
