import { Theme } from '@emotion/react';
import {
  HHAccordion,
  HHAccordionDetails,
  HHAccordionSummary,
  HHCard,
  HHCardContent,
  HHCardHeader,
  HHDialog,
  HHDialogContent,
  HHDialogTitle,
  HHDivider,
  HHIconButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { ExpandMore } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import ReadMore from '@mui/icons-material/ReadMore';
import { SystemStyleObject } from '@mui/system';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useState } from 'react';
import { PackageOfferingsType } from '../../../../../targeted-interventions/types';
import {
  ActiveContractLabels,
  MilestoneLabels,
} from '../../../../constants/strings/billing-label-enums';
import {
  BillingModelType,
  ContractTemplateType,
  EngagementType,
  PackageType,
} from '../../../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';
import { formatCurrency } from '../../../../utils/currency-helpers';
import {
  categorizePackageIndications,
  listifyIndications,
} from '../../../../utils/package-utils';
import { TextGrouping } from '../../../utils/text-grouping';
import {
  ConfigurableActivityDisplay,
  PackageBillableActivityDisplay,
} from '../../contract-view-only/billable-activity-display';
import { ContractTypeExtension } from '../../form/custom-types';
import {
  BillableActivitiesWithDetails,
  RawInnerPackageFormValues,
  ValidBillingModel,
} from '../custom-package-types';

export interface FormPanelDetailsProps {
  selectedPackage: RawInnerPackageFormValues;
  availablePackages: PackageType[];
  engagementTypes: EngagementType[];
  currency: string | Maybe<string>;
  billingModels: BillingModelType[];
  templates: ContractTemplateType[];
  sx?: SystemStyleObject<Theme>;
}
interface SelectedPackageIndicationsProps {
  packageOfferings: PackageOfferingsType[];
}

interface PricesDisplayProps {
  selectedPackage: RawInnerPackageFormValues;
  currency: string | Maybe<string>;
  billingModels: BillingModelType[];
}

interface SelectedBillableActivitiesDisplayProps {
  selectedBillableActivities: BillableActivitiesWithDetails;
  engagementTypes: EngagementType[];
}

export const SelectedPackageIndications = ({
  packageOfferings,
}: SelectedPackageIndicationsProps): JSX.Element => {
  const [indicationsOpen, setIndicationsOpen] = useState(false);

  const indicationCategories = categorizePackageIndications(packageOfferings);

  return (
    <HHStack direction="row" alignItems="center">
      <HHTypography hhVariant="body2">
        {ActiveContractLabels.INDICATIONS_INCLUDED}
      </HHTypography>
      <HHIconButton
        aria-label="read-more"
        hhVariant="variant-bypass"
        onClick={(): void => setIndicationsOpen(true)}
        size="small"
      >
        <ReadMore fontSize="small" />
      </HHIconButton>
      <HHDialog
        open={indicationsOpen}
        onClose={(): void => setIndicationsOpen(false)}
        fullWidth
        keepMounted={false}
      >
        <HHDialogTitle>
          <HHStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <HHTypography hhVariant="muted" fontSize={12}>
              Included Program Indications
            </HHTypography>
            <HHIconButton
              aria-label="close"
              onClick={(): void => setIndicationsOpen(false)}
              hhVariant={'medium'}
            >
              <Close />
            </HHIconButton>
          </HHStack>
        </HHDialogTitle>
        <HHDialogContent>
          <HHStack
            spacing={2}
            padding={1}
            direction="column"
            justifyContent="flex-start"
            sx={(theme): SystemStyleObject<Theme> => ({
              backgroundColor: theme.palette.background.default,
            })}
          >
            {Object.keys(indicationCategories).map((key, i, og) => (
              <HHStack
                direction="column"
                spacing={2}
                flexWrap="wrap"
                padding={1}
                key={key}
                divider={og.length === i ? null : <HHDivider />}
              >
                <HHTypography hhVariant="muted" fontSize={12}>
                  {capitalizeFirstLetterAndSpaceSnakeString(key)}
                </HHTypography>
                <HHTypography hhVariant="body2">
                  {listifyIndications(indicationCategories[key])}
                </HHTypography>
              </HHStack>
            ))}
          </HHStack>
        </HHDialogContent>
      </HHDialog>
    </HHStack>
  );
};

const SelectedBillableActivitiesDisplay = ({
  selectedBillableActivities,
  engagementTypes,
}: SelectedBillableActivitiesDisplayProps): JSX.Element => {
  const billableActivitiesToDisplay = engagementTypes
    .filter(ent => ent.id in selectedBillableActivities)
    .map(ent => ({
      ...ent,
      details: selectedBillableActivities[ent.id],
    }));

  return (
    <HHStack direction="column" paddingTop={4}>
      <PackageBillableActivityDisplay
        packageBillableActivities={billableActivitiesToDisplay}
      />
    </HHStack>
  );
};

const PricesDisplay = ({
  selectedPackage,
  currency,
  billingModels,
}: PricesDisplayProps): JSX.Element => {
  const isEngagementPackage =
    billingModels.find(model => model.name === ValidBillingModel.ENGAGEMENT)
      ?.id === selectedPackage.billingModelId;

  return (
    <HHStack spacing={4} paddingTop={2}>
      <TextGrouping
        label="Price"
        text={formatCurrency(selectedPackage.price, currency)}
      />
      {isEngagementPackage && (
        <HHStack direction="row" spacing={4} flex={1}>
          <TextGrouping
            label={ActiveContractLabels.ENGAGEMENT_INITIAL_FEE}
            text={formatCurrency(selectedPackage.initialFee, currency)}
          />
          <TextGrouping
            label={ActiveContractLabels.ENGAGEMENT_ACTIVITY_FEE}
            text={formatCurrency(selectedPackage.activityFee, currency)}
          />
        </HHStack>
      )}
    </HHStack>
  );
};

export const panelConditionsMap: Record<string, string> = {
  enrollment_check: 'Enrollment',
  engagement_threshold_check: 'Engagement threshold',
  pain_reduction_check: 'Pain reduction',
};

const FormPanelDetails = ({
  selectedPackage,
  availablePackages,
  engagementTypes,
  currency,
  billingModels,
  templates,
  sx,
}: FormPanelDetailsProps): JSX.Element => {
  const selectedBillingModel = billingModels.find(
    model => model.id === selectedPackage.billingModelId,
  );

  const isMilestoneModel = [
    ValidBillingModel.INDIVIDUAL_MILESTONE,
    ValidBillingModel.COHORT_MILESTONE,
  ].includes(selectedBillingModel?.name as ValidBillingModel);

  const billingModelName = selectedBillingModel?.name;

  const template =
    templates.find(
      t => t.id === selectedPackage.currentTemplate?.contractTemplateId,
    ) ?? null;

  return (
    <HHCard
      sx={(theme): SystemStyleObject<Theme> => ({
        ...sx,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(4),
        flex: 1,
        overflowY: 'auto',
      })}
    >
      <HHCardHeader
        title={
          <HHStack textTransform="capitalize">
            {Boolean(selectedPackage.packageId) && (
              <HHTypography hhVariant="subtitle1">
                {`${availablePackages
                  .find(ap => ap.id === selectedPackage.packageId)
                  ?.name.replaceAll('_', ' ')} package`}
              </HHTypography>
            )}
            {Boolean(selectedPackage.billingModelId) && (
              <HHTypography hhVariant="body2">
                {`${billingModelName?.replaceAll('_', ' ')}`}
              </HHTypography>
            )}
          </HHStack>
        }
        sx={{ paddingBottom: 0 }}
      ></HHCardHeader>
      <HHCardContent>
        <SelectedPackageIndications
          packageOfferings={
            availablePackages.find(p => p.id === selectedPackage.packageId)
              ?.packageOfferings || []
          }
        />
        {isMilestoneModel && template ? (
          <>
            {template.contract.length === 0 ? (
              <HHTypography hhVariant="body2">
                No milestones in this template
              </HHTypography>
            ) : (
              (template.contract as ContractTypeExtension[]).map(milestone => (
                <HHStack
                  flexDirection="column"
                  spacing={2}
                  key={milestone.name}
                  flex={1}
                  padding={1}
                >
                  <HHDivider />
                  <HHAccordion
                    key={milestone.name}
                    sx={(theme): SystemStyleObject<Theme> => ({
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: theme.palette.background.default,
                    })}
                  >
                    <HHAccordionSummary expandIcon={<ExpandMore />}>
                      <HHStack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <TextGrouping
                          label={'M' + milestone.name.replace('C', '')}
                          text={formatCurrency(milestone.payment, currency)}
                        />
                        <ConfigurableActivityDisplay
                          milestone={milestone}
                          selectedBillableActivities={
                            selectedPackage.selectedBillableActivities
                          }
                          engagementTypes={engagementTypes}
                        />
                      </HHStack>
                    </HHAccordionSummary>
                    <HHAccordionDetails>
                      <HHStack direction="row">
                        <HHStack direction="column" flex={1}>
                          <HHTypography hhVariant="muted">
                            {MilestoneLabels.DOS_OFFSET}
                          </HHTypography>
                          <HHTypography hhVariant="body2">
                            {milestone.dateOfServiceOffset
                              ? `${milestone.dateOfServiceOffset}`
                              : 'None'}
                          </HHTypography>
                        </HHStack>
                        <HHStack direction="column">
                          <HHTypography hhVariant="muted">
                            {MilestoneLabels.DAY_RANGE}
                          </HHTypography>
                          <HHTypography hhVariant="body2">
                            {milestone.dayRange &&
                            milestone.dayRange.length >= 1
                              ? `${milestone.dayRange[0]} to ${milestone.dayRange[1]} days`
                              : 'None'}
                          </HHTypography>
                        </HHStack>
                      </HHStack>
                      <HHStack>
                        <HHTypography hhVariant="muted">
                          {MilestoneLabels.BILLING_CONDITIONS}
                        </HHTypography>
                        {milestone.conditions?.map(c => (
                          <HHTypography hhVariant="body2" key={c.function}>
                            {panelConditionsMap[c.function]} -{' '}
                            {panelConditionsMap[c.function] ===
                            panelConditionsMap.pain_reduction_check
                              ? c.threshold + '%'
                              : c.threshold + ' billable activity'}
                          </HHTypography>
                        ))}
                      </HHStack>
                    </HHAccordionDetails>
                  </HHAccordion>
                </HHStack>
              ))
            )}
          </>
        ) : (
          <>
            <HHDivider sx={{ with: '100%' }} />
            <PricesDisplay
              selectedPackage={selectedPackage}
              currency={currency}
              billingModels={billingModels}
            />
            <SelectedBillableActivitiesDisplay
              selectedBillableActivities={
                selectedPackage.selectedBillableActivities
              }
              engagementTypes={engagementTypes}
            />
          </>
        )}
      </HHCardContent>
    </HHCard>
  );
};

export default FormPanelDetails;
