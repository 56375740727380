import {
  HHChip,
  HHIconButton,
  HHStack,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import Close from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGrid,
  GridColumns,
  GridComparatorFn,
  GridRenderCellParams,
  GridRowsProp,
  GridValidRowModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { useState } from 'react';
import { GetSplitTreatmentOnOrOff } from '../../../../components/splits';
import { ActiveContractLabels } from '../../constants/strings/billing-label-enums';
import { routes } from '../../constants/strings/routes';
import { contractPartnershipIdFlag } from '../../constants/strings/split';
import {
  ContractTemplateType,
  ContractType,
  useGetPartnershipsQuery,
} from '../../types';
import {
  ActiveStatus,
  calculateChipColor,
  computeActiveStatus,
  dateSorterFunction,
  extractProductPackages,
} from '../../utils/contract-utils';
import { computeEffectiveRange } from '../../utils/date-helper';
import LoadingComponent from '../utils/loading';
import { EditContractButton } from './billing-edit-contract-button';
import DisplayContractDetails from './billing-tool-contract-grid-display';
import ContractDetailView from './contract-detail-view';
import { ContractSettingsButton } from './contract-settings/contract-settings-button';

const useStyles = makeStyles(theme =>
  createStyles({
    gridStyles: {
      display: 'flex',
      marginTop: '1em',
    },
    oddRow: {
      backgroundColor: theme.palette.background.default,
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    modalStyles: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60vw',
      height: '50vh',
      backgroundColor: '#fff',
      border: '2px solid grey',
      padding: '2em',
      overflow: 'hidden',
    },
  }),
);

export const CONTRACT_GRID_TEST_ID = 'contract-grid-test-id';

interface ContractGridProps {
  data: ContractType[];
  templates: ContractTemplateType[];
}
type ContractDateRange = Pick<ContractType, 'startDate' | 'endDate'>;

const effectiveDatesComparator: GridComparatorFn<ContractDateRange> = (
  v1,
  v2,
) => dateSorterFunction(v1.startDate, v2.startDate);

const ContractGrid = ({
  data,
  templates,
}: ContractGridProps): JSX.Element | null => {
  const classes = useStyles();
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [contractIdToView, setContractIdToView] = useState<number | null>(null);
  const { data: partnershipData, error: partnershipError } =
    useGetPartnershipsQuery({
      variables: { type: 'billing' },
    });

  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const uid = hingeHealthAuthState?.accessToken?.claims.uid.toString();
  const partnershipContractEnabled = GetSplitTreatmentOnOrOff(
    contractPartnershipIdFlag,
    uid,
  );

  if (!data || !templates) {
    return <LoadingComponent />;
  }

  const rows: GridRowsProp = data.map(item => ({
    id: item.id,
    template:
      templates.find(({ id }) => id === item.contractTemplateId)?.name ??
      'No matching template id',
    contractType:
      templates.find(({ id }) => id === item.contractTemplateId)?.name ??
      'Product package',
    contractPackages: extractProductPackages(item.contractPackages),
    chronicPrice: item.chronicPrice,
    acutePrice: item.acutePrice,
    inPersonVisitPrice: item.contractPackages[0]?.price,
    effectiveDates: {
      startDate: item.startDate,
      endDate: item.endDate,
    },
    status: computeActiveStatus(item.void, item.startDate, item.endDate),
    packageEnabled: item.packageEnabled,
    partnership: partnershipError
      ? 'Unable to get partnership'
      : (partnershipData?.getPartnerships.find(p => p.id === item.partnershipId)
          ?.name ?? '--'),
  }));

  const columns: GridColumns<GridValidRowModel> = [
    {
      field: 'contractType',
      headerName: 'Contract Type',
      flex: 1,
    },
    {
      field: 'prices',
      headerName: 'Prices',
      flex: 1,
      renderCell: (params: GridRenderCellParams): JSX.Element => {
        const contractType = params.row.contractType;
        return (
          <DisplayContractDetails
            contractType={contractType}
            templateName={params.row.template}
            chronicPrice={params.row.chronicPrice}
            acutePrice={params.row.acutePrice}
            inPersonVisitPrice={params.row.inPersonVisitPrice}
            contractPackages={params.row.contractPackages}
            currency={
              data.find(contract => contract.id === params.row.id)?.currency ??
              'USD'
            }
          />
        );
      },
    },
    {
      field: 'effectiveDates',
      headerName: 'Effective Dates',
      flex: 1.2,
      minWidth: 100,
      sortComparator: effectiveDatesComparator,
      valueFormatter: ({
        value,
      }: GridValueFormatterParams<ContractDateRange>): string =>
        computeEffectiveRange(value.startDate, value.endDate),
    },
    {
      flex: 0,
      sortable: false,
      field: 'status',
      headerName: 'Status',
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams<ActiveStatus>): JSX.Element => (
        <HHStack direction="row" spacing={1}>
          <HHChip
            hhVariant="variant-bypass"
            color={calculateChipColor(params.value)}
            size="small"
            label={params.value}
          />
        </HHStack>
      ),
    },
    {
      flex: 0,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      field: 'actions',
      minWidth: 125,
      headerName: '',
      renderCell: (params: GridRenderCellParams): JSX.Element => {
        const foundContract = data.find(
          ({ id }) => id === parseInt(String(params.id), 10),
        );
        return (
          <HHStack flexWrap="wrap" direction="row" spacing={1}>
            {params.row.status !== ActiveStatus.Void ? (
              <EditContractButton
                isText={true}
                contractId={parseInt(String(params.id), 10)}
                route={
                  params.row.packageEnabled === true
                    ? routes.billing.editContractPackageContract
                    : routes.billing.editContract
                }
              />
            ) : null}
            {foundContract ? (
              <ContractSettingsButton contract={foundContract} />
            ) : null}
            <HHIconButton
              hhVariant="variant-bypass"
              aria-label="View"
              size="small"
              color="primary"
              title="View"
              onClick={(): void => {
                setContractIdToView(parseInt(String(params.id), 10));
                setIsModelOpen(true);
              }}
            >
              <VisibilityIcon fontSize="small" />
            </HHIconButton>
          </HHStack>
        );
      },
    },
  ];

  if (partnershipContractEnabled) {
    columns.splice(1, 0, {
      // when split flag removed, move this to second column in columns array above
      field: 'partnership',
      headerName: 'Partnership',
      flex: 1,
    });
  }

  const closeModal = (): void => {
    setContractIdToView(null);
    setIsModelOpen(false);
  };

  return rows.length === 0 ? null : (
    <Box>
      <HHTypography hhVariant="section-title">
        {ActiveContractLabels.CONTRACTS_LABEL}
      </HHTypography>
      <Box className={classes.gridStyles} data-testid={CONTRACT_GRID_TEST_ID}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          hideFooter
          autoHeight
          disableVirtualization
          sx={{
            border: 0,
            '& .MuiDataGrid-iconSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: (theme): string => theme.palette.action.focus,
            },
          }}
          getRowClassName={(params): string =>
            params.indexRelativeToCurrentPage % 2 === 0
              ? classes.evenRow
              : classes.oddRow
          }
        />
      </Box>
      <Modal
        open={isModelOpen}
        onClose={(): void => closeModal()}
        closeAfterTransition
      >
        <Box className={classes.modalStyles}>
          <HHStack
            direction="column"
            justifyContent="flex-end"
            height="100%"
            spacing={4}
          >
            <HHStack flex={1} direction="column" spacing={4} overflow="hidden">
              <HHStack flex={0} direction="row" justifyContent="space-between">
                <HHTypography hhVariant="section-title">
                  Contract Details
                </HHTypography>
                <HHIconButton
                  hhVariant="variant-bypass"
                  size="medium"
                  color="default"
                  aria-label="close"
                  onClick={(): void => closeModal()}
                >
                  <Close />
                </HHIconButton>
              </HHStack>
              <HHStack direction="column" overflow="auto">
                <ContractDetailView
                  templates={templates}
                  contract={data.find(({ id }) => id === contractIdToView)}
                  showJson
                />
              </HHStack>
            </HHStack>
            {data.find(({ id }) => id === contractIdToView)?.void === false ? (
              <HHStack flex={0} direction="row">
                <EditContractButton
                  isText={false}
                  contractId={contractIdToView ?? 0}
                  route={
                    data.find(({ id }) => id === contractIdToView)
                      ?.packageEnabled === true
                      ? routes.billing.editContractPackageContract
                      : routes.billing.editContract
                  }
                />
              </HHStack>
            ) : null}
          </HHStack>
        </Box>
      </Modal>
    </Box>
  );
};

export default ContractGrid;
