import {
  HHAlert,
  HHBreadcrumbs,
  HHButton,
  HHLink,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { InferType } from 'yup';
import { routes } from '../../constants/strings/routes';
import { useDynamicDialogContext } from '../../context/dynamic-dialog.context';
import { useSnackbarContext } from '../../context/snackbar.context';
import {
  BillingTypes,
  ClientType,
  PartnershipType,
  useGetAllClientsQuery,
  useGetAllInsurersQuery,
  useGetAllProgramIndicationsQuery,
  useGetPartnershipsQuery,
  useGetPaymentTypesQuery,
  useRunClaimsManualWebDagMutation,
} from '../../types';
import LoadingComponent from '../utils/loading';
import BillToolForm from './billing-form';
import ToolRunStatus, { DagRunStatus, RunStatusType } from './tool-run-status';
import {
  addAllOption,
  EligibilitySearchDepth,
  InsurerType,
  ToolRunMode,
  ToolValidationSchema,
} from './tool-validation-schema';

const BillToolRun = (): JSX.Element => {
  const { setDialogContent } = useDynamicDialogContext();
  const { setSnackbarState } = useSnackbarContext();
  const [runClaimsManualWebDagMutation] = useRunClaimsManualWebDagMutation();
  const {
    data: clientsData,
    error: clientsError,
    loading: clientsLoading,
  } = useGetAllClientsQuery();
  const {
    data: insurersData,
    error: insurersError,
    loading: insurersLoading,
  } = useGetAllInsurersQuery();
  const {
    data: partnershipsData,
    error: partnershipsError,
    loading: partnershipsLoading,
  } = useGetPartnershipsQuery({ variables: { type: 'billing' } });
  const {
    data: programIndicationData,
    error: programIndicationError,
    loading: programIndicationLoading,
  } = useGetAllProgramIndicationsQuery();
  const {
    data: paymentTypeData,
    error: paymentTypeError,
    loading: paymentTypeLoading,
  } = useGetPaymentTypesQuery();
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(ToolValidationSchema),
    criteriaMode: 'all',
  });
  const [sortedClientsData, setSortedClientsData] = useState<ClientType[]>([]);
  const [sortedInsurersData, setSortedInsurersData] = useState<InsurerType[]>(
    [],
  );
  const [sortedPartnershipsData, setSortedPartnershipsData] = useState<
    PartnershipType[]
  >([]);
  const { handleSubmit, formState, reset, watch, resetField } = methods;
  const { isValid, isSubmitting, isDirty } = formState;

  const runMode = watch('runMode');

  const onFormSubmit: SubmitHandler<
    InferType<typeof ToolValidationSchema>
  > = formData => {
    const finalData = {
      claimsStartDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
      claimsEndDate: dayjs(formData.endDate).format('YYYY-MM-DD'),
      claimsClient: formData.client?.identifier.toLowerCase() || '',
      paymentTypes: formData.paymentTypes,
      claimsLimit: formData.claimsLimit,
      insurerId: formData.insurer?.id,
      partnership: formData.partnership?.name,
      eligibilitySearchDepth: formData.eligibilitySearchDepth,
      programIndication: null,
      excludePartnerships: formData.excludePartnership,
      mode: formData.runMode,
      claimsBillingType: formData.billingType.toLowerCase(),
    };

    return runClaimsManualWebDagMutation({
      variables: {
        dagRunRequestInput: {
          conf: finalData,
        },
      },
    })
      .then(({ data }) => {
        if (!data?.runClaimsManualWebDag) {
          throw new Error('Failed to run billing tool');
        }
        setSnackbarState('Billing tool run successfully', 'success', 4000);
        const runStatus: RunStatusType[] = JSON.parse(
          localStorage.getItem('runStatus') ?? '[]',
        );
        runStatus.unshift({
          dagId: data.runClaimsManualWebDag.dagId,
          dagRunId: data.runClaimsManualWebDag.dagRunId,
          note: data.runClaimsManualWebDag.note,
          state: data.runClaimsManualWebDag.state as DagRunStatus,
          logicalDate: dayjs(data.runClaimsManualWebDag.logicalDate).format(
            'L LT',
          ),
          conf: finalData,
        });
        if (runStatus.length > 10) {
          runStatus.length = 10;
        }
        localStorage.setItem('runStatus', JSON.stringify(runStatus));
      })
      .catch(() => {
        setSnackbarState('Billing tool run failed', 'error', 4000);
      })
      .finally(() => {
        resetField('runMode', { defaultValue: ToolRunMode.PREVIEW });
      });
  };

  useEffect(() => {
    setSortedClientsData(
      [...(clientsData?.getAllClients || [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    );
  }, [clientsData, setSortedClientsData]);

  useEffect(() => {
    setSortedInsurersData(
      [...(insurersData?.getAllInsurers || [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    );
  }, [insurersData, setSortedInsurersData]);

  useEffect(() => {
    setSortedPartnershipsData(
      [...(partnershipsData?.getPartnerships || [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    );
  }, [partnershipsData, setSortedPartnershipsData]);

  useEffect(() => {
    reset({
      clientOptions:
        sortedClientsData.length > 0 ? addAllOption(sortedClientsData) : [],
      insurerOptions: sortedInsurersData,
      partnershipOptions: sortedPartnershipsData,
      runMode: ToolRunMode.PREVIEW,
      eligibilitySearchDepth: EligibilitySearchDepth.STANDARD,
      claimsLimit: 0,
      paymentTypes: null,
      excludePartnership: false,
      startDate: dayjs().subtract(1, 'month').toDate(),
      endDate: dayjs().toDate(),
      billingTypeOptions: Object.values(BillingTypes).map(value => ({
        value,
        displayName: value,
      })),
    });
  }, [reset, sortedClientsData, sortedInsurersData, sortedPartnershipsData]);

  if (
    clientsLoading ||
    insurersLoading ||
    partnershipsLoading ||
    programIndicationLoading ||
    paymentTypeLoading
  ) {
    return <LoadingComponent center />;
  }

  if (
    clientsError ||
    insurersError ||
    partnershipsError ||
    programIndicationError ||
    paymentTypeError
  ) {
    return (
      <HHAlert hhVariant="outlined" severity="error">
        <HHTypography hhVariant="body">
          There was an error loading client insurer list
        </HHTypography>
      </HHAlert>
    );
  }

  if (
    !clientsData ||
    !insurersData ||
    !partnershipsData ||
    !programIndicationData ||
    !paymentTypeData
  ) {
    return (
      <HHStack spacing={2} overflow="hidden">
        <HHTypography hhVariant="body">Client info not found</HHTypography>
      </HHStack>
    );
  }

  return (
    <FormProvider {...methods}>
      <HHStack spacing={8} direction="column" padding={6}>
        <HHBreadcrumbs aria-label="breadcrumb">
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Operations Hub
          </HHLink>
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Billing
          </HHLink>
          <HHTypography color="text.primary" hhVariant="variant-bypass">
            Tool
          </HHTypography>
        </HHBreadcrumbs>
        <HHTypography hhVariant="section-title">
          Bill Submission Tool
        </HHTypography>
        <BillToolForm
          clientsData={sortedClientsData}
          insurersData={sortedInsurersData}
          partnershipsData={sortedPartnershipsData}
          paymentTypesData={paymentTypeData.getPaymentTypes}
        />
        <HHStack direction="row" spacing={4} alignSelf="end">
          <HHButton
            hhVariant="variant-bypass"
            variant="outlined"
            size="medium"
            color="primary"
            disabled={isSubmitting}
            onClick={(): void => {
              setDialogContent({
                title: (
                  <HHTypography hhVariant="section-title">
                    Run Status
                  </HHTypography>
                ),
                content: <ToolRunStatus />,
                fullWidth: true,
                width: 'md',
                footer: (
                  <HHTypography hhVariant="muted">
                    <i>* These runs are saved in your browser local storage</i>
                  </HHTypography>
                ),
              });
            }}
          >
            Run Status
          </HHButton>
          <HHButton
            hhVariant="variant-bypass"
            variant="outlined"
            size="medium"
            color="primary"
            onClick={(): void => {
              reset();
            }}
          >
            Clear
          </HHButton>
          <HHButton
            hhVariant="variant-bypass"
            variant="contained"
            size="medium"
            color={runMode === ToolRunMode.PREVIEW ? 'primary' : 'error'}
            disabled={!isDirty || !isValid || isSubmitting}
            onClick={handleSubmit(onFormSubmit)}
          >
            {runMode === ToolRunMode.PREVIEW ? 'Run Preview' : 'Run Submission'}
          </HHButton>
        </HHStack>
      </HHStack>
    </FormProvider>
  );
};

export default BillToolRun;
