import {
  HHCheckbox,
  HHFormControlLabel,
} from '@hinge-health/react-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import {
  billableActivitiesMap,
  BILLABLE_ACTIVITY_TEST_ID,
} from '../../../../constants/strings/contract/form-constants';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';
import { PackageFormData } from '../../package/custom-package-types';
import { BillableActivitiesProps } from './billable-activities';
import { EngagementTypeWithRules, RuleType } from './engagement-types-rules';

export interface BillableActivityCheckBoxProps
  extends Pick<BillableActivitiesProps, 'wholeFormDisabled' | 'isPackageForm'> {
  engagementType: EngagementTypeWithRules;
  index: number;
}

const BillableActivityCheckBox = ({
  engagementType,
  isPackageForm,
  wholeFormDisabled,
  index,
}: BillableActivityCheckBoxProps): JSX.Element => {
  const { control } = useFormContext<PackageFormData>();
  const engagementTypeDefaultSubType = engagementType.subTypeOptions?.[
    RuleType[engagementType.name as keyof typeof RuleType]
  ]?.find(subType => subType.defaultSelected);

  return (
    <HHFormControlLabel
      key={engagementType.id}
      data-testid={BILLABLE_ACTIVITY_TEST_ID}
      label={
        billableActivitiesMap[engagementType.name] ??
        capitalizeFirstLetterAndSpaceSnakeString(engagementType.name)
      }
      control={
        <Controller
          control={control}
          name={
            isPackageForm
              ? `selectedPackages.${index}.selectedBillableActivities`
              : 'selectedBillableActivities'
          }
          render={({ field: { onChange, value } }): JSX.Element => (
            <HHCheckbox
              hhVariant="primary"
              disabled={wholeFormDisabled || engagementType?.disabled}
              sx={{ color: 'gray' }}
              checked={engagementType.id in value}
              onChange={(): void => {
                const newValue = { ...value };
                if (engagementType.id in newValue) {
                  delete newValue[engagementType.id];
                } else {
                  newValue[engagementType.id] = engagementTypeDefaultSubType
                    ? {
                        [RuleType[
                          engagementType.name as keyof typeof RuleType
                        ]]: engagementTypeDefaultSubType.value,
                      }
                    : null;
                }
                onChange(newValue);
              }}
            />
          )}
        />
      }
    />
  );
};

export default BillableActivityCheckBox;
