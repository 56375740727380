import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  BillingModelType,
  ContractType,
  EngagementType,
} from '../../../../types';
import { getBillableActivitiesFromContractPackageOrDefault } from '../../../../utils/contract-utils';
import { PackageFormData } from '../../package/custom-package-types';
import LegacyBillableActivities from './billable-activities-legacy';
import BillableActivitiesWithSubTypes from './billable-activities-with-sub-types';
import { mapEngagementTypesToRules } from './engagement-types-rules';

export interface BillableActivitiesProps {
  wholeFormDisabled: boolean | undefined;
  engagementTypes: EngagementType[];
  direction?: string;
  isPackageForm?: boolean;
  billingModels?: BillingModelType[];
  index?: number;
  existingContract?: ContractType;
}

export const BillableActivities = ({
  wholeFormDisabled,
  engagementTypes,
  direction,
  billingModels,
  isPackageForm = false,
  index = 0,
  existingContract,
}: BillableActivitiesProps): JSX.Element => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<PackageFormData>();

  const watchedBillingModelId = watch(
    `selectedPackages.${index}.billingModelId`,
  );

  const billingModel = billingModels?.find(
    bm => bm.id === watchedBillingModelId,
  );

  const billingModelName =
    billingModel?.name ?? (isPackageForm ? 'engagement' : 'legacy');

  const engagementTypesWithRules = mapEngagementTypesToRules(
    billingModelName,
    engagementTypes,
  );

  useEffect(() => {
    if (isPackageForm) {
      setValue(
        `selectedPackages.${index}.selectedBillableActivities`,
        getBillableActivitiesFromContractPackageOrDefault(
          billingModelName,
          engagementTypesWithRules,
          index,
          existingContract,
        ),
        { shouldValidate: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedBillingModelId]);

  if (engagementTypes.length === 0) {
    return (
      <HHTypography hhVariant="body" sx={{ color: 'red' }}>
        billable activities unable to load
      </HHTypography>
    );
  }

  return (
    <HHStack>
      <HHTypography hhVariant="caption" color={'GrayText'}>
        Billable Activities
      </HHTypography>
      {errors?.selectedPackages?.[index]?.selectedBillableActivities && (
        <HHTypography
          hhVariant="variant-bypass"
          sx={{ color: (theme): string => theme.palette.error.main }}
          variant="caption"
        >
          {
            errors?.selectedPackages?.[index]?.selectedBillableActivities
              ?.message
          }
        </HHTypography>
      )}
      {isPackageForm ? (
        <BillableActivitiesWithSubTypes
          wholeFormDisabled={wholeFormDisabled}
          engagementTypes={engagementTypesWithRules}
          direction={direction}
          isPackageForm={isPackageForm}
          index={index}
        />
      ) : (
        // TODO: remove with https://hingehealth.atlassian.net/browse/MINT-8511
        <LegacyBillableActivities
          wholeFormDisabled={wholeFormDisabled}
          engagementTypes={engagementTypesWithRules}
          direction={direction}
          isPackageForm={isPackageForm}
          index={index}
        />
      )}
    </HHStack>
  );
};
