import dayjs from 'dayjs';
import { ContractSubscriptionType } from '../../types';

export const getContractNotificationMessage = (
  contractId: number | null,
  isVoid: boolean,
  contractSubscriptiondData: ContractSubscriptionType | undefined,
  endDate: dayjs.Dayjs | null,
): string => {
  if (!contractId) {
    const activeSubscriptions =
      contractSubscriptiondData?.subscriptionCount ?? 0;
    const activeFeeForServiceEvents =
      contractSubscriptiondData?.feeForServiceEventCount ?? 0;
    return activeSubscriptions + activeFeeForServiceEvents > 0
      ? `There are ${activeSubscriptions} subscriptions and ${activeFeeForServiceEvents} fee for service events that have not been voided. Please submit a work request.`
      : 'No conflicting data found for new contract.';
  }
  if (isVoid) {
    return `${contractSubscriptiondData?.subscriptionCount} subscriptions and ${contractSubscriptiondData?.feeForServiceEventCount} fee for service events will be voided, as well as all associated bills and discounts.`;
  } else if (endDate?.isAfter(dayjs(), 'day') || !endDate) {
    return 'Changes will not affect any subscriptions, bills, or submissions';
  } else {
    return `${contractSubscriptiondData?.subscriptionCount} subscriptions and ${contractSubscriptiondData?.feeForServiceEventCount} fee for service events starting after ${endDate?.format('MM/DD/YYYY')} will be voided, as well as all associated bills and discounts.`;
  }
};
