import { HHBaseTable } from '@hinge-health/react-component-library';
import { PackageType } from '../../../types';
import { formatCurrency } from '../../../utils/currency-helpers';
import { IDiscount, IDiscountUsage } from '../../../utils/discount-utils';
import DiscountDetailBase, {
  DiscountDetailBaseProps,
} from './discount-detail-base';

export interface VolumeBasedDiscountsProps extends DiscountDetailBaseProps {
  discountData: IDiscount;
  availablePackages: PackageType[];
  currency: string | null;
}

const subscriptionTierString = (
  subscriptionTier: (number | null | undefined)[],
): string => {
  const leftSide = subscriptionTier[0] ?? 1;
  const rightSide = subscriptionTier[1] ? ` - ${subscriptionTier[1]}` : '+';
  return `${leftSide}${rightSide}`;
};

const getUsageDisplay = (discountUsage: IDiscountUsage): string => {
  if (discountUsage.loading || discountUsage.error) {
    return '--';
  }

  return String(discountUsage?.totalCount || 0);
};

const VolumeBasedDiscountsDetail = ({
  discountData,
  availablePackages,
  currency,
  handleEditDiscountClick,
}: VolumeBasedDiscountsProps): JSX.Element => (
  <DiscountDetailBase
    handleEditDiscountClick={handleEditDiscountClick}
    discountData={discountData}
    availablePackages={availablePackages}
  >
    <HHBaseTable
      headerRowBottomBorder="1px solid rgba(0, 0, 0, 0.12)"
      bodyRowBorder={{
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
      bodyTypographyVariant="inherit"
      tableHeaders={[
        { value: 'Tier', align: 'left' },
        { value: 'Subscriptions per Tier', align: 'left' },
        { value: 'Subscriptions Assigned', align: 'left' },
        { value: 'Tier Price (Milestones)', align: 'left' },
      ]}
      bodyContent={discountData.discountDetails.map(row => ({
        tier: {
          value: row.tier,
        },
        subscriptionTier: {
          value: subscriptionTierString(row.subscriptionTier),
        },
        subscriptionsCount: {
          value: getUsageDisplay(row.discountUsage),
        },
        discount: {
          value: formatCurrency(row.discount, currency),
        },
      }))}
    />
  </DiscountDetailBase>
);

export default VolumeBasedDiscountsDetail;
