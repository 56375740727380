import {
  HHFormControlLabel,
  HHRadio,
  HHStack,
} from '@hinge-health/react-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { capitalizeFirstLetterAndSpaceCamelCaseString } from '../../../../utils/bills-utils';
import { PackageFormData } from '../../package/custom-package-types';
import { BillableActivityCheckBoxProps } from './billable-activity-checkbox';
import { RuleType } from './engagement-types-rules';

export type BillableActivitySubTypesProps = Pick<
  BillableActivityCheckBoxProps,
  'wholeFormDisabled' | 'index' | 'engagementType'
>;

const BillableActivitySubTypes = ({
  engagementType,
  wholeFormDisabled = false,
  index = 0,
}: BillableActivitySubTypesProps): JSX.Element | null => {
  const { control } = useFormContext<PackageFormData>();
  const subTypeKey = RuleType[engagementType.name as keyof typeof RuleType];

  return (
    <HHStack direction="column" paddingLeft={12}>
      {engagementType.subTypeOptions?.[subTypeKey]?.map(st => (
        <HHFormControlLabel
          label={`${capitalizeFirstLetterAndSpaceCamelCaseString(subTypeKey)} ${
            st.value
          }`}
          key={`${engagementType.id}-${engagementType.name}-${st.value}`}
          className="billable-activities-subtypes"
          control={
            <Controller
              control={control}
              name={`selectedPackages.${index}.selectedBillableActivities`} //extend with legacy
              render={({ field: { onChange, value } }): JSX.Element => (
                <HHRadio
                  hhVariant="primary"
                  value={st.value}
                  disabled={st.disabled || wholeFormDisabled}
                  checked={value[engagementType.id]?.[subTypeKey] === st.value}
                  onChange={(e): void => {
                    onChange({
                      ...value,
                      [engagementType.id]: {
                        [subTypeKey]: parseInt(e.target.value),
                      },
                    });
                  }}
                />
              )}
            />
          }
        />
      ))}
    </HHStack>
  );
};
export default BillableActivitySubTypes;
