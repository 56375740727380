import {
  HHDivider,
  HHPaper,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { ActiveContractLabels } from '../../../constants/strings/billing-label-enums';
import { IN_PERSON_NAME_KEY } from '../../../constants/strings/contract/form-constants';
import {
  ContractPackagesType,
  ContractTemplateType,
  ContractType,
} from '../../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../utils/bills-utils';
import {
  isEngagementModelByName,
  isMilestoneModelByName,
} from '../../../utils/contract-utils';
import { formatCurrency } from '../../../utils/currency-helpers';
import { computeEffectiveRange } from '../../../utils/date-helper';
import MetaInfoDisplay from '../../utils/meta-info-display';
import { TextGrouping } from '../../utils/text-grouping';
import { Milestones } from '../contract-view-components/milestones';
import { SelectedPackageIndications } from '../package/components/form-right-panel';
import { ValidBillingModel } from '../package/custom-package-types';
import { PackageBillableActivityDisplay } from './billable-activity-display';

interface ProductPackageContractViewProps {
  contract: ContractType;
  productPackages: { key: string; contractPackage: ContractPackagesType }[];
  templates: ContractTemplateType[];
  slimDisplay?: boolean;
}

const ProductPackageContractView = ({
  contract,
  productPackages,
  templates,
  slimDisplay = false,
}: ProductPackageContractViewProps): JSX.Element => (
  <HHStack sx={{ position: 'relative' }} gap={2}>
    {/* Contract Details */}
    <HHStack
      display="grid"
      gridTemplateColumns={slimDisplay ? '1fr' : 'repeat(3, 1fr)'}
      gap={slimDisplay ? 2 : 0}
      padding={2}
    >
      <TextGrouping
        label={ActiveContractLabels.EFFECT_DATE_LABEL}
        text={computeEffectiveRange(contract.startDate, contract.endDate)}
      />
      <TextGrouping
        label={ActiveContractLabels.USER_ANNUAL_CAP}
        text={
          contract.userAnnualCap
            ? formatCurrency(contract.userAnnualCap, contract.currency)
            : 'No user annual spending cap found'
        }
      />
      <TextGrouping
        label={ActiveContractLabels.CURRENCY}
        text={contract.currency ?? 'Currency not found'}
      />
      <MetaInfoDisplay
        updatedAt={contract.updatedAt}
        createdAt={contract.createdAt}
        lastModifiedBy={contract.lastModifiedBy ?? '--'}
        floating
      />
    </HHStack>
    {/* Packages */}
    {productPackages.map(pp => (
      <HHPaper
        key={pp.key}
        elevation={3}
        sx={{ backgroundColor: 'background.default', padding: 4 }}
      >
        {Object.values(ValidBillingModel).includes(
          pp.contractPackage.billingModel.name as ValidBillingModel,
        ) ? (
          <HHStack gap={4}>
            {/* Package Header */}
            <HHStack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <HHTypography
                hhVariant="body"
                // @ts-expect-error - layoutStyles does not include this property
                layoutStyles={{ fontWeight: '500' }}
              >
                {`${
                  pp.key === IN_PERSON_NAME_KEY
                    ? capitalizeFirstLetterAndSpaceSnakeString(pp.key)
                    : pp.key
                } package`}
              </HHTypography>
              {pp.contractPackage.package.packageOfferings.some(
                p => p.packageOffering === 'program_indication',
              ) && (
                <SelectedPackageIndications
                  packageOfferings={pp.contractPackage.package.packageOfferings}
                />
              )}
            </HHStack>
            {/* Package Details */}
            <HHStack
              display="grid"
              gridTemplateColumns={slimDisplay ? '1fr' : 'repeat(3, 1fr)'}
              gap={slimDisplay ? 2 : 0}
            >
              <HHStack direction="column" gap={2}>
                <TextGrouping
                  label="Billing Model"
                  text={capitalizeFirstLetterAndSpaceSnakeString(
                    pp.contractPackage.billingModel.name,
                  )}
                />
                {isEngagementModelByName(
                  pp.contractPackage.billingModel.name,
                ) && (
                  // we have extra data with engagement model, showing pricing grouped with billing model if engagement model
                  <TextGrouping
                    label="Price"
                    text={formatCurrency(
                      pp.contractPackage.price,
                      contract.currency,
                    )}
                  />
                )}
              </HHStack>
              {isMilestoneModelByName(pp.contractPackage.billingModel.name) && (
                <TextGrouping
                  label="Template"
                  text={
                    templates.find(
                      t => t.id === pp.contractPackage.contractTemplateId,
                    )?.name ?? 'Template not found'
                  }
                />
              )}
              {isEngagementModelByName(
                pp.contractPackage.billingModel.name,
              ) && (
                <HHStack direction="column" gap={2}>
                  <TextGrouping
                    label={ActiveContractLabels.ENGAGEMENT_INITIAL_FEE}
                    text={formatCurrency(
                      pp.contractPackage.rules?.activationFee ?? 0,
                      contract.currency,
                    )}
                  />
                  <TextGrouping
                    label={ActiveContractLabels.ENGAGEMENT_ACTIVITY_FEE}
                    text={formatCurrency(
                      pp.contractPackage.rules?.activityFee ?? 0,
                      contract.currency,
                    )}
                  />
                </HHStack>
              )}
              {!isEngagementModelByName(
                pp.contractPackage.billingModel.name,
              ) && (
                <TextGrouping
                  label="Price"
                  text={formatCurrency(
                    pp.contractPackage.price,
                    contract.currency,
                  )}
                />
              )}
              {!isMilestoneModelByName(
                pp.contractPackage.billingModel.name,
              ) && (
                <PackageBillableActivityDisplay
                  packageBillableActivities={
                    pp.contractPackage.billableActivityTypes ?? []
                  }
                />
              )}
            </HHStack>
            {isMilestoneModelByName(pp.contractPackage.billingModel.name) && (
              <>
                <HHDivider />
                <Milestones
                  contractDetails={pp.contractPackage.rules}
                  templateName={
                    templates.find(
                      t => t.id === pp.contractPackage.contractTemplateId,
                    )?.name
                  }
                  slimDisplay={slimDisplay}
                  contractBillableActivities={
                    pp.contractPackage.billableActivityTypes
                  }
                  currency={contract.currency ?? 'USD'}
                />
              </>
            )}
          </HHStack>
        ) : (
          <HHTypography hhVariant="muted">
            {`Invalid billing model for package: ${pp.contractPackage.billingModel.name}`}
          </HHTypography>
        )}
      </HHPaper>
    ))}
  </HHStack>
);

export default ProductPackageContractView;
