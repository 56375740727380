import {
  HHStack,
  HHToggleButton,
  HHToggleButtonGroup,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GetSplitTreatmentOnOrOff } from '../../../../../../components/splits';
import {
  migrateMilestonesToPackage,
  migrateStandardToPackage,
  ptvvFlag,
} from '../../../../constants/strings/split';
import { BillingModelType } from '../../../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';
import {
  isEngagementModelByName,
  isFeeForServiceModelById,
} from '../../../../utils/contract-utils';
import { getDefaultPackagePrice } from '../../form/components/engagement-types-rules';
import { ValidBillingModel } from '../custom-package-types';
import { ContractPackageFormLabelText, fieldArrayName } from '../form';

interface BillingModelSelectProps {
  billingModels: BillingModelType[];
  index: number;
  wholeFormDisabled?: boolean;
}

const BillingModelSelect: React.FC<BillingModelSelectProps> = ({
  billingModels,
  index,
  wholeFormDisabled,
}): JSX.Element => {
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();

  const migrateStandardToPackageSplitEnabled = GetSplitTreatmentOnOrOff(
    migrateStandardToPackage,
    adminId,
  );
  const migrateMilestonesToPackageSplitEnabled = GetSplitTreatmentOnOrOff(
    migrateMilestonesToPackage,
    adminId,
  );
  const ptvvFlagEnabled = GetSplitTreatmentOnOrOff(ptvvFlag, adminId);

  const {
    trigger,
    watch,
    formState: { isDirty },
    setValue,
  } = useFormContext();
  const watchedBillingModelId = watch(
    `selectedPackages.${index}.billingModelId`,
  );

  const filteredBillingModels = billingModels.filter(model => {
    switch (model.name) {
      case ValidBillingModel.STANDARD:
        return migrateStandardToPackageSplitEnabled;
      case ValidBillingModel.INDIVIDUAL_MILESTONE:
        return migrateMilestonesToPackageSplitEnabled;
      case ValidBillingModel.COHORT_MILESTONE:
        return migrateMilestonesToPackageSplitEnabled;
      case ValidBillingModel.FEE_FOR_SERVICE:
        return ptvvFlagEnabled;
      default:
        return isEngagementModelByName(model.name);
    }
  });

  useEffect(() => {
    if (watchedBillingModelId > 0 && isDirty) {
      trigger('selectedPackages');
      setValue(
        `${fieldArrayName}.${index}.price`,
        getDefaultPackagePrice(
          billingModels.find(bm => bm.id === watchedBillingModelId)?.name ??
            'engagement',
        ),
      );
    }
  }, [trigger, watchedBillingModelId, isDirty, billingModels, setValue, index]);

  return (
    <HHStack direction="column" spacing={2}>
      <HHTypography hhVariant="caption" color="gray">
        {ContractPackageFormLabelText.BILLING_MODEL}
      </HHTypography>
      <Controller
        name={`${fieldArrayName}.${index}.billingModelId`}
        render={({ field: { onChange, value } }): JSX.Element => (
          <HHToggleButtonGroup size="medium">
            {filteredBillingModels.map(model => (
              <HHToggleButton
                hhVariant="variant-bypass"
                key={model.id}
                value={model.id}
                onClick={(): void => {
                  onChange(model.id);
                  // if the billing model is fee for service,
                  // set package id to insure we don't show packages that are not eligible
                  if (
                    isFeeForServiceModelById(billingModels, model.id) ||
                    isFeeForServiceModelById(
                      billingModels,
                      watchedBillingModelId,
                    )
                  ) {
                    setValue(`${fieldArrayName}.${index}.packageId`, 0);
                  }
                }}
                selected={value === model.id}
                sx={(theme): SystemStyleObject<Theme> => ({
                  backgroundColor: 'white',
                  width: '100%',
                  textTransform: 'none',
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.action.focus,
                  },
                })}
                disabled={wholeFormDisabled}
              >
                <HHTypography hhVariant="body">
                  {capitalizeFirstLetterAndSpaceSnakeString(model.name)}
                </HHTypography>
              </HHToggleButton>
            ))}
          </HHToggleButtonGroup>
        )}
      />
    </HHStack>
  );
};

export default BillingModelSelect;
