import { HHLinearProgress } from '@hinge-health/react-component-library/dist/components/atoms/hh-linear-progress';
import { DataGrid, GridColumns, GridEventListener } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { dateSortComparator } from '../../../../utils/datagrid-utils';
import { NoRowsMessage } from '../../../hinge-connect/components/no-rows';
import { CopyActionButton } from '../../../hinge-connect/rules/components/copy-rule/copy-action-button';
import { ListRulesGridStatusChip } from '../../../hinge-connect/rules/components/list-rules-grid-status-chip';
import {
  listRulesGridContent,
  rulesRoutes,
} from '../../../hinge-connect/rules/constants/rules.constant';
import { ListRulesQuery } from '../../../hinge-connect/rules/types';
import {
  getRuleName,
  getRuleSource,
  getRuleStatus,
  getRuleUpdatedAt,
} from '../../../hinge-connect/rules/utils/list-rules-grid-utils';
import { tabRoutes } from '../../constants/member-data-services-constants.constant';
import {
  DeleteActionButton,
  EditActionButton,
  RunActionButton,
} from './targeted-enrollment-grid-action-buttons';

type TargetedEnrollmentGridProps = {
  listRulesData: ListRulesQuery | undefined;
  listRulesLoading: boolean;
  refetchListRules: () => void;
  deleteRule: ({ variables }: { variables: { id: string } }) => void;
  copyRuleLoading: boolean;
  deleteRuleLoading: boolean;
  listRulesError: Error | undefined;
};

const Footer = (): JSX.Element => <></>;

export const TargetedEnrollmentGrid = (
  props: TargetedEnrollmentGridProps,
): JSX.Element => {
  const {
    listRulesData,
    listRulesLoading,
    deleteRule,
    deleteRuleLoading,
    copyRuleLoading,
    refetchListRules,
  } = props;

  enum ColumnHeaders {
    ruleName = 'ruleName',
    source = 'source',
    status = 'status',
    updatedAt = 'updatedAt',
    id = 'id',
  }

  const ListRulesColumns: GridColumns = [
    {
      field: ColumnHeaders.ruleName,
      headerName: listRulesGridContent.ruleName,
      width: 200,
      valueGetter: getRuleName,
    },
    {
      field: ColumnHeaders.source,
      headerName: listRulesGridContent.source,
      width: 200,
      valueGetter: getRuleSource,
    },
    {
      field: ColumnHeaders.status,
      headerName: listRulesGridContent.status,
      width: 200,
      valueGetter: getRuleStatus,
      renderCell: (params): JSX.Element => (
        <ListRulesGridStatusChip status={params.value as string} />
      ),
    },
    {
      field: ColumnHeaders.updatedAt,
      headerName: listRulesGridContent.updatedAt,
      type: 'date',
      width: 200,
      valueGetter: getRuleUpdatedAt,
      renderCell: (params): string => params.value.toLocaleString(),
      sortComparator: dateSortComparator,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'left',
      headerName: listRulesGridContent.actions,
      headerAlign: 'left',
      width: 200,
      sortable: false,
      getActions: (params): JSX.Element[] => [
        <RunActionButton />,
        <EditActionButton ruleId={params.id as string} />,
        <DeleteActionButton
          ruleId={params.id as string}
          deleteRule={deleteRule}
          deleteRuleLoading={deleteRuleLoading}
          ruleName={''}
        />,
        <CopyActionButton
          ruleId={params.id as string}
          refetchListRules={refetchListRules}
          copyRuleLoading={copyRuleLoading}
          ruleName={''}
        />,
      ],
    },
  ];

  const navigate = useNavigate();

  const handleRowClick: GridEventListener<'rowClick'> = params => {
    navigate(
      `/${tabRoutes.baseRoute}/${tabRoutes.targetedEnrollment}/${params.id}/${rulesRoutes.view}`,
    );
  };

  return (
    <>
      {listRulesLoading && <HHLinearProgress />}
      <DataGrid
        rows={listRulesData?.listRules?.rules ?? []}
        columns={ListRulesColumns}
        autoHeight
        disableSelectionOnClick
        onRowClick={handleRowClick}
        components={{
          NoRowsOverlay: NoRowsMessage,
          Footer,
        }}
      />
    </>
  );
};
