import {
  HHButton,
  HHPaper,
  HHStack,
} from '@hinge-health/react-component-library';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FieldArrayWithId, useWatch } from 'react-hook-form';
import { IN_PERSON_NAME_KEY } from '../../../constants/strings/contract/form-constants';
import {
  BillingModelType,
  ContractTemplateType,
  ContractType,
  EngagementType,
  PackageType,
} from '../../../types';
import {
  isEngagementModelById,
  isMilestoneModelById,
} from '../../../utils/contract-utils';
import { BillableActivities } from '../form/components/billable-activities';
import { ContractTemplateSelect } from '../form/components/template-input';
import BillingModelSelect from './components/billing-model-select';
import { PackageSelect } from './components/package-select';
import { PriceInput } from './components/price-input';
import { ValidBillingModel } from './custom-package-types';
import { ContractPackageFormLabelText } from './form';

export interface PackageFormProps {
  billingModels: BillingModelType[];
  availablePackages: PackageType[];
  engagementTypes: EngagementType[];
  templates: ContractTemplateType[];
  wholeFormDisabled: boolean;
  index: number;
  value: FieldArrayWithId;
  remove: (index: number) => void;
  existingContract?: ContractType;
}

export const PackageForm = ({
  billingModels,
  availablePackages,
  engagementTypes,
  templates,
  wholeFormDisabled,
  index,
  value,
  remove,
  existingContract,
}: PackageFormProps): JSX.Element => {
  const watchedBillingModelId = useWatch({
    name: `selectedPackages.${index}.billingModelId`,
  });

  const isEngagementModel = isEngagementModelById(
    billingModels,
    watchedBillingModelId,
  );

  const isFeeForServiceModel =
    billingModels.find(
      model => model.name === ValidBillingModel.FEE_FOR_SERVICE,
    )?.id === watchedBillingModelId;

  const allowedPackages = isFeeForServiceModel
    ? availablePackages.filter(
        pkg =>
          pkg.name.includes('PTVV') || pkg.name.includes(IN_PERSON_NAME_KEY),
      )
    : availablePackages.filter(
        pkg =>
          !pkg.name.includes('PTVV') && !pkg.name.includes(IN_PERSON_NAME_KEY),
      );

  const watchedPackages = useWatch({
    name: 'selectedPackages',
  });

  const hasMoreThanOnePackage = watchedPackages?.length > 1;

  return (
    <HHPaper
      variant="elevation"
      elevation={2}
      sx={(theme): SystemStyleObject<Theme> => ({
        flexDirection: 'column',
        display: 'flex',
        flex: 2,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
      })}
    >
      <HHStack flex={2} spacing={4} direction="column">
        <BillingModelSelect
          billingModels={billingModels}
          key={`${index}.${value.id}.billingModelId`}
          index={index}
          wholeFormDisabled={wholeFormDisabled}
        />
        <HHStack direction="column" spacing={4}>
          <HHStack direction="row" spacing={2}>
            <PackageSelect
              availablePackages={allowedPackages}
              key={`${index}.${value.id}.packageId`}
              wholeFormDisabled={wholeFormDisabled}
              index={index}
            />
            <PriceInput
              wholeFormDisabled={wholeFormDisabled}
              priceType="price"
              label={ContractPackageFormLabelText.PRICE}
              key={`${index}.${value.id}.price`}
              index={index}
            />
          </HHStack>
          {isEngagementModel && (
            <HHStack direction="row" spacing={2}>
              <PriceInput
                wholeFormDisabled={wholeFormDisabled}
                priceType="initialFee"
                label={ContractPackageFormLabelText.INITIAL_FEE}
                key={`${index}.${value.id}.initialFee`}
                index={index}
              />
              <PriceInput
                wholeFormDisabled={wholeFormDisabled}
                priceType="activityFee"
                label={ContractPackageFormLabelText.ACTIVITY_FEE}
                key={`${index}.${value.id}.activityFee`}
                index={index}
              />
            </HHStack>
          )}
          {isMilestoneModelById(billingModels, watchedBillingModelId) && (
            <ContractTemplateSelect
              wholeFormDisabled={wholeFormDisabled}
              allTemplates={templates}
              billingModels={billingModels}
              isPackageForm={true}
              index={index}
              key={`${index}.${value.id}.currentTemplate`}
            />
          )}
          <BillableActivities
            wholeFormDisabled={wholeFormDisabled}
            engagementTypes={engagementTypes}
            billingModels={billingModels}
            direction="row"
            isPackageForm={true}
            index={index}
            key={`${index}.${value.id}.selectedBillableActivities`}
            existingContract={existingContract}
          />
        </HHStack>
      </HHStack>
      {hasMoreThanOnePackage && !wholeFormDisabled && (
        <HHStack
          flex={1}
          direction="column"
          spacing={2}
          justifyContent="flex-end"
        >
          <HHStack direction="row" spacing={2}>
            <HHButton
              hhVariant="variant-bypass"
              variant="outlined"
              size="medium"
              color="error"
              onClick={(): void => remove(index)}
            >
              Remove Package
            </HHButton>
          </HHStack>
        </HHStack>
      )}
    </HHPaper>
  );
};
