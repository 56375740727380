import {
  HHAlert,
  HHFormControlLabel,
  HHStack,
  HHSwitch,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDynamicDrawerContext } from '../../context/dynamic-drawer.context';
import { useGetFeeForServiceEventsLazyQuery } from '../../types';
import FeeForServiceList from './fee-for-service-list';

const UserFeeForService = ({ userId }: { userId: number }): JSX.Element => {
  const { onClose } = useDynamicDrawerContext();
  const [searchParams, setSearchParams] = useSearchParams({
    includeVoid: 'false',
  });

  const [getUserFeeForServiceEvents, { data, loading, error }] =
    useGetFeeForServiceEventsLazyQuery();

  const includeVoid = searchParams.get('includeVoid') === 'true';

  useEffect(() => {
    getUserFeeForServiceEvents({
      variables: { userIds: [userId], includeVoid },
    });
  }, [userId, includeVoid, getUserFeeForServiceEvents]);

  useEffect(
    () => () => {
      onClose();
    },
    [onClose],
  );

  if (error && !loading) {
    return (
      <HHAlert hhVariant="outlined" severity="error">
        <HHTypography hhVariant="body">
          There was an error loading user fee for service list
        </HHTypography>
      </HHAlert>
    );
  }

  return (
    <HHStack flex={1} spacing={8} direction="column" padding={1} paddingTop={4}>
      <HHStack spacing={1}>
        <HHStack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <HHTypography hhVariant="subtitle1">Fee for Service</HHTypography>
          <HHFormControlLabel
            label="Include voids"
            control={
              <HHSwitch
                hhVariant="primary"
                checked={includeVoid}
                onChange={(e): void => {
                  setSearchParams({ includeVoid: e.target.checked.toString() });
                }}
              />
            }
          />
        </HHStack>
        <FeeForServiceList
          data={data?.getFeeForServiceEvents}
          loading={loading}
        />
      </HHStack>
    </HHStack>
  );
};

export default UserFeeForService;
