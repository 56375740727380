import { HHStack } from '@hinge-health/react-component-library';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { cleanTypeNameGraphql } from '../../../utils/graphql-utils';
import { ContractTypeExtension } from '../form/custom-types';

export interface MilestoneJsonProps {
  contract: ContractTypeExtension[];
  editable?: boolean;
}

export const CONTRACT_MILESTONE_JSON_TEXT_AREA_ID =
  'contract-milestone-json-textarea';

const MilestoneJson = ({
  contract,
  editable = false,
}: MilestoneJsonProps): JSX.Element => (
  <HHStack direction="row">
    <TextareaAutosize
      data-testid={CONTRACT_MILESTONE_JSON_TEXT_AREA_ID}
      style={{ flex: 1, border: 0 }}
      defaultValue={JSON.stringify(cleanTypeNameGraphql(contract), null, 2)}
      disabled={!editable}
      minRows={5}
      maxRows={15}
    />
  </HHStack>
);
export default MilestoneJson;
