import {
  HHButton,
  HHDialog,
  HHDialogContent,
  HHDialogTitle,
  HHIconButton,
  HHStack,
  HHToggleButton,
  HHToggleButtonGroup,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { Close } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetSplitTreatmentOnOrOff } from '../../../../components/splits';
import { newContractLabel } from '../../constants/strings/header-labels';
import { routes } from '../../constants/strings/routes';
import {
  migrateMilestonesToPackage,
  migrateStandardToPackage,
} from '../../constants/strings/split';
import { ContractType } from '../../types';
import {
  capitalizeFirstLetterAndSpaceSnakeString,
  selectSorterFunction,
} from '../../utils/bills-utils';
import { isEngagementModelByName } from '../../utils/contract-utils';
import { EditContractButton } from '../contract/billing-edit-contract-button';
export interface ContractBillingModelLaunch {
  activeContract?: ContractType;
}

export enum CurrentBillingModels {
  standard = 'Standard',
  engagement = 'Engagement',
  milestone = 'Milestone',
}

const ContractBillingModelLaunch = ({
  activeContract,
}: ContractBillingModelLaunch): JSX.Element => {
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();

  const migrateStandardToPackageEnabled = GetSplitTreatmentOnOrOff(
    migrateStandardToPackage,
    adminId,
  );
  const migrateMilestonesToPackageEnabled = GetSplitTreatmentOnOrOff(
    migrateMilestonesToPackage,
    adminId,
  );

  const navigation = useNavigate();
  const [billingModelLaunchOpen, setBillingModelLaunchOpen] = useState(false);

  const models = (
    Object.keys(CurrentBillingModels) as (keyof typeof CurrentBillingModels)[]
  )
    .map(key => ({
      value: key,
      label: CurrentBillingModels[key],
    }))
    .sort((a, b) => selectSorterFunction(a.label, b.label));

  const contractPackageAllowedBillingModels = models
    .filter(model => {
      switch (model.label) {
        case CurrentBillingModels.standard:
          return migrateStandardToPackageEnabled;
        case CurrentBillingModels.milestone:
          return migrateMilestonesToPackageEnabled;
        default:
          return isEngagementModelByName(model.value);
      }
    })
    .map(model => model.label);

  return (
    <HHStack direction="row" spacing={2}>
      {activeContract && (
        <EditContractButton
          isText={false}
          contractId={activeContract?.id}
          buttonVariant="text"
          route={
            activeContract.packageEnabled
              ? routes.billing.editContractPackageContract
              : routes.billing.editContract
          }
        />
      )}
      <HHButton
        hhVariant="contained"
        onClick={(): void => setBillingModelLaunchOpen(true)}
      >
        {newContractLabel}
      </HHButton>
      <HHDialog
        open={billingModelLaunchOpen}
        onClose={(): void => setBillingModelLaunchOpen(false)}
        fullWidth
        keepMounted={false}
      >
        <HHDialogTitle>
          <HHStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <HHTypography hhVariant="muted" fontSize={12}>
              Select Billing Model
            </HHTypography>
            <HHIconButton
              aria-label="close"
              onClick={(): void => setBillingModelLaunchOpen(false)}
              hhVariant={'medium'}
            >
              <Close />
            </HHIconButton>
          </HHStack>
        </HHDialogTitle>
        <HHDialogContent>
          <HHStack direction="row" alignItems="center" spacing={2}>
            <HHToggleButtonGroup size="large" exclusive fullWidth>
              {models.map(model => (
                <HHToggleButton
                  hhVariant="variant-bypass"
                  key={model.value}
                  value={model.value}
                  onClick={(): void => {
                    model.value &&
                    !contractPackageAllowedBillingModels.includes(
                      CurrentBillingModels[model.value],
                    )
                      ? navigation(routes.billing.newContract, {
                          state: { billingModel: model.value },
                        })
                      : navigation(routes.billing.newContractPackage, {
                          state: { billingModel: model.value },
                        });
                  }}
                  sx={(theme): SystemStyleObject<Theme> => ({
                    textTransform: 'none',
                    backgroundColor: 'white',
                    width: '100%',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.action.focus,
                    },
                  })}
                >
                  <HHStack direction="row" alignItems="center">
                    {capitalizeFirstLetterAndSpaceSnakeString(model.label)}
                  </HHStack>
                </HHToggleButton>
              ))}
            </HHToggleButtonGroup>
          </HHStack>
        </HHDialogContent>
      </HHDialog>
    </HHStack>
  );
};

export default ContractBillingModelLaunch;
