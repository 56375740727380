import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { genericError } from '../constants/hinge-connect-constants.constant';

export interface UseSnackbarProps {
  data: unknown;
  error: ApolloError | undefined;
  errorMessages: string;
  successMessage: string;
}

export interface UseSnackbarHook {
  snackbar: {
    open: boolean;
    message: string;
    severity: SnackbarSeverity;
  };
  handleClose: () => void;
}

export enum SnackbarSeverity {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
}

export const useSnackbar = ({
  data,
  error,
  errorMessages,
  successMessage,
}: UseSnackbarProps): UseSnackbarHook => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: SnackbarSeverity.INFO,
  });

  const handleClose = (): void => {
    setSnackbar({
      open: false,
      message: '',
      severity: SnackbarSeverity.INFO,
    });
  };

  useEffect(() => {
    if (data)
      setSnackbar({
        open: true,
        message: successMessage,
        severity: SnackbarSeverity.SUCCESS,
      });
    if (error)
      setSnackbar({
        open: true,
        message: errorMessages.length > 0 ? errorMessages : genericError,
        severity: SnackbarSeverity.ERROR,
      });
  }, [data, error, errorMessages, successMessage]);

  return { snackbar, handleClose };
};
