import { HHTextField } from '@hinge-health/react-component-library';
import Autocomplete from '@mui/material/Autocomplete';
import {
  GetClientInsurersByIdsQueryVariables,
  useGetClientInsurersByIdsQuery,
} from '../../../../types';
import { selectSorterFunction } from '../../../../utils/bills-utils';
import { BillTypeDataList } from '../bill-holds-types';

/**
 * @param selectedPanelRows BillTypeDataList
 * @returns Pick<GetClientInsurersByIdsQueryVariables, 'clientIds' | 'insurerIds'>
 */
export const getClientAndInsurerIdsFromRows = (
  selectedPanelRows: BillTypeDataList,
): Pick<GetClientInsurersByIdsQueryVariables, 'clientIds' | 'insurerIds'> => {
  const insurerIds: number[] = [];

  const clientIds: number[] = [];

  selectedPanelRows.forEach(row => {
    if (row.client?.id) {
      clientIds.push(row.client.id);
    }
    if (row.insurer?.id) {
      insurerIds.push(row.insurer.id);
    }
    if (row.holdDetails?.clientId) {
      clientIds.push(row.holdDetails.clientId);
    }
    if (row.holdDetails?.insurerId) {
      insurerIds.push(row.holdDetails.insurerId);
    }
  });

  return {
    // makes list unique
    clientIds: [...new Set(clientIds)],
    insurerIds: [...new Set(insurerIds)],
  };
};

export interface ClientInsurerSelectProps {
  disabled: boolean;
  selectedRows: BillTypeDataList;
  onSelectCallback: (value: number) => void;
}

export const CLIENT_INSURER_LABEL = 'Clients Insurer';

export const ClientInsurerSelect = ({
  disabled,
  selectedRows,
  onSelectCallback,
}: ClientInsurerSelectProps): JSX.Element => {
  const { clientIds, insurerIds } =
    getClientAndInsurerIdsFromRows(selectedRows);

  const { data, error } = useGetClientInsurersByIdsQuery({
    variables: {
      clientIds,
      insurerIds,
    },
  });

  const sortedClientInsurers = [...(data?.getClientInsurersByIds ?? [])].sort(
    (a, b) => selectSorterFunction(a.client.name, b.client.name),
  );

  const ciSelectOptions = sortedClientInsurers?.map(ci => ({
    label: `${ci.client.name} - ${ci.insurer.name} ID: ${ci.id}`,
    value: ci.id,
  }));

  const selectClientInsurer = (
    newValue: {
      label: string;
      value: number;
    } | null,
  ): void => {
    const val = newValue?.value ?? 0;
    onSelectCallback(val);
  };

  return (
    <Autocomplete
      disabled={disabled}
      options={ciSelectOptions}
      size="small"
      isOptionEqualToValue={(option, value): boolean =>
        option.value === value.value
      }
      fullWidth
      onChange={(_, newValue): void => selectClientInsurer(newValue)}
      getOptionLabel={(option): string => option.label}
      renderInput={(params): JSX.Element => (
        <HHTextField
          {...params}
          hhVariant="variant-bypass"
          variant="outlined"
          label={CLIENT_INSURER_LABEL}
          error={!!error}
          helperText={error ? error?.message : ''}
        />
      )}
    />
  );
};
