import { HHTooltip } from '@hinge-health/react-component-library';
import { DeleteRounded, Edit, PlayCircleRounded } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../../hinge-connect/hooks/use-modal';
import { DeleteRuleModal } from '../../../hinge-connect/rules/components/delete-rule-modal';
import { actionButtonText } from '../../../hinge-connect/rules/constants/rules.constant';
import { tabRoutes } from '../../constants/member-data-services-constants.constant';

export const RunActionButton = (): JSX.Element => (
  <HHTooltip hhVariant="bottom" title={actionButtonText.run}>
    <GridActionsCellItem
      id="run-rule-button"
      data-testid="run-rule-button"
      label={actionButtonText.run}
      icon={<PlayCircleRounded />}
      color="primary"
      disabled
    />
  </HHTooltip>
);

export const EditActionButton = ({
  ruleId,
}: {
  ruleId: string;
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <HHTooltip hhVariant="bottom" title={actionButtonText.edit}>
      <GridActionsCellItem
        id="edit-rule-button"
        data-testid="edit-rule-button"
        label={actionButtonText.edit}
        icon={<Edit />}
        color="primary"
        onClick={(): void => {
          navigate(
            `/${tabRoutes.baseRoute}/${tabRoutes.targetedEnrollment}/${ruleId}/edit`,
          );
        }}
      />
    </HHTooltip>
  );
};

export const DeleteActionButton = ({
  ruleName,
  ruleId,
  deleteRule,
  deleteRuleLoading,
}: {
  ruleName: string;
  ruleId: string;
  deleteRule: ({ variables }: { variables: { id: string } }) => void;
  deleteRuleLoading: boolean;
}): JSX.Element => {
  const {
    isOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose,
  } = useModal();

  return (
    <>
      <DeleteRuleModal
        ruleName={ruleName}
        ruleId={ruleId}
        isOpen={isOpen}
        handleClose={handleModalClose}
        deleteRule={deleteRule}
        deleteRuleLoading={deleteRuleLoading}
      />
      <HHTooltip hhVariant="bottom" title={actionButtonText.delete}>
        <GridActionsCellItem
          id="delete-rule-button"
          data-testid="delete-rule-button"
          label={actionButtonText.delete}
          icon={<DeleteRounded />}
          color="error"
          onClick={(): void => {
            handleModalOpen();
          }}
        />
      </HHTooltip>
    </>
  );
};
