import { HHTextField } from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PackageType } from '../../../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';
import { PackageFormData } from '../custom-package-types';
import { ContractPackageFormLabelText, fieldArrayName } from '../form';

export interface PackageSelectProps {
  availablePackages: PackageType[];
  wholeFormDisabled: boolean;
  index: number;
}

export const PackageSelect = ({
  availablePackages,
  index,
  wholeFormDisabled,
}: PackageSelectProps): JSX.Element => {
  const {
    control,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext<PackageFormData>();
  const watchedPackageId = watch(`selectedPackages.${index}.packageId`);

  useEffect(() => {
    if (watchedPackageId > 0) {
      trigger('selectedPackages');
    }
  }, [trigger, watchedPackageId]);

  return (
    <Controller
      control={control}
      name={`${fieldArrayName}.${index}.packageId`}
      render={({ field: { onChange, value } }): JSX.Element => (
        <Autocomplete
          disabled={wholeFormDisabled}
          options={availablePackages}
          getOptionLabel={(option): string =>
            capitalizeFirstLetterAndSpaceSnakeString(option?.name)
          }
          fullWidth
          onChange={(_, newValue): void => onChange(newValue?.id)}
          value={availablePackages.find(p => p.id === value) ?? null}
          renderInput={(params): JSX.Element => (
            <HHTextField
              {...params}
              label={ContractPackageFormLabelText.PACKAGE}
              hhVariant="variant-bypass"
              variant="outlined"
              fullWidth
              error={
                Object.keys(errors?.selectedPackages?.[index]?.packageId ?? {})
                  .length !== 0
              }
              name="packageId"
              helperText={errors?.selectedPackages?.[index]?.packageId?.message}
              sx={{ '& .MuiInputLabel-root': { color: 'gray' } }}
            />
          )}
        />
      )}
    />
  );
};
