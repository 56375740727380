import { HHDivider, HHStack } from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { ActiveContractLabels } from '../../../constants/strings/billing-label-enums';
import {
  ContractPackagesType,
  ContractTemplateType,
  ContractType,
} from '../../../types';
import { formatCurrency } from '../../../utils/currency-helpers';
import { computeEffectiveRange } from '../../../utils/date-helper';
import MetaInfoDisplay from '../../utils/meta-info-display';
import { TextGrouping } from '../../utils/text-grouping';
import { Milestones } from './milestones';

interface LegacyContractDetailViewProps {
  contract: ContractType;
  templates: ContractTemplateType[];
  showJson?: boolean;
  inPersonVisitContractPackage: ContractPackagesType | undefined;
  slimDisplay?: boolean;
}

const LegacyDetailView = ({
  contract,
  templates,
  inPersonVisitContractPackage,
  slimDisplay = false,
}: LegacyContractDetailViewProps): JSX.Element => (
  <HHStack
    display="grid"
    gridTemplateColumns={slimDisplay ? '1fr' : 'repeat(3, 1fr)'}
    gap={slimDisplay ? 2 : 0}
  >
    <TextGrouping
      label={ActiveContractLabels.EFFECT_DATE_LABEL}
      text={computeEffectiveRange(contract.startDate, contract.endDate)}
    />
    <TextGrouping
      label={ActiveContractLabels.CONTRACT_TEMPLATE_LABEL}
      text={
        templates.find(({ id }) => id === contract.contractTemplateId)?.name ??
        'No matching template id'
      }
    />
    <TextGrouping
      label={ActiveContractLabels.CURRENCY}
      text={contract.currency ?? 'Currency not found'}
    />
    <TextGrouping
      label={ActiveContractLabels.CHRONIC_CORE_CHARGE_LABEL}
      text={formatCurrency(contract.chronicPrice ?? 0, contract?.currency)}
    />
    {contract.acutePrice ? (
      <TextGrouping
        label={ActiveContractLabels.ACUTE_CHARGE_LABEL}
        text={formatCurrency(contract.acutePrice, contract?.currency)}
      />
    ) : null}
    {inPersonVisitContractPackage ? (
      <>
        <TextGrouping
          label={ActiveContractLabels.IN_PERSON_VISIT_CHARGE_LABEL}
          text={formatCurrency(
            inPersonVisitContractPackage.price,
            contract?.currency,
          )}
        />
        <TextGrouping
          label={ActiveContractLabels.IN_PERSON_VISIT_EFFECTIVE_DATE_LABEL}
          text={`${dayjs(inPersonVisitContractPackage?.startDate).format('L')}`}
        />
      </>
    ) : null}
  </HHStack>
);

const LegacyContractDetailView = ({
  contract,
  templates,
  inPersonVisitContractPackage,
  slimDisplay = false,
}: LegacyContractDetailViewProps): JSX.Element => (
  <HHStack
    gap={4}
    sx={{ backgroundColor: 'background.default', position: 'relative' }}
    padding={4}
  >
    <MetaInfoDisplay
      updatedAt={contract.updatedAt}
      createdAt={contract.createdAt}
      lastModifiedBy={contract.lastModifiedBy || '--'}
      floating
    />
    <LegacyDetailView
      contract={contract}
      templates={templates}
      inPersonVisitContractPackage={inPersonVisitContractPackage}
      slimDisplay={slimDisplay}
    />
    <HHDivider />
    <Milestones
      contractDetails={contract.contract ?? [{}]}
      templateName={
        templates.find(({ id }) => id === contract.contractTemplateId)?.name ??
        'No matching template id'
      }
      contractBillableActivities={contract.billableActivityTypes ?? []}
      slimDisplay={slimDisplay}
      currency={contract.currency ?? 'USD'}
    />
  </HHStack>
);

export default LegacyContractDetailView;
