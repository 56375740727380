import {
  HHAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import Box from '@mui/material/Box';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BillingLeftPanel from '../components/billing-tool-left-panel';
import { ContractFormValues } from '../components/contract/form/custom-types';
import { ContractForm } from '../components/contract/form/form';
import LoadingComponent from '../components/utils/loading';
import { routes } from '../constants/strings/routes';
import { useClientInsurersById } from '../hooks/client-insurer-hook';
import {
  GetContractsByClientInsurerDocument,
  useCreateContractMutation,
  useGetContractsByClientInsurerQuery,
} from '../types';
import { getClientsInsurerPartnership } from '../utils/contract-utils';

const useStyles = makeStyles(() =>
  createStyles({
    clientInsurerNewContract: {
      display: 'flex',
      width: ' 100%',
      height: '100%',
      overflowY: 'auto',
    },
  }),
);

export const CLIENT_INSURER_NEW_CONTRACT_CONTAINER_TEST_ID =
  'client-insurer-new-contract-container';

const ClientInsurerNewContract = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const clientInsurerId = parseInt(params.id ?? '0', 10);

  const { data, loading, error } = useClientInsurersById(clientInsurerId);

  const clientsInsurerPartnership = getClientsInsurerPartnership(data);

  const { data: contractsData, error: contractsError } =
    useGetContractsByClientInsurerQuery({
      variables: {
        id: clientInsurerId,
        includeVoided: true,
      },
    });

  const [createContractMutation, { data: createData, error: createError }] =
    useCreateContractMutation();

  const createContract = useCallback(
    async createInput => {
      await createContractMutation({
        variables: {
          contractInput: createInput,
        },
        refetchQueries: [
          {
            query: GetContractsByClientInsurerDocument,
            variables: { id: clientInsurerId, includeVoided: true },
          },
        ],
      });
    },
    [createContractMutation, clientInsurerId],
  );

  useEffect(() => {
    if (createData) {
      setTimeout(() => {
        navigate(`${routes.billing.main}/${clientInsurerId}`);
      }, 1000);
    }
  }, [createData, clientInsurerId, navigate]);

  if (!loading && (error || !data)) {
    return (
      <HHAlert
        hhVariant="outlined"
        severity="error"
        children={
          <HHTypography hhVariant="body">
            There was an error loading the client insurer.
          </HHTypography>
        }
      ></HHAlert>
    );
  }

  if (!loading && !data) {
    return (
      <Box className={classes.clientInsurerNewContract}>
        <HHTypography hhVariant="body">
          Client insurer info not found
        </HHTypography>
      </Box>
    );
  }

  const handleCancel = (): void => {
    navigate(`${routes.billing.main}/${clientInsurerId}`);
  };

  const formSubmit = (values: ContractFormValues): void => {
    // using destructuing to remove void property on create
    const { void: _, ...rest } = values;
    const additional = {
      clientsInsurerId: clientInsurerId,
      clientId: data?.client.id,
      insurerId: data?.insurer.id,
      procedureCodes: [],
    };

    const contractInput = { ...additional, ...rest };

    createContract(contractInput);
  };

  const currentContracts = contractsData?.getContracts ?? [];

  /**
   *Scroll to top of form if contract created or errors
   * */
  const scrollRef = (element: HTMLDivElement | null): void => {
    if (element && (createData || createError)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Box
      className={classes.clientInsurerNewContract}
      data-testid={CLIENT_INSURER_NEW_CONTRACT_CONTAINER_TEST_ID}
    >
      {data && <BillingLeftPanel data={data} />}

      {loading ? (
        <LoadingComponent center />
      ) : (
        <HHStack padding={4} ref={scrollRef} width={'100%'}>
          {contractsError ? (
            <HHAlert hhVariant="outlined" severity="error">
              <HHTypography hhVariant="body">
                There was an error loading current contracts, contract create
                unavailable
              </HHTypography>
            </HHAlert>
          ) : null}
          {createError ? (
            <HHAlert
              hhVariant="outlined"
              severity="error"
              children={
                <HHTypography hhVariant="body">
                  Contract not created successfully, please check inputs
                </HHTypography>
              }
            ></HHAlert>
          ) : null}
          {createData ? (
            <HHAlert
              hhVariant="outlined"
              severity="success"
              children={
                <HHTypography hhVariant="body">
                  Contract Created Successfully!
                </HHTypography>
              }
            ></HHAlert>
          ) : null}
          <ContractForm
            currentContracts={currentContracts}
            formTitle="New Contract"
            onSubmitCallback={formSubmit}
            onCancel={handleCancel}
            partnershipIds={
              clientsInsurerPartnership
                ? [clientsInsurerPartnership?.id]
                : undefined
            }
            disableSubmit={!!contractsError}
            hideVoid
          />
        </HHStack>
      )}
    </Box>
  );
};

export default ClientInsurerNewContract;
