import { IN_PERSON_NAME_KEY } from '../../constants/strings/contract/form-constants';
import { ContractTemplateType, ContractType } from '../../types';
import { extractProductPackages } from '../../utils/contract-utils';
import LoadingComponent from '../utils/loading';

import LegacyContractDetailView from './legacy-contract-detail-view';
import ProductPackageContractView from './product-package-detail-view';

interface ContractDetailViewProps {
  templates: ContractTemplateType[];
  contract?: ContractType;
  showJson?: boolean;
  slimDisplay?: boolean;
}
const ContractDetailView = ({
  templates,
  contract,
  slimDisplay = false,
}: ContractDetailViewProps): JSX.Element => {
  if (!contract || templates.length === 0) {
    return <LoadingComponent />;
  }
  const productPackageData = extractProductPackages(contract.contractPackages);
  const inPersonVisitContractPackage = productPackageData.find(
    productPackageData => productPackageData.key === IN_PERSON_NAME_KEY,
  );
  const isProductPackageContract =
    contract.packageEnabled && productPackageData.length > 0;

  return isProductPackageContract ? (
    <ProductPackageContractView
      contract={contract}
      productPackages={productPackageData}
    />
  ) : (
    <LegacyContractDetailView
      contract={contract}
      templates={templates}
      inPersonVisitContractPackage={
        inPersonVisitContractPackage?.contractPackage
      }
      slimDisplay={slimDisplay}
    />
  );
};

export default ContractDetailView;
