import { HHStack } from '@hinge-health/react-component-library/dist/components/atoms/hh-stack';
import { HHTypography } from '@hinge-health/react-component-library/dist/components/atoms/hh-typography';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClientInsurerBatchList } from '../components/client-insurers/client-insurer-batch-list';
import { ContractFormValues } from '../components/contract/form/custom-types';
import { ContractForm } from '../components/contract/form/form';
import { routes } from '../constants/strings/routes';
import {
  useCreateBatchContractMutation,
  useGetClientInsurersByIdsQuery,
} from '../types';

export const CLIENT_INSURER_BATCH_CREATE_CONTRACT_CONTAINER_TEST_ID =
  'client-insurer-batch-create-contract-container';

const ClientInsurerBatchContractCreate = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const urlIds = searchParams.get('ids');
  const clientInsurerIds = urlIds?.split(',').map(cid => parseInt(cid)) ?? [];

  const { data, error } = useGetClientInsurersByIdsQuery({
    variables: {
      ids: clientInsurerIds,
    },
  });

  const [createBatchContractMutation, { data: createData }] =
    useCreateBatchContractMutation();

  const [clientInsurersList, setClientInsurersList] = useState(
    data ? data?.getClientInsurersByIds : [],
  );

  useEffect(() => {
    if (createData) {
      navigate(`../${routes.billing.batchContractCreateStatus}`, {
        state: {
          data: createData.createBatchContract,
          clientInsurersList,
        },
      });
    }
  }, [createData, clientInsurersList, navigate]);

  useEffect(() => {
    if (data) {
      setClientInsurersList(data?.getClientInsurersByIds);
    }
  }, [data]);

  const handleCancel = (): void => {
    navigate(`${routes.billing.main}`);
  };

  const batchSubmit = (values: ContractFormValues): void => {
    const clientInsurersContractCreate = clientInsurersList.map(ci => ({
      id: ci.id,
      insurerId: ci.insurer.id,
      clientId: ci.client.id,
    }));

    const {
      acutePrice,
      billableActivityTypes,
      chronicPrice,
      contractTemplateId,
      startDate,
      endDate,
      contractPackages,
      partnershipId,
    } = values;

    createBatchContractMutation({
      variables: {
        batchContractInput: {
          acutePrice,
          billableActivityTypes,
          chronicPrice,
          clientInsurers: clientInsurersContractCreate,
          contractTemplateId,
          endDate,
          startDate,
          contractPackages,
          partnershipId,
        },
      },
    });
  };

  const handleRemoveClientInsurerFromList = (id: number): void => {
    const filteredList = clientInsurersList.filter(ci => ci.id !== id);
    setClientInsurersList(filteredList);
  };

  return (
    <HHStack
      data-testid={CLIENT_INSURER_BATCH_CREATE_CONTRACT_CONTAINER_TEST_ID}
    >
      {clientInsurersList.length >= 1 && !error ? (
        <HHStack width={'100%'} flexDirection={'row'}>
          {/* making form grow for batch  */}
          <HHStack flexGrow={1} padding={4}>
            <ContractForm
              currentContracts={[]}
              formTitle="Batch create contracts"
              onSubmitCallback={batchSubmit}
              submitTitle={`Create ${clientInsurersList.length ?? 0} Contracts`}
              onCancel={handleCancel}
              disableModalSubmit={true}
              startDateDisablePast
              hideVoid
              partnershipIds={clientInsurersList
                .map(ci => ci.partnerships?.map(p => p.id))
                .flat()}
            />
          </HHStack>
          <ClientInsurerBatchList
            clientInsurers={clientInsurersList}
            clientInsurerUrlIdsCount={clientInsurerIds.length}
            onRemove={handleRemoveClientInsurerFromList}
          />
        </HHStack>
      ) : (
        <HHStack flexDirection={'column'} padding={'1em'}>
          <HHTypography hhVariant="section-title">
            No Client Insurers found
          </HHTypography>
        </HHStack>
      )}
    </HHStack>
  );
};

export default ClientInsurerBatchContractCreate;
