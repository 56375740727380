import { Dayjs } from 'dayjs';

import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';
import {
  BillingModelType,
  ContractTemplateType,
  ContractType,
  EngagementType,
  PackageType,
  useGetAllEngagementTypesQuery,
  useGetAllPackagesQuery,
  useGetBillingModelsQuery,
  useGetContractTemplatesQuery,
} from '../../../types';
import { computeStartDateDefault } from '../../../utils/contract-utils';

interface Data {
  defaultStartDateCalenderDate: Dayjs;
  allTemplates: ContractTemplateType[];
  engagementTypes: EngagementType[];
  availablePackages: PackageType[];
  billingModels: BillingModelType[];
}

interface UseGetDefaultReturn {
  data: Data | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
}

export function useGetDefaultValues(
  currentContracts: ContractType[],
): UseGetDefaultReturn {
  const defaultStartDateCalenderDate = useMemo(
    () => computeStartDateDefault(currentContracts),
    [currentContracts],
  );

  const {
    data: templateData,
    loading: templateLoading,
    error: templateError,
  } = useGetContractTemplatesQuery();
  const {
    data: engagementTypes,
    loading: engagementTypeLoading,
    error: engagementTypeError,
  } = useGetAllEngagementTypesQuery();
  const {
    data: packagesData,
    loading: packagesLoading,
    error: packagesError,
  } = useGetAllPackagesQuery();
  const {
    data: billingModelsData,
    loading: billingModelsLoading,
    error: billingModelsError,
  } = useGetBillingModelsQuery();

  const combinedError =
    templateError || engagementTypeError || packagesError || billingModelsError;

  const isLoading =
    templateLoading ||
    engagementTypeLoading ||
    packagesLoading ||
    billingModelsLoading;

  const processedData = useMemo(() => {
    if (
      isLoading ||
      combinedError ||
      !templateData?.getContractTemplates ||
      !engagementTypes?.getAllEngagementTypes ||
      !packagesData?.getAllPackages ||
      !billingModelsData?.getBillingModels
    ) {
      return undefined;
    }

    return {
      defaultStartDateCalenderDate,
      allTemplates: templateData.getContractTemplates,
      engagementTypes: engagementTypes.getAllEngagementTypes,
      availablePackages: packagesData.getAllPackages,
      billingModels: billingModelsData.getBillingModels,
    };
  }, [
    engagementTypes?.getAllEngagementTypes,
    billingModelsData?.getBillingModels,
    combinedError,
    defaultStartDateCalenderDate,
    isLoading,
    packagesData?.getAllPackages,
    templateData?.getContractTemplates,
  ]);

  return {
    data: processedData,
    isLoading,
    error: combinedError,
  };
}
