import {
  HHInfoGrid,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useBillingSetting } from '../../context/billing.context';
import {
  FeeForServiceEvent,
  useGetContractsByClientInsurerQuery,
  useGetContractTemplatesQuery,
} from '../../types';
import { displayDateTime } from '../../utils/date-helper';
import ContractDetailView from '../contract/contract-view-components/contract-detail-view';
import LoadingComponent from '../utils/loading';

interface FeeForServiceDetailProps {
  feeForServiceEventData: FeeForServiceEvent;
}

const FeeForServiceDetail = ({
  feeForServiceEventData,
}: FeeForServiceDetailProps): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');
  const contractsByClientInsurerQuery = useGetContractsByClientInsurerQuery({
    variables: {
      id: feeForServiceEventData.clientsInsurerId,
      includeVoided: true,
    },
  });
  const contractTemplatesQuery = useGetContractTemplatesQuery();

  if (contractsByClientInsurerQuery.loading || contractTemplatesQuery.loading) {
    return <LoadingComponent center />;
  }

  const contract = contractsByClientInsurerQuery.data?.getContracts.find(
    ({ id }) => id === feeForServiceEventData.contractId,
  );

  if (!contract || !contractTemplatesQuery.data?.getContractTemplates) {
    return (
      <HHTypography hhVariant="body">
        Contract not found for this fee for service event
      </HHTypography>
    );
  }

  return (
    <HHStack spacing={4} padding={1}>
      <HHInfoGrid
        data={[
          {
            content: String(feeForServiceEventData.id),
            heading: 'Id',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.userId),
            heading: 'User Id',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.userUuid),
            heading: 'User UUID',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.pathwayUuid),
            heading: 'Pathway UUID',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.clientsInsurerId),
            heading: 'Client Insurer Id',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.contractPackageId),
            heading: 'Contract Package Id',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.contractId),
            heading: 'Contract Id',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.engagementEventId),
            heading: 'Engagement Event Id',
            copyable: true,
          },
          {
            content: displayDateTime(feeForServiceEventData.occurredAt, {
              displayLocal,
            }),
            heading: 'Occurred At',
            copyable: true,
          },
          {
            content: displayDateTime(feeForServiceEventData.createdAt, {
              displayLocal,
            }),
            heading: 'Created At',
            copyable: false,
          },
          {
            content: String(feeForServiceEventData.void),
            heading: 'Void',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.partnershipId),
            heading: 'Partnership Id',
            copyable: true,
          },
          {
            content: String(feeForServiceEventData.adminUuid),
            heading: 'Admin UUID',
            copyable: true,
          },
        ]}
        divided={false}
        justifyBetween={true}
      />
      <HHTypography hhVariant="subtitle1">Contract</HHTypography>
      <ContractDetailView
        key={feeForServiceEventData.contractId}
        contract={contract}
        templates={contractTemplatesQuery.data?.getContractTemplates}
        slimDisplay
      />
    </HHStack>
  );
};

export default FeeForServiceDetail;
