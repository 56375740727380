import {
  HHCircularProgress,
  HHTextField,
} from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CONTRACT_FORM_TEMPLATE_SELECT_ID,
  NO_DATA_TEMPLATE,
} from '../../../../constants/strings/contract/form-constants';

import { useEffect, useRef } from 'react';
import { BillingModelType, ContractTemplateType } from '../../../../types';
import { filterTemplatesByPartnershipsAndBillingModel } from '../../../../utils/contract-utils';

export interface ContractTemplateSelectProps {
  allTemplates: ContractTemplateType[];
  wholeFormDisabled?: boolean;
  isPackageForm?: boolean;
  billingModels?: BillingModelType[];
  index?: number;
}

export const ContractTemplateSelect = ({
  allTemplates,
  wholeFormDisabled,
  isPackageForm = false,
  index = 0,
  billingModels,
}: ContractTemplateSelectProps): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [watchedTemplate, watchedBillingModelId, watchedPartnershipId] = watch([
    `${isPackageForm ? `selectedPackages.${index}.currentTemplate` : 'currentTemplate'}`,
    `selectedPackages.${index}.billingModelId`,
    'partnershipId',
  ]);

  const previousBillingModelId = useRef(watchedBillingModelId);

  useEffect(() => {
    if (
      watchedTemplate?.contract &&
      Array.isArray(watchedTemplate?.contract) &&
      watchedTemplate?.contract?.length > 0 &&
      watchedTemplate?.contract?.some(
        (contract: { [key: string]: object }): boolean =>
          Object.keys(contract).length > 0,
      ) &&
      !isPackageForm
    ) {
      setValue('contract', watchedTemplate.contract);
    }
  }, [watchedTemplate, setValue, isPackageForm]);

  useEffect(() => {
    const selectedTemplate = allTemplates.find(
      t => t.id === watchedTemplate.contractTemplateId,
    );

    /**
     * If there is a partnership selected
     * and the selected template's partnershipId is different
     * from the watched partnershipId then reset template
     */
    if (
      selectedTemplate?.partnershipId &&
      selectedTemplate.partnershipId !== watchedPartnershipId
    ) {
      setValue(
        isPackageForm
          ? `selectedPackages.${index}.currentTemplate`
          : 'currentTemplate',
        NO_DATA_TEMPLATE,
        { shouldValidate: true },
      );
    }
    if (
      isPackageForm &&
      watchedBillingModelId !== previousBillingModelId.current
    ) {
      setValue(`selectedPackages.${index}.currentTemplate`, NO_DATA_TEMPLATE);
      previousBillingModelId.current = watchedBillingModelId;
    }
  }, [
    allTemplates,
    setValue,
    watchedPartnershipId,
    watchedTemplate.contractTemplateId,
    isPackageForm,
    watchedBillingModelId,
    index,
  ]);

  const filteredTemplates = filterTemplatesByPartnershipsAndBillingModel(
    allTemplates,
    [watchedPartnershipId],
    billingModels?.find(model => model.id === watchedBillingModelId)?.name,
  );

  return filteredTemplates.length ? (
    <Controller
      control={control}
      name={
        isPackageForm
          ? `selectedPackages.${index}.currentTemplate`
          : 'currentTemplate'
      }
      render={({ field: { onChange, value, ref } }): JSX.Element => (
        <Autocomplete
          ref={ref}
          key="currentTemplate"
          data-testid={CONTRACT_FORM_TEMPLATE_SELECT_ID}
          disabled={wholeFormDisabled}
          options={filteredTemplates}
          getOptionLabel={(option): string => option.name}
          isOptionEqualToValue={(option, value): boolean =>
            option.contractTemplateId === value.contractTemplateId
          }
          disableClearable
          onChange={(_, newValue): void => onChange(newValue)}
          value={value}
          renderInput={(params): JSX.Element => (
            <HHTextField
              {...params}
              label="Contract template"
              hhVariant="variant-bypass"
              variant="outlined"
              error={
                Object.keys(
                  isPackageForm
                    ? (errors?.selectedPackages?.[index]?.currentTemplate ?? {})
                    : (errors?.currentTemplate ?? {}),
                ).length !== 0
              }
              name="currentTemplate"
              helperText={
                isPackageForm
                  ? errors?.selectedPackages?.[index]?.currentTemplate?.message
                  : errors.currentTemplate?.message
              }
              sx={{ '& .MuiInputLabel-root': { color: 'gray' } }}
            />
          )}
        />
      )}
    />
  ) : (
    <HHCircularProgress />
  );
};
