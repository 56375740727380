import { EngagementType } from '../../../../types';

export interface SubTypeRules {
  defaultSelected: boolean;
  disabled: boolean;
  value: number;
}

export interface EngagementTypeWithRules
  extends BillingModelRule,
    EngagementType {}

export interface BillingModelRule {
  defaultSelected: boolean;
  disabled: boolean;
  subTypeOptions: Record<RuleType, SubTypeRules[]> | null;
}

export enum RuleType {
  enso_session = 'dailyCap',
}

export const getEngagementTypesConfig = (): Record<
  string,
  Record<string, BillingModelRule>
> => ({
  enso_session: {
    engagement: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: {
        [RuleType.enso_session]: [
          {
            defaultSelected: false,
            disabled: false,
            value: 1,
          },
          {
            defaultSelected: true,
            disabled: false,
            value: 3,
          },
        ],
      },
    },
    legacy: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: {
        [RuleType.enso_session]: [
          {
            defaultSelected: true,
            disabled: false,
            value: 3,
          },
        ],
      },
    },
    standard: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: {
        [RuleType.enso_session]: [
          {
            defaultSelected: true,
            disabled: false,
            value: 3,
          },
        ],
      },
    },
  },
  et_session: {
    engagement: {
      defaultSelected: true,
      disabled: false,
      subTypeOptions: null,
    },
    legacy: {
      defaultSelected: true,
      disabled: false,
      subTypeOptions: null,
    },
    standard: {
      defaultSelected: true,
      disabled: false,
      subTypeOptions: null,
    },
  },
  article_read: {
    engagement: {
      defaultSelected: false,
      disabled: true,
      subTypeOptions: null,
    },
    legacy: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: null,
    },
    standard: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: null,
    },
  },
  video_visit: {
    engagement: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: null,
    },
    legacy: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: null,
    },
    standard: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: null,
    },
  },
  in_person_visit: {
    engagement: {
      defaultSelected: false,
      disabled: true,
      subTypeOptions: null,
    },
    legacy: {
      defaultSelected: false,
      disabled: true,
      subTypeOptions: null,
    },
    standard: {
      defaultSelected: false,
      disabled: true,
      subTypeOptions: null,
    },
  },
  care_team_communication: {
    engagement: {
      defaultSelected: false,
      disabled: false,
      subTypeOptions: null,
    },
    legacy: {
      defaultSelected: false,
      disabled: true,
      subTypeOptions: null,
    },
    standard: {
      defaultSelected: false,
      disabled: true,
      subTypeOptions: null,
    },
  },
});

export const mapEngagementTypesToRules = (
  billingModel: string,
  engagementTypes: EngagementType[],
): EngagementTypeWithRules[] => {
  // get a new instance of config object every time we call this function
  const engagementTypesConfig = getEngagementTypesConfig();
  return engagementTypes.map(ent => ({
    ...ent,
    ...(engagementTypesConfig[ent.name]
      ? engagementTypesConfig[ent.name][billingModel]
      : {
          defaultSelected: false,
          disabled: true,
          subTypeOptions: null,
        }),
  }));
};
