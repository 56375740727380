import { HHAlert, HHTypography } from '@hinge-health/react-component-library';
import Stack from '@mui/material/Stack';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';
import { BatchClientInsurerType } from '../../../types';
import { ClientInsurerBatchCard } from '../components/client-insurers/batch-client-insurer-card';
import { routes } from '../constants/strings/routes';
import { BatchContractResponse, BatchInsurerType, ClientType } from '../types';

interface ParsedClientInsurer {
  id: number;
  insurer: BatchInsurerType;
  client: ClientType;
  errorMessage: string;
  link: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    clientInsurerBatchContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    clientInsurerBatchContractCreateStatus: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      overflowY: 'auto',
    },
    link: {
      textDecoration: 'none',
    },
    successFailureContainer: {
      flex: '1',
      padding: '3rem',
      paddingTop: '0',
    },
  }),
);
export const CLIENT_INSURER_BATCH_CREATE_CONTRACT_STATUS_CONTAINER_TEST_ID =
  'client-insurer-batch-create-contract-status-container';

const parseData = (
  data: BatchContractResponse[],
  clientInsurersList: BatchClientInsurerType[],
): {
  successCount: number;
  successCI: Array<ParsedClientInsurer>;
  failedCount: number;
  failureCI: Array<ParsedClientInsurer>;
} => {
  const failureCI: Array<ParsedClientInsurer> = [];
  const successCI: Array<ParsedClientInsurer> = [];

  clientInsurersList?.map(ci => {
    data?.map(response => {
      if (ci.id === response.clientInsurerId) {
        if (response.success === true) {
          successCI.push({
            ...ci,
            errorMessage: response.error,
            link: `${routes.billing.main}/${JSON.stringify(ci.id)}`,
          });
        } else {
          failureCI.push({
            ...ci,
            errorMessage: response.error,
            link: `${routes.billing.main}/${JSON.stringify(ci.id)}`,
          });
        }
      }
    });
  });

  return {
    successCount: successCI.length,
    successCI,
    failedCount: failureCI.length,
    failureCI,
  };
};

const ClientInsurerBatchContractCreateStatus = (): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const clientInsurerData = location.state as {
    data: BatchContractResponse[];
    clientInsurersList: BatchClientInsurerType[];
  };
  const parsedData = parseData(
    clientInsurerData.data,
    clientInsurerData.clientInsurersList,
  );
  return (
    <Stack
      className={classes.clientInsurerBatchContainer}
      data-testid={
        CLIENT_INSURER_BATCH_CREATE_CONTRACT_STATUS_CONTAINER_TEST_ID
      }
    >
      <Stack>
        <HHTypography
          hhVariant="section-title"
          layoutStyles={{ padding: '1rem 0 24px 3rem' }}
        >
          Batch Creation Confirmation
        </HHTypography>
      </Stack>
      <Stack className={classes.clientInsurerBatchContractCreateStatus}>
        <Stack className={classes.successFailureContainer}>
          <HHAlert hhVariant="standard" severity="success">
            {parsedData.successCount} contract(s) successfully created:
          </HHAlert>
          {parsedData.successCI.map((ci: ParsedClientInsurer) => (
            <ClientInsurerBatchCard
              client={ci.client}
              insurer={ci.insurer}
              id={ci.id}
              showButton={false}
              showDivider={false}
              key={ci.id}
            />
          ))}
        </Stack>
        <Stack className={classes.successFailureContainer}>
          <HHAlert hhVariant="standard" severity="error">
            {parsedData.failedCount} contract(s) failed creation:
          </HHAlert>
          {parsedData.failureCI.map((ci: ParsedClientInsurer) => (
            <Stack key={ci.id}>
              <Link to={ci.link} className={classes.link} target={'_blank'}>
                <ClientInsurerBatchCard
                  client={ci.client}
                  insurer={ci.insurer}
                  id={ci.id}
                  showButton={false}
                  showDivider={false}
                  showToolTip={true}
                  toolTipInfo={ci.errorMessage}
                  key={ci.id}
                />
              </Link>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ClientInsurerBatchContractCreateStatus;
