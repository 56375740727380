import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { MilestoneLabels } from '../../../constants/strings/billing-label-enums';
import { BillableActivityType, EngagementType } from '../../../types';
import { determineContractType } from '../../../utils/contract-utils';
import { formatCurrency } from '../../../utils/currency-helpers';
import { TextGrouping } from '../../utils/text-grouping';
import { ContractTypeExtension } from '../form/custom-types';
import { BillableActivitiesWithDetails } from '../package/custom-package-types';
import { ConfigurableActivityDisplay } from './billable-activity-display';
import MilestoneJson from './contract-milestone-json';

export interface MilestoneProps {
  contractDetails: ContractTypeExtension[];
  templateName?: string;
  contractType?: string;
  selectedBillableActivities?: BillableActivitiesWithDetails;
  engagementTypes?: EngagementType[];
  contractBillableActivities?: BillableActivityType[];
  slimDisplay?: boolean;
  currency?: string;
}

export const conditionsMap: Record<string, string> = {
  enrollment_check: 'Enrollment',
  engagement_threshold_check: 'Engagement threshold check',
  pain_reduction_check: 'Pain reduction check',
};

const MilestoneDisplay = ({
  contractDetails,
  selectedBillableActivities,
  engagementTypes,
  contractBillableActivities,
  contractType,
  slimDisplay = false,
  currency,
}: MilestoneProps): JSX.Element => (
  <HHStack
    display="grid"
    gridTemplateColumns={slimDisplay ? '1fr' : 'repeat(3, 1fr)'}
    sx={
      slimDisplay
        ? {
            '& > div': {
              borderBottom: '1px solid lightgray',
              boxSizing: 'border-box',
              paddingBottom: 3,
            },
            '& > div:last-child': {
              borderBottom: 'none',
            },
          }
        : {
            '& > div': {
              borderRight: '1px solid lightgray',
              boxSizing: 'border-box',
              marginRight: 3,
            },
            '& > div:last-child': {
              borderRight: 'none',
            },
          }
    }
  >
    {contractDetails.map(milestone => (
      <HHStack flexDirection="column" key={milestone.name} flex={1} gap={2}>
        <TextGrouping
          label={
            contractType === 'Standard'
              ? MilestoneLabels.STANDARD_MILESTONE_LABEL
              : 'M' + milestone.name.replace('C', '')
          }
          text={formatCurrency(milestone.payment, currency)}
        />
        <HHTypography hhVariant="body" flexWrap="wrap">
          <ConfigurableActivityDisplay
            milestone={milestone}
            selectedBillableActivities={selectedBillableActivities}
            engagementTypes={engagementTypes}
            contractBillableActivities={contractBillableActivities}
          />
        </HHTypography>
        <HHStack direction="row">
          <HHStack direction="column" flex={1}>
            <HHTypography hhVariant="muted">
              {MilestoneLabels.DOS_OFFSET}
            </HHTypography>
            <HHTypography hhVariant="body2">
              {milestone.dateOfServiceOffset
                ? `${milestone.dateOfServiceOffset}`
                : 'None'}
            </HHTypography>
          </HHStack>
          <HHStack
            direction="column"
            flex={contractType === 'Standard' ? 2 : 1}
          >
            <HHTypography hhVariant="muted">
              {MilestoneLabels.DAY_RANGE}
            </HHTypography>
            <HHTypography hhVariant="body2">
              {milestone.dayRange && milestone.dayRange.length >= 1
                ? `${milestone.dayRange[0]} to ${milestone.dayRange[1]} days`
                : 'Day range not configured'}
            </HHTypography>
          </HHStack>
        </HHStack>
        <HHStack>
          <HHTypography hhVariant="muted">
            {MilestoneLabels.BILLING_CONDITIONS}
          </HHTypography>
          {milestone.conditions?.map(c => (
            <HHTypography hhVariant="body2" key={c.function}>
              {conditionsMap[c.function]} -{' '}
              {conditionsMap[c.function] === conditionsMap.pain_reduction_check
                ? c.threshold + '%'
                : c.threshold + ' billable activity'}
            </HHTypography>
          ))}
        </HHStack>
      </HHStack>
    ))}
  </HHStack>
);

// reusable outside of form
export const Milestones = ({
  contractDetails,
  templateName,
  selectedBillableActivities,
  engagementTypes,
  contractBillableActivities,
  slimDisplay = false,
  currency,
}: MilestoneProps): JSX.Element => {
  const contractType = determineContractType(templateName ?? '');
  return contractType === 'Milestone' ||
    contractType === 'Standard' ||
    contractType === 'Individual Milestone' ? (
    <MilestoneDisplay
      contractDetails={contractDetails}
      selectedBillableActivities={selectedBillableActivities}
      engagementTypes={engagementTypes}
      contractBillableActivities={contractBillableActivities}
      contractType={contractType}
      slimDisplay={slimDisplay}
      currency={currency}
    />
  ) : (
    <MilestoneJson contract={contractDetails} />
  );
};
