import {
  HHAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useSnackbarContext } from '../../context/snackbar.context';
import {
  FeeForServiceEvent,
  useGetBillsByFeeForServiceEventIdQuery,
  useGetContractsByClientInsurerQuery,
} from '../../types';
import LoadingComponent from '../utils/loading';
import BillList from './bill-list';

export interface BillsByFeeForServiceIdProps {
  ffsData: FeeForServiceEvent;
}

const BillsByFeeForServiceId = ({
  ffsData,
}: BillsByFeeForServiceIdProps): JSX.Element => {
  const { setSnackbarState } = useSnackbarContext();
  const { data, loading, error } = useGetBillsByFeeForServiceEventIdQuery({
    variables: {
      sourceId: ffsData.id,
      source: 'fee_for_service_events',
    },
  });
  const contractsByClientInsurerQuery = useGetContractsByClientInsurerQuery({
    variables: { id: ffsData.clientsInsurerId, includeVoided: true },
  });

  const contractCurrency =
    contractsByClientInsurerQuery.data?.getContracts.find(
      ({ id }) => id === ffsData.contractId,
    )?.currency;

  if (loading) {
    return <LoadingComponent center />;
  }

  if (error) {
    return (
      <HHAlert hhVariant="outlined" severity="error">
        <HHTypography hhVariant="body">
          There was an error loading fee for service bills
        </HHTypography>
      </HHAlert>
    );
  }

  if (!data?.getBillsByFeeForServiceEventId) {
    return (
      <HHStack spacing={2} overflow="hidden">
        <HHTypography hhVariant="body">
          Fee for service bills not found
        </HHTypography>
      </HHStack>
    );
  }

  if (!contractCurrency && !contractsByClientInsurerQuery.loading) {
    setSnackbarState('Unable to load currency information', 'info', 3000);
  }

  return (
    <BillList
      billsData={data.getBillsByFeeForServiceEventId}
      currency={contractCurrency ?? null}
    />
  );
};

export default BillsByFeeForServiceId;
