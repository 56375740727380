import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';
import { useGetFeeForServiceEngagementsLazyQuery } from '../../types';
import EngagementsData from './engagements-data';

export interface EngagementsByFeeForServiceIdProps {
  feeForServiceEventId: number;
}

const EngagementsByFeeForServiceId = ({
  feeForServiceEventId,
}: EngagementsByFeeForServiceIdProps): JSX.Element => {
  const [page, setPage] = useState(1);
  const [getFeeForServiceEngagements, { data, loading, error }] =
    useGetFeeForServiceEngagementsLazyQuery();
  const totalDataCnt = data?.getFeeForServiceEngagements.total ?? 0;

  useEffect(() => {
    getFeeForServiceEngagements({
      variables: { feeForServiceEventId, page, size: 10 },
    });
  }, [page, feeForServiceEventId, getFeeForServiceEngagements]);

  return (
    <HHStack spacing={2} padding={1}>
      <HHTypography hhVariant="subtitle1">Engagements</HHTypography>
      <EngagementsData
        loading={loading}
        error={Boolean(error)}
        page={page}
        engagementsData={data?.getFeeForServiceEngagements}
        setPage={setPage}
        total={totalDataCnt}
        pages={data?.getFeeForServiceEngagements.pages ?? 1}
      />
    </HHStack>
  );
};

export default EngagementsByFeeForServiceId;
